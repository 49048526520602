export const PATH = {
  home: "/",
  login: "/login",
  users: "/users",
  user: "/user/detail/:id",
  userAccount: "/accounts",
  transactions: "/transactions",
  withdrawLoans: "/withdraw-loans",
  withdraws: "/withdraws",
  currencies: "/currencies",
  settings: "/settings",
  dashboards: "/dashboards",
  activityLogs: "/activity-logs",
};
