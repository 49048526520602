import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const styles = makeStyles({
  rootTable: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DFE0EB",
    boxSizing: "border-box",
    borderRadius: 8,
    width: "100%",
  },
});

interface IProps {
  children: React.ReactNode;
  nameTable: string;
  ActionsComponent?: React.ReactNode;
}
export const RootTable = (props: IProps) => {
  const classes = styles();
  return (
    <Grid className={classes.rootTable}>
      <Grid container alignItems="center" style={{ marginTop: 32, height: 40 }}>
        <Grid item xs={3}>
          <Typography variant="h3" style={{ fontSize: 20, marginLeft: 16 }}>
            {props.nameTable}
          </Typography>
        </Grid>
        <Grid
          container
          justify="flex-end"
          item
          xs={9}
          style={{ paddingRight: 16 }}
        >
          {props.ActionsComponent}
        </Grid>
      </Grid>
      <Grid>{props.children}</Grid>
    </Grid>
  );
};
