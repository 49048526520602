import ICountry from "../types/country";
import { useState } from "react";
import { IVerificationInfo } from "../types/user.type";

export const validateEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function formatDateTime(date: string) {
  const data = new Date(date);
  const montNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = data.getDate();
  const monthIndex = data.getMonth();
  const year = data.getFullYear();
  const hours = `0${data.getHours()}`.slice(-2);
  const minutes = `0${data.getMinutes()}`.slice(-2);

  return (
    montNames[monthIndex] +
    " " +
    day +
    " " +
    year +
    ", " +
    hours +
    ":" +
    minutes
  );
}

export const formatMonthDateYear = (date: Date | null) => {
  let formatDateTime = null;
  if (date) {
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    formatDateTime = month + "/" + day + "/" + year;
  }
  return formatDateTime;
};

export function formatAddress(format: string) {
  const lengthText = format ? format.length : 0;
  let newValue = "";
  if (lengthText <= 5) {
    newValue = format;
  } else if (5 < lengthText && lengthText <= 10) {
    newValue = format.substring(0, 10) + "...";
  } else if (lengthText > 10) {
    newValue =
      format.substring(0, 7) + "..." + format.substring(format.length - 7);
  } else {
    newValue = "";
  }
  return newValue;
}

export const formatNumberToFixed = (
  n: number | string,
  fix: number
): string => {
  if (n === "") {
    return "";
  }

  if (n === "0" || n === 0) {
    return "0";
  }

  const formatedN = parseFloat(
    typeof n === "string" ? parseFloat(n).toFixed(fix) : n.toFixed(fix)
  )
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const parts = formatedN.split(".");
  if (parts[1]) {
    return parts[0] + "." + parts[1].replace(/,/g, "");
  } else {
    return parts[0];
  }
};

export const selectCountry = (countryId: string, dataContries: ICountry[]) => {
  const newValueCountry: ICountry[] = dataContries.filter(
    (propEl) => propEl.id === countryId
  );
  if (newValueCountry.length === 0) {
    return 0;
  }
  const nameCountry = newValueCountry[0].name;

  return nameCountry;
};

//create your forceUpdate hook
export function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}

export function getBlockchainExplorerLink(currency: string, hash: string) {
  let blockchainExplorerURL: string = "";
  switch (currency) {
    case "BTC":
      blockchainExplorerURL = `${process.env.REACT_APP_URL_BTC_NETWORK}/${hash}`;
      break;
    case "XRP":
      blockchainExplorerURL = `${process.env.REACT_APP_URL_XRP_NETWORK}/${hash}`;
      break;
    default:
      blockchainExplorerURL = `${process.env.REACT_APP_URL_ETH_NETWORK}/${hash}`;
      break;
  }

  return blockchainExplorerURL;
}

export function renderContentVerification(
  verification1: IVerificationInfo,
  verification2: IVerificationInfo
) {
  let isVerification: string = "Basic";
  if (verification2 !== null && verification2.isApproved === true) {
    isVerification = verification2.type;
  } else {
    isVerification = "Basic";
  }
  return isVerification;
}

export function renderContentVerificationUserTable(
  verificationLevel1: boolean,
  verificationLevel2: boolean
) {
  let isVerification: string = "Basic";
  if (verificationLevel2 === true) {
    isVerification = "Advanced";
  } else {
    isVerification = "Basic";
  }

  return isVerification;
}

export const fortmatJson = (data: any) => {
  // jsonObject will contain a valid JavaScript object
  // eslint-disable-next-line no-eval
  var jsonObject = eval("(" + data + ")");

  // Will display the string 'someValue'
  return jsonObject;
};

export const checkMinMax = (value: string) => {
  let valueUpdate = value;
  if (Number(value) > 100) {
    valueUpdate = "100";
  }
  if (Number(valueUpdate) < 0) {
    valueUpdate = "1";
  }
  if (valueUpdate === "0") {
    valueUpdate = "1";
  }
  return valueUpdate;
};
