import React, { useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { ItemUser } from "./ItemUser";
import { NexoButton } from "../share/NexoButton";
import { useDispatch } from "react-redux";
import {
  verificationAdvancedUser,
  verificationRefuseUser,
} from "../../actions";
import { ListImageUser } from "./ListImageUser";
import { ConfirmDialog } from "../dialog/Confirm.dialog";
import { IUserInfo } from "../../types/user.type";

const styles = makeStyles({
  buttonCancel: {
    width: 150,
    marginRight: 7,
  },
  buttonVerify: {
    width: 150,
  },
  marginImage: {
    marginTop: 30,
    marginBottom: 30,
  },
});

interface IProps {
  profileUser: IUserInfo;
}

export const ProfileUser = (props: IProps) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { profileUser } = props;
  // Create state
  const [showDialogApprove, setShowDialogApprove] = useState(false);
  const [showDialogDenied, setShowDialogDenied] = useState(false);

  const handleShowDialog = (type: string) => () => {
    if (type === "Approve") {
      setShowDialogApprove(true);
    } else {
      setShowDialogDenied(true);
    }
  };

  const handleClose = () => {
    setShowDialogApprove(false);
    setShowDialogDenied(false);
  };

  const handleApproveVerification = () => {
    dispatch(verificationAdvancedUser(profileUser.id));
    handleClose();
  };

  const handleDeniedVerification = () => {
    dispatch(verificationRefuseUser(profileUser.id));
    handleClose();
  };

  const ItemReject = () => {
    return (
      <Grid className={classes.buttonVerify}>
        <NexoButton
          title="Reject"
          variant="outlined"
          color="primary"
          handleRedirect={handleShowDialog("Refuse")}
          heightButton={40}
        />
      </Grid>
    );
  };
  const ItemApprove = () => {
    return (
      <Grid className={classes.buttonCancel}>
        <NexoButton
          title="Approve"
          variant="contained"
          color="primary"
          heightButton={40}
          handleRedirect={handleShowDialog("Approve")}
        />
      </Grid>
    );
  };
  return (
    <Grid
      container
      style={{
        display: "grid",
        marginTop: 50,
        gridTemplateColumns: "420px calc(100% - 420px)",
      }}
    >
      <Grid
        style={{
          background: "#FFFFFF",
          border: "1px solid #D7DCE0",
          boxSizing: "border-box",
          borderRadius: 5,
        }}
      >
        <Grid
          container
          alignItems="center"
          style={{
            background: " #F9FAFB",
            borderBottom: "1px solid #D7DCE0",
            boxSizing: "border-box",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: 60,
          }}
        >
          <Typography
            variant="h4"
            style={{ fontSize: 16, fontWeight: 500, marginLeft: 16 }}
          >
            Personal Information
          </Typography>
        </Grid>
        <Grid container>
          <ItemUser title="First Name" value={profileUser.firstName} />
          <ItemUser title="Last Name" value={profileUser.lastName} />

          <ItemUser
            title="Phone"
            value={profileUser.phone}
            id={profileUser.id}
          />
          <ItemUser title="Country" value={profileUser.country.name} />
          <ItemUser title="City" value={profileUser.city} />
          <ItemUser title="Address" value={profileUser.address} />
          <ItemUser title="Post Code" value={profileUser.postCode} />
          <ItemUser
            title="Google Authenticator / Authy"
            value=""
            checked={profileUser.isGoogleAuthenticator}
            id={profileUser.id}
          />
        </Grid>
        {profileUser.deniedImage?.length !== 0 ||
        profileUser.pendingImage?.length !== 0 ||
        profileUser.approvedImage?.length !== 0 ? (
          profileUser.verification2 &&
          profileUser.verification2.isApproved === true ? (
            <Grid
              container
              style={{ marginTop: 30, marginBottom: 30 }}
              justify="center"
            >
              <ItemReject />
            </Grid>
          ) : (
            <Grid
              container
              style={{ marginTop: 30, marginBottom: 30 }}
              justify="center"
            >
              <ItemApprove />
              {profileUser.pendingImage?.length !== 0 ||
              (profileUser.verification2 &&
                profileUser.verification2.isApproved === true) ? (
                <ItemReject />
              ) : null}
            </Grid>
          )
        ) : (
          <div style={{ height: 30 }} />
        )}
      </Grid>
      <Grid style={{ marginLeft: 30 }}>
        <Grid
          style={{
            background: "#FFFFFF",
            border: "1px solid #D7DCE0",
            boxSizing: "border-box",
            borderRadius: 5,
            height: "100%",
          }}
        >
          <Grid
            container
            alignItems="center"
            style={{
              background: " #F9FAFB",
              borderBottom: "1px solid #D7DCE0",
              boxSizing: "border-box",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              height: 60,
            }}
          >
            <Typography
              variant="h4"
              style={{ fontSize: 16, fontWeight: 500, marginLeft: 16 }}
            >
              Personal Documents
            </Typography>
          </Grid>
          <Grid container justify="center" className={classes.marginImage}>
            {profileUser.pendingImage && profileUser.pendingImage.length > 0 ? (
              <ListImageUser
                header="Pending Approvals:"
                listImage={
                  profileUser.pendingImage ? profileUser.pendingImage : []
                }
              />
            ) : null}

            {profileUser.approvedImage &&
            profileUser.approvedImage.length > 0 ? (
              <ListImageUser
                header="Success Approvals:"
                listImage={
                  profileUser.approvedImage ? profileUser.approvedImage : []
                }
              />
            ) : null}
            {profileUser.deniedImage && profileUser.deniedImage.length > 0 ? (
              <ListImageUser
                header="Reject Approvals:"
                listImage={
                  profileUser.deniedImage ? profileUser.deniedImage : []
                }
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        handleClose={handleClose}
        message="Are you sure you want to approve?"
        showDialog={showDialogApprove}
        type="Advanced Verification "
        handleSubmit={handleApproveVerification}
      />
      <ConfirmDialog
        handleClose={handleClose}
        message="Are you sure you want to reject?"
        showDialog={showDialogDenied}
        type="Advanced Verification"
        handleSubmit={handleDeniedVerification}
      />
    </Grid>
  );
};
