import { Action } from "redux";
import {
  LOAD_TRANSACTION_LIST,
  SET_TRANSACTION_LIST,
} from "../constants/transaction.constant";
import { ILoadTransactionListRequest } from "../types/meta.type";
import { IObjectTransactionList } from "../types/transaction.type";

export interface ILoadTransactionList extends Action<LOAD_TRANSACTION_LIST> {
  payload: ILoadTransactionListRequest;
}

export const loadTransactionListAction = (
  request: ILoadTransactionListRequest
): ILoadTransactionList => ({
  type: LOAD_TRANSACTION_LIST,
  payload: request,
});

export interface ISetTransactionList extends Action<SET_TRANSACTION_LIST> {
  payload: IObjectTransactionList;
}

export const setTransactionListAction = (
  transactionList: IObjectTransactionList
): ISetTransactionList => ({
  type: SET_TRANSACTION_LIST,
  payload: transactionList,
});
