import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  makeStyles,
  Grid,
  Typography,
} from "@material-ui/core";
import { loadUserApproveList } from "../../actions";
import { ILoadUserListRequest } from "../../types/user.type";
import { ITopDeposit } from "../../types/dashboard.type";
import { formatNumberToFixed } from "../../helper";
import { CardNoDataTable } from "../share/CardNoDataTable";

const styles = makeStyles({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  rootTable: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DFE0EB",
    boxSizing: "border-box",
    borderRadius: 8,
    width: "100%",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 35,
        top: 7,
        left: 0,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
});

interface IProps {
  topDeposit: ITopDeposit[];
}
export const TopDepositUser = (props: IProps) => {
  const classes = styles();

  const { topDeposit } = props;

  const [meta] = useState<ILoadUserListRequest>({
    limit: 10,
    offset: 0,
    order: {},
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserApproveList(meta));
  }, [dispatch, meta]);

  return (
    <Grid className={classes.rootTable}>
      <Grid container alignItems="center" style={{ marginTop: 32, height: 40 }}>
        <Typography variant="h3" style={{ fontSize: 20, marginLeft: 16 }}>
          Top five users with the most deposit
        </Typography>
      </Grid>
      <Grid>
        <Table style={{ borderSpacing: "inherit" }}>
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: "1%",
                }}
              >
                {"#"}
              </TableCell>
              <TableCell
                style={{
                  width: "19%",
                }}
                className={classes.tableHead}
              >
                Name
              </TableCell>
              <TableCell
                style={{
                  width: "20%",
                }}
                className={classes.tableHead}
              >
                Deposit
              </TableCell>
              <TableCell
                style={{
                  width: "20%",
                }}
                className={classes.tableHead}
              >
                Withdraw Loans
              </TableCell>
              <TableCell
                style={{
                  width: "20%",
                }}
                className={classes.tableHead}
              >
                Withdraw Assets
              </TableCell>
              <TableCell
                style={{
                  width: "20%",
                  paddingRight: 16,
                }}
                className={classes.tableHead}
                align="right"
              >
                Repayment
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topDeposit.length > 0 && topDeposit[0].deposit ? (
              topDeposit.map((el, index) => (
                <TableRow className={classes.tableCell} key={index}>
                  <TableCell className={classes.tableBody}>
                    {index + 1}
                  </TableCell>
                  <TableCell className={classes.tableBody}>
                    {el.first_name} {el.last_name}
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    style={{ color: "green" }}
                  >
                    {formatNumberToFixed(el.deposit, 2)} $
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    style={{ color: "red" }}
                  >
                    {formatNumberToFixed(el.withdraw_loan, 2)} $
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    style={{ color: "red" }}
                  >
                    {formatNumberToFixed(el.withdraw_asset, 2)} $
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    style={{ color: "green", paddingRight: 16 }}
                    align="right"
                  >
                    {formatNumberToFixed(el.repayment, 2)} $
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <CardNoDataTable colSpan={8} />
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
