import Axios, { AxiosResponse } from "axios";
import { REFRESH_TOKEN } from "./environment";

export const requestTokenAction = (refreshToken: string) => {
  return new Promise(async (resolve, reject) => {
    Axios.post(`${REFRESH_TOKEN}/${refreshToken}`)
      .then((response: AxiosResponse) => {
        if (response.data) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refresh-token", response.data.refreshToken);
          localStorage.setItem("exp", response.data.exp);
          localStorage.setItem("refreshExp", response.data.refreshExp);
        }

        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};
