import React from "react";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { NexoButton } from "./NexoButton";
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      width: "100%",
    },
    top: {
      marginBottom: 20,
      border: "3px dashed #d7dce0",
      height: 150,
      width: 130,
    },
    box: {
      padding: 5,
      position: "relative",
      "& .MuiIconButton-root": {
        color: "#4867ca",
        position: "absolute",
        right: "-10px",
        top: "-8px",
        padding: "0px",
      },
    },
    bottom: {
      position: "relative",
      overflow: "hidden",
      display: "flex",
      cursor: "pointer",
      borderRadius: 5,
      textAlign: "center",
      "& .MuiButton-root": {
        width: 250,
      },
      "& input[type=file]": {
        fontSize: 42,
      },
    },
    input: {
      fontSize: "100px",
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0,
      cursor: "pointer",
    },
  });

interface IProps extends WithStyles<typeof styles> {
  maxFiles: number;
  handleUploadFile: (files: any) => void;
  fileName?: string;
}

interface IState {
  files: any;
}

class NexoUploadFileComponent extends React.Component<IProps, IState> {
  public state: IState = {
    files: [],
  };

  public fileSelectedHandler = (e: any) => {
    const { files } = this.state;
    const { maxFiles } = this.props;
    const currentFiles = files;

    if (e.target.files) {
      Object.entries(e.target.files).map(([key, file], index) => {
        if (currentFiles.length < maxFiles) {
          currentFiles.push(file);
        }
        return currentFiles;
      });
    }
    this.props.handleUploadFile(currentFiles);
    this.setState({ files: currentFiles });
  };

  public handleDeleteFile = (key: any) => (e: any) => {
    const { files } = this.state;
    const currentFiles = files;
    currentFiles.splice(key, 1);
    this.props.handleUploadFile(currentFiles);
    this.setState({
      files: currentFiles,
    });
  };

  render() {
    const fileName =
      (`${process.env.REACT_APP_API_URL}static/images/currencies/` || "") +
      `${this.props.fileName}`;

    const { classes, maxFiles } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid className={classes.top}>
            {this.props.fileName && this.state.files.length < 1 ? (
              <div key={0} className={classes.box}>
                <img width="120" height="140" src={fileName} alt="icon" />
              </div>
            ) : undefined}
            {this.state.files &&
              [...this.state.files].map((file, key) => (
                <div key={key} className={classes.box}>
                  <img
                    width="120"
                    height="140"
                    src={URL.createObjectURL(file)}
                    alt="icon"
                  />
                  <IconButton
                    onClick={this.handleDeleteFile(key)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              ))}
          </Grid>
        </Grid>

        <Grid className={classes.bottom}>
          <NexoButton
            title={`Select ${maxFiles} File`}
            variant="contained"
            color="secondary"
          />
          <input
            multiple
            onChange={this.fileSelectedHandler}
            accept="image/*"
            type="file"
            className={classes.input}
          />
        </Grid>
      </div>
    );
  }
}
export const NexoUploadFile = withStyles(styles)(NexoUploadFileComponent);
