import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
  makeStyles,
} from "@material-ui/core";
import {
  deletePhoneUserAction,
  updateStatusGoogleAuthenicatorAction,
} from "../../actions";
import { useDispatch } from "react-redux";
import { ConfirmDialog } from "../dialog/Confirm.dialog";
import Cancel from "@material-ui/icons/Cancel";

const styles = makeStyles({
  rootSwitch: {
    margin: "5px 0px",
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: "#050F47",
      lineHeight: "28px",
      width: 330,
    },
  },
  buttonResult: {
    color: "#EB5757",
    padding: 0,
    border: "1px solid #EB5757",
    height: 26,
    "&:hover": {
      color: "#f90505",
    },
  },
});

interface Iprops {
  title: string;
  value: string | number;
  id?: string | undefined;
  checked?: boolean;
}

export const ItemUser = (props: Iprops) => {
  const dispatch = useDispatch();
  const classes = styles();
  const { title, value, id } = props;
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [type, setType] = useState<string>("phone");

  const handleOpenDialog = (type: string) => () => {
    setOpenConfirmDialog(true);
    setType(type);
  };

  const handleSubmit = () => {
    if (id) {
      if (type === "phone") {
        dispatch(deletePhoneUserAction({ id }));
      } else {
        dispatch(updateStatusGoogleAuthenicatorAction({ id }));
        setChecked(!checked);
      }
    }
    setOpenConfirmDialog(!openConfirmDialog);
  };

  useEffect(() => {
    if (props.checked) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  let mesage =
    type === "phone"
      ? "Please confirm you want to delete the phone number?"
      : "Please confirm you want to update status google authenicator?";

  return (
    <Grid container direction="column" style={{ width: "100%" }}>
      <Grid container justify="space-between">
        {title === "Google Authenticator / Authy" ? (
          <FormControlLabel
            className={classes.rootSwitch}
            style={{
              color: "#050f474f",
              fontSize: 13,
              paddingLeft: 16,
              marginLeft: 0,
            }}
            control={
              <Switch checked={checked} onChange={handleOpenDialog("google")} />
            }
            label="Google Authenticator / Authy"
            labelPlacement="start"
            disabled={!checked}
          />
        ) : (
          <Typography
            variant="h4"
            style={{ color: "#050f474f", fontSize: 13, paddingLeft: 16 }}
          >
            {title}
          </Typography>
        )}
      </Grid>

      <Grid container>
        <Typography
          variant="h4"
          style={{
            paddingBottom: 3,
            fontSize: 15,
            paddingLeft: 16,
            marginRight: 10,
          }}
        >
          {value}
          {value === null ? "-" : undefined}
        </Typography>

        {title === "Phone" && value !== null ? (
          <Tooltip title="Delete Phone">
            <IconButton
              onClick={handleOpenDialog("phone")}
              className={classes.buttonResult}
            >
              <Cancel style={{ padding: 0 }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Grid>

      <Grid
        style={{ borderBottom: "1px dashed rgb(215, 220, 224)", width: "100%" }}
      />
      <ConfirmDialog
        showDialog={openConfirmDialog}
        handleClose={setOpenConfirmDialog}
        message={mesage}
        type="USER PROFILE"
        handleSubmit={handleSubmit}
      />
    </Grid>
  );
};
