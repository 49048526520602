import React, { useState, useEffect } from "react";
import { formatDateTime, fortmatJson, useForceUpdate } from "../helper";
import {
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/share/Pagination";
import { loadActivityLogsListAction } from "../actions/activityLogs.action";
import {
  ILoadActivityLogsListRequest,
  ISelectCategory,
} from "../types/activityLogs.type";
import { IStoreState } from "../types";
import classNames from "classnames";
import { NexoAutocomplete } from "../components/share/NexoAutocomplete";
import { DataCategoryActivityLog } from "../constants/activityLogs.constants";
import { ProfileCard } from "../components/ProfileCard";
import { RootTable } from "../components/RootTable";
import _ from "lodash";
import Search from "@material-ui/icons/Search";
import showItem from "../assets/images/showItem.svg";

const styles = makeStyles({
  autocomplete: {
    height: 50,
    width: 180,
    marginRight: 20,
  },
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  rootTable: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DFE0EB",
    boxSizing: "border-box",
    borderRadius: 8,
  },
  rootInputSeach: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "#9FA2B4",
    },
    "& .MuiInputBase-input": {
      color: "#9FA2B4",
      fontSize: 15,
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "20px",
      fontStyle: "italic",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.09) !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.09) !important",
    },
  },
});

export const ActivityLogs = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const forceUpdate = useForceUpdate();
  const [meta, setMeta] = useState<ILoadActivityLogsListRequest>({
    limit: 10,
    offset: 0,
    search: "",
    category: "",
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const activityLogs = useSelector((state: IStoreState) => state.activityLogs);
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["Activity Log"];

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };

  const handleChangePage = (event: any, page: number) => {
    setMeta({
      ...meta,
      limit: rowsPerPage,
      offset: page * activityLogs.meta.limit,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMeta({
      ...meta,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    });
  };

  const handleChildContent = (id: string) => () => {
    const index = selectedRows.findIndex((row) => row === id);
    const newSelectedRows = selectedRows;
    if (index !== -1) {
      if (newSelectedRows.length >= 2 && index !== newSelectedRows.length - 1) {
        newSelectedRows[index] = newSelectedRows[newSelectedRows.length - 1];
      }
      newSelectedRows.pop();
    } else {
      newSelectedRows.push(id);
    }

    setSelectedRows(newSelectedRows);
    forceUpdate();
  };

  const debonuceSearch = _.debounce((search: string) => {
    setMeta({
      ...meta,
      search,
      limit: 10,
      offset: 0,
    });
  }, 500);

  const handleChangeInputSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => debonuceSearch(event.target.value);

  const handleChangeType = (event: any, value: ISelectCategory | null) => {
    setMeta({
      ...meta,
      category: value?.title,
      offset: 0,
    });
  };

  useEffect(() => {
    dispatch(loadActivityLogsListAction(meta));
  }, [meta, dispatch]);

  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <RootTable
        nameTable="All Activity log"
        ActionsComponent={
          <Grid className={classes.rootInputSeach}>
            <Grid container>
              <Grid item style={{ width: 200, marginRight: 50 }}>
                <NexoAutocomplete
                  content=""
                  placeholderInput="All Category"
                  dataSelect={DataCategoryActivityLog}
                  onChangeType={handleChangeType}
                />
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Search here..."
                  onChange={handleChangeInputSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      >
        <Table>
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: "1%",
                }}
              >
                {"#"}
              </TableCell>
              <TableCell
                style={{
                  width: "24%",
                }}
                className={classes.tableHead}
              >
                Create
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                }}
                className={classes.tableHead}
              >
                Category
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                }}
                className={classes.tableHead}
              >
                Log
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  paddingRight: 16,
                }}
                align="right"
                className={classes.tableHead}
              >
                Content
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityLogs.data.length ? (
              activityLogs.data.map((el, index) => {
                const content = fortmatJson(el.content);
                let detailContent: any = [];

                const addContent = (dataKey: string, dataValue: string) => {
                  detailContent.push(
                    <Grid container key={dataKey}>
                      <Grid item xs={3}>
                        <Typography variant="h4" style={{ fontWeight: "bold" }}>
                          {dataKey}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h4">{dataValue}</Typography>
                      </Grid>
                    </Grid>
                  );
                };

                const showContent = () => {
                  if (content) {
                    for (let [key, value] of Object.entries<any>(content)) {
                      if (typeof value === "object") {
                        for (let [keyDetail, valueDetail] of Object.entries<
                          any
                        >(value)) {
                          if (keyDetail === "code") {
                            valueDetail = fortmatJson(valueDetail);
                          }

                          if (typeof valueDetail === "object" && valueDetail) {
                            for (let [
                              keyContent,
                              valueContent,
                            ] of Object.entries<any>(valueDetail)) {
                              addContent(keyContent, valueContent);
                            }
                          } else {
                            addContent(keyDetail, valueDetail);
                          }
                        }
                      } else {
                        addContent(key, value);
                      }
                    }
                  }
                  return detailContent;
                };

                const showDialogContent = selectedRows.find(
                  (row) => row === el.id
                );
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableCell}>
                      <TableCell className={classes.tableBody}>
                        {index +
                          1 +
                          activityLogs.meta.limit *
                            (activityLogs.meta.currentPage - 1)}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {formatDateTime(el.createdAt)}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.category}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.log}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 16,
                        }}
                        className={classNames(classes.tableBody)}
                      >
                        <Tooltip title="View" aria-label="add">
                          <IconButton
                            onClick={handleChildContent(el.id)}
                            style={{
                              width: 30,
                              height: 30,
                              padding: 0,
                            }}
                          >
                            <img
                              src={showItem}
                              alt="icon"
                              width={15}
                              height={15}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    {showDialogContent ? (
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: "#e4eaee",
                          }}
                          colSpan={8}
                        >
                          <Grid container spacing={1}>
                            <Grid container direction="row">
                              {showContent()}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })
            ) : (
              <React.Fragment>
                <TableRow className={classes.tableCell}>
                  <TableCell
                    className={classes.tableBody}
                    colSpan={8}
                    style={{ textAlign: "center" }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>
        <Grid style={{ width: "100%" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={activityLogs.meta.total ? activityLogs.meta.total : 0}
            rowsPerPage={rowsPerPage}
            page={
              activityLogs.meta.currentPage
                ? activityLogs.meta.currentPage - 1
                : 0
            }
            onChangePage={handleChangePage}
            ActionsComponent={Pagination}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </RootTable>
    </ProfileCard>
  );
};
