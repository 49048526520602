import { Action } from "redux";
import {
  LOAD_CONFIG_LIST,
  SET_CONFIG_LIST,
  UPDATE_CONFIG,
  ADD_CONFIG,
  DELETE_CONFIG,
} from "../constants/config.constants";
import {
  IObjectConfig,
  IUpdateConfig,
  IAddConfig,
  IDeleteConfig,
} from "../types/config.type";

export interface ILoadConfigListAction extends Action<LOAD_CONFIG_LIST> {}

export const loadConfigList = (): ILoadConfigListAction => ({
  type: LOAD_CONFIG_LIST,
});

export interface ISetConfigListAction extends Action<SET_CONFIG_LIST> {
  payload: IObjectConfig;
}

export const setConfigList = (
  request: IObjectConfig
): ISetConfigListAction => ({
  type: SET_CONFIG_LIST,
  payload: request,
});

export interface IUpdateConfigAction extends Action<UPDATE_CONFIG> {
  payload: IUpdateConfig;
}

export const updateConfigAction = (
  request: IUpdateConfig
): IUpdateConfigAction => ({
  type: UPDATE_CONFIG,
  payload: request,
});

export interface IAddConfigAction extends Action<ADD_CONFIG> {
  payload: IAddConfig;
}

export const addConfigAction = (request: IAddConfig): IAddConfigAction => ({
  type: ADD_CONFIG,
  payload: request,
});

export interface IDeleteConfigAction extends Action<DELETE_CONFIG> {
  payload: IDeleteConfig;
}

export const deleteConfigAction = (
  request: IDeleteConfig
): IDeleteConfigAction => ({
  type: DELETE_CONFIG,
  payload: request,
});
