import { IObjectDashboard } from "../types/dashboard.type";
import { ISetDashboardDataAction } from "../actions/dashboard.action";
import { SET_DASHBOARD_DATA } from "../constants/dashboard.constants";

const initialDashboard: IObjectDashboard = {
  asset_withdrawal: "0",
  btc_balance: "0",
  btc_withdrawal: "0",
  btc_withdrawal_value: "0",
  deposit_btc: "0",
  deposit_btc_value: "0",
  deposit_eth: "0",
  deposit_eth_value: "0",
  deposit_value: "0",
  deposit_xrp: "0",
  deposit_xrp_value: "0",
  eth_balance: "0",
  eth_withdrawal: "0",
  eth_withdrawal_value: "0",
  loan_interest: "0",
  loan_withdrawal: "0",
  pendingAdvancedVerificationTotal: "0",
  repayment_btc: "0",
  repayment_btc_value: "0",
  repayment_eth: "0",
  repayment_eth_value: "0",
  repayment_usdt: "0",
  repayment_usdt_value: "0",
  repayment_value: "0",
  repayment_xrp: "0",
  repayment_xrp_value: "0",
  saving: "0",
  saving_interest: "0",
  saving_withdrawal: "0",
  userTotal: "0",
  xrp_balance: "0",
  xrp_withdrawal: "0",
  xrp_withdrawal_value: "0",
  topDeposit: [
    {
      deposit: "",
      email: "",
      first_name: "",
      last_name: "",
      repayment: "",
      user_id: "",
      withdraw_asset: "",
      withdraw_loan: "",
    },
  ],
};

export const dashboard = (
  state: IObjectDashboard = initialDashboard,
  action: ISetDashboardDataAction
) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return action.payload;

    default:
      return state;
  }
};
