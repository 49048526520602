import React from "react";
import {
  Grid,
  Table,
  makeStyles,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import { RootTable } from "../RootTable";
import { CardNoDataTable } from "../share/CardNoDataTable";
import {
  formatNumberToFixed,
  getBlockchainExplorerLink,
  formatDateTime,
} from "../../helper";
import Pagination from "../share/Pagination";
import { IObjectWalletUser } from "../../types/wallet.type";
import { Link } from "react-router-dom";
import { DVT_USD } from "../../constants/transaction.constant";

const styles = makeStyles({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  paddingTableHead: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

interface IProps {
  wallets: IObjectWalletUser;
}
export const Wallet = (props: IProps) => {
  const classes = styles();
  const { wallets } = props;
  const handleChangePage = (event: any, page: number) => {};

  const handleChangeRowsPerPage = (event: any) => {};
  return (
    <Grid
      style={{
        marginTop: 50,
      }}
    >
      <RootTable nameTable="All Wallets">
        <Table>
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: "1%",
                }}
              >
                {"#"}
              </TableCell>
              <TableCell
                style={{
                  width: "19%",
                }}
                align="center"
                className={classes.tableHead}
              >
                Currency
              </TableCell>

              <TableCell
                align="right"
                style={{
                  width: "15%",
                }}
                className={classes.tableHead}
              >
                Balance
              </TableCell>

              <TableCell
                align="right"
                style={{
                  width: "20%",
                }}
                className={classes.tableHead}
              >
                Credit Line
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: "15%",
                }}
                className={classes.tableHead}
              >
                Last Transaction
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: "30%",
                  paddingRight: 16,
                }}
                className={classes.tableHead}
              >
                Address
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wallets.wallets.length ? (
              wallets.wallets.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableCell}>
                      <TableCell className={classes.tableBody}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        className={classes.tableBody}
                      >
                        <img
                          src={
                            (`${process.env.REACT_APP_API_URL}static/images/currencies/` ||
                              "") + el.currency.image
                          }
                          width={25}
                          height={25}
                          alt="icon currency"
                        />
                      </TableCell>

                      <TableCell align="right" className={classes.tableBody}>
                        {el.balance
                          ? formatNumberToFixed(el.balance, 2) +
                            " " +
                            el.currency.symbol
                          : "-"}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{ color: "#3b2e95" }}
                        className={classes.tableBody}
                      >
                        {el.creditLine
                          ? formatNumberToFixed(el.creditLine, 2) + DVT_USD
                          : "-"}
                      </TableCell>
                      <TableCell align="right" className={classes.tableBody}>
                        {el.updatedAt ? formatDateTime(el.updatedAt) : ""}
                      </TableCell>
                      <TableCell
                        className={classes.tableBody}
                        style={{ paddingRight: 16 }}
                        align="right"
                      >
                        {el.address ? (
                          <Link
                            style={{
                              color: "#3DACEB ",
                              textDecoration: "none",
                            }}
                            to={{
                              pathname: getBlockchainExplorerLink(
                                el.currency.symbol,
                                el.address
                              ),
                            }}
                            target="true"
                          >
                            {el.address}
                          </Link>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <CardNoDataTable colSpan={7} />
            )}
          </TableBody>
        </Table>
        <Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={wallets.wallets ? wallets.wallets.length : 0}
            rowsPerPage={10}
            page={0}
            onChangePage={handleChangePage}
            ActionsComponent={Pagination}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </RootTable>
    </Grid>
  );
};
