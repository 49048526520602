import { MONTH_KEY, MONTH_NAME } from "../constants/dashboard.constants";

/* eslint-disable no-undef */
/* check connect wifi */
let online = navigator.onLine;

export function drawChart(dashboard, title) {
  if (online) {
    google.charts.load("current", { packages: ["corechart"] }).then(() => {
      let dataStatistical = [
        ["Task", "Hours per Day"],
        ["BTC Deposit", parseInt(dashboard.deposit_btc_value, 10)],
        ["ETH Deposit", parseInt(dashboard.deposit_eth_value, 10)],
        ["XRP Deposit", parseInt(dashboard.deposit_xrp_value, 10)],
      ];

      switch (title) {
        case "Repayment":
          dataStatistical = [
            ["Task", "Hours per Day"],
            ["BTC Repayment", parseInt(dashboard.repayment_btc_value, 10)],
            ["ETH Repayment", parseInt(dashboard.repayment_eth_value, 10)],
            ["XRP Repayment", parseInt(dashboard.repayment_xrp_value, 10)],
            ["USDT Repayment", parseInt(dashboard.repayment_usdt_value, 10)],
          ];
          break;
        case "Deposit":
          dataStatistical = [
            ["Task", "Hours per Day"],
            ["BTC Deposit", parseInt(dashboard.deposit_btc_value, 10)],
            ["ETH Deposit", parseInt(dashboard.deposit_eth_value, 10)],
            ["XRP Deposit", parseInt(dashboard.deposit_xrp_value, 10)],
          ];
          break;

        default:
          break;
      }
      google.charts.setOnLoadCallback(() => {
        if (google.visualization) {
          var data = google.visualization.arrayToDataTable(dataStatistical);

          var options = {
            // title: title + ": " + parseInt(dashboard.deposit, 10) + " (USD)",
            is3D: true,
            width: 375,
            height: 300,
            chartArea: { left: 0, top: 30, width: "95%", height: "70%" },
            // legend: {
            //   position: "bottom",
            //   textStyle: { color: "#A6ACBE" },
            // },
            legend: "none",
            fontName: "Montserrat",
            tooltip: {
              showColorCode: true,
            },
            fontSize: 12,
            slices: {
              0: { color: "#8067dc" },
              1: { color: "#c767dc" },
              2: { color: "#a367dc" },
              3: { color: "#6794dc" },
              4: { color: "#67b7dc" },
            },
          };

          var chart = new google.visualization.PieChart(
            document.getElementById("chart_div")
          );

          chart.draw(data, options);
        }
      });
    });
  }
}

export function drawVisualization(dashboard) {
  let dataSummaryByMonth = [];
  MONTH_KEY.map((value, key) => {
    dataSummaryByMonth.push({
      asset_withdrawal: 0,
      deposit: 0,
      loan_withdrawal: 0,
      month: MONTH_KEY[key],
      repayment: 0,
      saving_withdrawal: 0,
      saving: 0,
    });
    return dataSummaryByMonth;
  });

  if (dashboard.summaryByMonth) {
    dashboard.summaryByMonth.map((item, index) => {
      dataSummaryByMonth.map((value, key) => {
        if (item.month === value.month) {
          dataSummaryByMonth[key].asset_withdrawal = item.asset_withdrawal;
          dataSummaryByMonth[key].deposit = item.deposit;
          dataSummaryByMonth[key].loan_withdrawal = item.loan_withdrawal;
          dataSummaryByMonth[key].repayment = item.repayment;
          dataSummaryByMonth[key].saving_withdrawal = item.saving_withdrawal;
          dataSummaryByMonth[key].saving = item.saving;
        }
        return dataSummaryByMonth;
      });
      return dataSummaryByMonth;
    });
  }
  let dataShow = [
    [
      "Month",
      "Deposit",
      "Repayment",
      "Withdraw Loans",
      "Withdaw Assets",
      "Withdaw Savings",
      "Saving",
    ],
  ];

  dataSummaryByMonth.map((value, index) => {
    dataShow.push([
      MONTH_NAME[index],
      value.deposit,
      value.repayment,
      value.loan_withdrawal,
      value.asset_withdrawal,
      value.saving_withdrawal,
      value.saving,
    ]);
    return dataShow;
  });

  // Some raw data (not necessarily accurate)
  if (online) {
    google.charts.load("current", { packages: ["corechart"] }).then(() => {
      google.charts.setOnLoadCallback(() => {
        if (google.visualization) {
          var data = google.visualization.arrayToDataTable(dataShow);

          var options = {
            title: "",
            vAxis: {
              title: "USD",
              titleTextStyle: {
                bold: true,
              },
            },
            hAxis: {
              title: "Month",
              titleTextStyle: {
                bold: true,
              },
            },
            seriesType: "bars",
            height: 375,
            width: "100%",
            chartArea: { left: 70, top: 50, width: "100%", height: "50%" },
            legend: {
              position: "bottom",
              textStyle: { color: "#A6ACBE" },
            },
            fontSize: 13,
            fontName: "Montserrat",
            series: {
              0: { color: "#8067dc" },
              1: { color: "#c767dc" },
              2: { color: "#67b7dc" },
              3: { color: "#6794dc" },
              4: { color: "#dc6967" },
              5: { color: "#dc8c67" },
            },
            tooltip: {
              showColorCode: true,
            },
          };

          var chart = new google.visualization.ComboChart(
            document.getElementById("chart_column")
          );
          chart.draw(data, options);
        }
      });
    });
  }
}
