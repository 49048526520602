import { Store } from "redux";
import { IStoreState } from "../types";
import { Dispatch } from "react";
import {
  ILoginAction,
  ILogoutAction,
  ILoadUserProfileAction,
  setUserProfileAction,
} from "../actions/auth.action";
import { LOGIN, LOGOUT, LOAD_USER_PROFILE } from "../constants/auth.constant";
import Axios, { AxiosResponse } from "axios";
import { PATH } from "../constants/PATH";
import { fireNotification } from "../actions/Notification.action";
import { MESSAGE_NOTIFICATION_ERROR } from "../constants/Notification.constant";
import Variant from "../types/Variant.type";
import { IUserInfo } from "../types/user.type";

export const loginMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ILoginAction | ILogoutAction | ILoadUserProfileAction>
) => (action: ILoginAction | ILogoutAction | ILoadUserProfileAction) => {
  switch (action.type) {
    case LOGIN: {
      Axios.post("/auth", action.payload)
        .then((res) => {
          const token = res.data;
          if (token.token) {
            if (token.role === "Admin") {
              localStorage.setItem("token", token.token);
              localStorage.setItem("refresh-token", token.refreshToken);
              localStorage.setItem("exp", token.exp);
              localStorage.setItem("refreshExp", token.refreshExp);
              localStorage.setItem("role", token.role);
              window.location.href = PATH.users;
            } else {
              store.dispatch(
                fireNotification({
                  message: "You must login to the admin account",
                  variant: Variant.ERROR,
                })
              );
            }
          }
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
    case LOAD_USER_PROFILE: {
      Axios.get(`/user/profile`)
        .then((data: AxiosResponse<IUserInfo>) => {
          if (data.data) {
            store.dispatch(
              setUserProfileAction({
                ...data.data,
              })
            );
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }

    case LOGOUT: {
      localStorage.clear();
      window.location.href = PATH.login;
      break;
    }
  }
  return dispatch(action);
};
