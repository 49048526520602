import { Action } from "redux";
import {
  ILoadWithdrawRequest,
  IObjectListWithdrawLoan,
  IOjectListWithdrawAsset,
  IWithdrawRequest,
} from "../types/withdraw.type";
import {
  LOAD_WITHDRAW_LOAN_ACTION,
  SET_WITHDRAW_LOAN_ACTION,
  SET_WITHDRAW_ASSET_ACTION,
  LOAD_WITHDRAW_ASSET_ACTION,
  APPROVE_WITHDRAW_ACTION,
  DENIED_WITHDRAW_ACTION,
  SET_WITHDRAW_SAVING_ACTION,
  LOAD_WITHDRAW_SAVING_ACTION,
} from "../constants/withdraw.constant";

export interface ILoadWithdrawLoanAction
  extends Action<LOAD_WITHDRAW_LOAN_ACTION> {
  payload: ILoadWithdrawRequest;
}

export const loadWithdrawLoanAction = (
  request: ILoadWithdrawRequest
): ILoadWithdrawLoanAction => ({
  type: LOAD_WITHDRAW_LOAN_ACTION,
  payload: request,
});

export interface ISetWithdrawLoanAction
  extends Action<SET_WITHDRAW_LOAN_ACTION> {
  payload: IObjectListWithdrawLoan;
}

export const setWithdrawLoanAction = (
  request: IObjectListWithdrawLoan
): ISetWithdrawLoanAction => ({
  type: SET_WITHDRAW_LOAN_ACTION,
  payload: request,
});

export interface ILoadWithdrawAssetAction
  extends Action<LOAD_WITHDRAW_ASSET_ACTION> {
  payload: ILoadWithdrawRequest;
}

export const loadWithdrawAssetAction = (
  request: ILoadWithdrawRequest
): ILoadWithdrawAssetAction => ({
  type: LOAD_WITHDRAW_ASSET_ACTION,
  payload: request,
});

export interface ISetWithdrawAssetAction
  extends Action<SET_WITHDRAW_ASSET_ACTION> {
  payload: IOjectListWithdrawAsset;
}

export const setWithdrawAssetAction = (
  request: IOjectListWithdrawAsset
): ISetWithdrawAssetAction => ({
  type: SET_WITHDRAW_ASSET_ACTION,
  payload: request,
});

export interface IApproveWithdrawAction
  extends Action<APPROVE_WITHDRAW_ACTION> {
  payload: IWithdrawRequest;
}

export const approveWithdrawAction = (
  request: IWithdrawRequest
): IApproveWithdrawAction => ({
  type: APPROVE_WITHDRAW_ACTION,
  payload: request,
});
export interface IDeniedWithdrawAction extends Action<DENIED_WITHDRAW_ACTION> {
  payload: IWithdrawRequest;
}

export const deniedWithdrawAction = (
  request: IWithdrawRequest
): IDeniedWithdrawAction => ({
  type: DENIED_WITHDRAW_ACTION,
  payload: request,
});

export interface ILoadWithdrawSavingAction
  extends Action<LOAD_WITHDRAW_SAVING_ACTION> {
  payload: ILoadWithdrawRequest;
}

export const loadWithdrawSavingAction = (
  request: ILoadWithdrawRequest
): ILoadWithdrawSavingAction => ({
  type: LOAD_WITHDRAW_SAVING_ACTION,
  payload: request,
});

export interface ISetWithdrawSavingAction
  extends Action<SET_WITHDRAW_SAVING_ACTION> {
  payload: IOjectListWithdrawAsset;
}

export const setWithdrawSavingAction = (
  request: IOjectListWithdrawAsset
): ISetWithdrawSavingAction => ({
  type: SET_WITHDRAW_SAVING_ACTION,
  payload: request,
});
