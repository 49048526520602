import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../types";
import Pagination from "../components/share/Pagination";
import {
  loadCurencyList,
  deleteCurencyAction,
} from "../actions/currencies.action";
import { formatDateTime, formatNumberToFixed } from "../helper";
import iconDelete from "../assets/images/delete.svg";
import iconUpdate from "../assets/images/edit.svg";
import { NexoButton } from "../components/share/NexoButton";
import { ILoadCurencyListRequest, ICurrency } from "../types/currencies.type";
import { ConfirmDialog } from "../components/dialog/Confirm.dialog";
import { CreateCurrency } from "../components/dialog/currencies/CreateCurrency.dialog";
import { UpdateCurrency } from "../components/dialog/currencies/UpdateCurrencyDialog";
import { ProfileCard } from "../components/ProfileCard";

const styles = makeStyles({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  rootTable: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DFE0EB",
    boxSizing: "border-box",
    borderRadius: 8,
  },
});

export const Currencies = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const [meta, setMeta] = useState<ILoadCurencyListRequest>({
    limit: 10,
    offset: 0,
  });
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openEditCurencyDialog, setOpenEditCurrencyDialog] = useState<boolean>(
    false
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currencyId, setCurrencyId] = useState<string>("");
  const [currency, setCurrency] = useState<ICurrency>({
    id: "",
    name: "",
    ltv: "",
    interest: "",
    symbol: "",
    image: "",
    loanDisabled: true,
    loanHidden: true,
    interestDisabled: true,
    interestHidden: true,
    isShow: true,
    createdAt: "",
    updatedAt: new Date(),
    deletedAt: null,
  });
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["Currencies"];

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };
  const listCurrencies = useSelector(
    (state: IStoreState) => state.currencyList
  );

  const handleChangePage = (event: any, page: number) => {
    setMeta({
      ...meta,
      limit: rowsPerPage,
      offset: page * listCurrencies.meta.limit,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMeta({
      ...meta,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    });
  };

  const handleCurrencyDelete = (id: string) => () => {
    setCurrencyId(id);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCurrencyUpdate = (data: ICurrency) => () => {
    setCurrency(data);
    setOpenEditCurrencyDialog(!openEditCurencyDialog);
  };
  const handleShowDialogCreateCurrency = () => {
    setOpenCreateDialog(true);
  };

  const handleSubmitCurrencyDelete = () => {
    dispatch(
      deleteCurencyAction({
        id: currencyId,
      })
    );
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCloseDialogEdit = () => {
    setOpenEditCurrencyDialog(false);
  };

  useEffect(() => {
    dispatch(loadCurencyList(meta));
  }, [meta, dispatch]);

  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <Grid container className={classes.rootTable}>
        <Grid
          container
          alignItems="center"
          style={{ marginTop: 30, marginBottom: 10 }}
        >
          <Grid item xs={3}>
            <Typography variant="h3" style={{ fontSize: 20, marginLeft: 16 }}>
              All Currencies
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={9}
            justify="flex-end"
            style={{ height: 40, paddingRight: 16 }}
          >
            <Grid style={{ width: 200 }}>
              <NexoButton
                title="Add Currency"
                variant="contained"
                heightButton={40}
                color="primary"
                handleRedirect={handleShowDialogCreateCurrency}
              />
            </Grid>
          </Grid>
        </Grid>

        <Table>
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: "1%",
                }}
              >
                {"#"}
              </TableCell>
              <TableCell
                style={{
                  width: "19%",
                }}
                className={classes.tableHead}
              >
                Name
              </TableCell>
              <TableCell
                style={{
                  width: "16%",
                }}
                className={classes.tableHead}
              >
                Image
              </TableCell>
              <TableCell
                style={{
                  width: "16%",
                }}
                className={classes.tableHead}
              >
                Symbol
              </TableCell>
              <TableCell
                style={{
                  width: "16%",
                }}
                className={classes.tableHead}
              >
                Ltv
              </TableCell>
              <TableCell
                style={{
                  width: "16%",
                }}
                className={classes.tableHead}
              >
                Date/Time
              </TableCell>
              <TableCell
                style={{
                  width: "16%",
                  paddingRight: 16,
                }}
                align={"right"}
                className={classes.tableHead}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listCurrencies.data.length ? (
              listCurrencies.data.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableCell}>
                      <TableCell className={classes.tableBody}>
                        {index +
                          1 +
                          listCurrencies.meta.limit *
                            (listCurrencies.meta.currentPage - 1)}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.name}
                      </TableCell>

                      <TableCell
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        className={classes.tableBody}
                      >
                        <img
                          src={
                            (`${process.env.REACT_APP_API_URL}static/images/currencies/` ||
                              "") + el.image
                          }
                          width={25}
                          height={25}
                          alt="icon currency"
                        />
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.symbol}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.ltv ? formatNumberToFixed(el.ltv, 2) + " %" : ""}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {" "}
                        {el.createdAt ? formatDateTime(el.createdAt) : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 16,
                        }}
                        className={classes.tableBody}
                        align={"right"}
                      >
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={handleCurrencyDelete(el.id)}
                            style={{
                              width: 30,
                              height: 30,
                              padding: 0,
                            }}
                          >
                            <img
                              src={iconDelete}
                              alt="icon"
                              width={15}
                              height={15}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={handleCurrencyUpdate(el)}
                            style={{
                              width: 30,
                              height: 30,
                              padding: 0,
                            }}
                          >
                            <img
                              src={iconUpdate}
                              alt="icon"
                              width={15}
                              height={15}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <React.Fragment>
                <TableRow className={classes.tableCell}>
                  <TableCell
                    className={classes.tableBody}
                    colSpan={7}
                    style={{ textAlign: "center" }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>
        <Grid style={{ width: "100%" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={listCurrencies.meta.total ? listCurrencies.meta.total : 0}
            rowsPerPage={rowsPerPage}
            page={
              listCurrencies.meta.currentPage
                ? listCurrencies.meta.currentPage - 1
                : 0
            }
            onChangePage={handleChangePage}
            ActionsComponent={Pagination}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
        <ConfirmDialog
          showDialog={openConfirmDialog}
          handleClose={setOpenConfirmDialog}
          message="Please confirm you want to delete?"
          type="CURRENCY"
          handleSubmit={handleSubmitCurrencyDelete}
        />
        <CreateCurrency
          showDialog={openCreateDialog}
          handleCloseDialog={setOpenCreateDialog}
          textHeader="Add Currency"
        />
        <UpdateCurrency
          showDialog={openEditCurencyDialog}
          handleCloseDialog={handleCloseDialogEdit}
          textHeader="Update Currency"
          data={currency}
        />
      </Grid>
    </ProfileCard>
  );
};
