import { Action } from "redux";
import {
  SET_CURRENCY_LIST,
  LOAD_CURRENCY_LIST,
  CREATE_CURRENCY_ACTION,
  DELETE_CURRENCY_ACTION,
  UPDATE_CURRENCY_ACTION,
} from "../constants/currencies.constant";
import {
  IObjectCurrencyList,
  ILoadCurencyListRequest,
  ICreateCurrencyRequest,
  IDeleteCurrencyRequest,
  IUpdateCurrencyRequest,
} from "../types/currencies.type";

export interface ISetCurrencyList extends Action<SET_CURRENCY_LIST> {
  payload: IObjectCurrencyList;
}

export const setCurrencyListAction = (
  currencyList: IObjectCurrencyList
): ISetCurrencyList => {
  return {
    type: SET_CURRENCY_LIST,
    payload: currencyList,
  };
};

export interface ILoadCurrencyListAction extends Action<LOAD_CURRENCY_LIST> {
  payload: ILoadCurencyListRequest;
}

export const loadCurencyList = (
  request: ILoadCurencyListRequest
): ILoadCurrencyListAction => ({
  type: LOAD_CURRENCY_LIST,
  payload: request,
});

export interface ICreateCurrencyAction extends Action<CREATE_CURRENCY_ACTION> {
  payload: ICreateCurrencyRequest;
}

export const createCurrencyAction = (
  request: ICreateCurrencyRequest
): ICreateCurrencyAction => ({
  type: CREATE_CURRENCY_ACTION,
  payload: request,
});

export interface IDeleteCurrencyAction extends Action<DELETE_CURRENCY_ACTION> {
  payload: IDeleteCurrencyRequest;
}

export const deleteCurencyAction = (
  request: IDeleteCurrencyRequest
): IDeleteCurrencyAction => ({
  type: DELETE_CURRENCY_ACTION,
  payload: request,
});

export interface IUpdateCurrencyAction extends Action<UPDATE_CURRENCY_ACTION> {
  payload: IUpdateCurrencyRequest;
}

export const updateCurrencyAction = (
  request: IUpdateCurrencyRequest
): IUpdateCurrencyAction => ({
  type: UPDATE_CURRENCY_ACTION,
  payload: request,
});
