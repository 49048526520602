import Axios, { AxiosResponse, AxiosError } from "axios";
import { IObjectCurrencyList } from "../types/currencies.type";
import {
  setCurrencyListAction,
  ILoadCurrencyListAction,
  ISetCurrencyList,
  ICreateCurrencyAction,
  IDeleteCurrencyAction,
  IUpdateCurrencyAction,
  loadCurencyList,
} from "../actions/currencies.action";
import { Dispatch } from "react";
import { IStoreState } from "../types";
import {
  LOAD_CURRENCY_LIST,
  CREATE_CURRENCY_ACTION,
  DELETE_CURRENCY_ACTION,
  UPDATE_CURRENCY_ACTION,
} from "../constants/currencies.constant";
import { Store } from "redux";
import Variant from "../types/Variant.type";
import { fireNotification } from "../actions/Notification.action";

export const getCurrencyList = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadCurrencyListAction
    | ISetCurrencyList
    | ICreateCurrencyAction
    | IDeleteCurrencyAction
    | IUpdateCurrencyAction
  >
) => async (
  action:
    | ILoadCurrencyListAction
    | ICreateCurrencyAction
    | IDeleteCurrencyAction
    | IUpdateCurrencyAction
) => {
  const meta = { limit: 10, offset: 0 };
  switch (action.type) {
    case LOAD_CURRENCY_LIST: {
      Axios.get(`/currencies`, {
        params: {
          limit: action.payload.limit,
          offset: action.payload.offset,
        },
      })
        .then((response: AxiosResponse<IObjectCurrencyList>) => {
          if (response && response.data) {
            store.dispatch(setCurrencyListAction(response.data));
          }
          return response;
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
    }
    case CREATE_CURRENCY_ACTION: {
      const formData = new FormData();
      formData.append("name", action.payload.name);
      formData.append("image", action.payload.image[0]);
      formData.append("ltv", action.payload.ltv);
      formData.append("interest", action.payload.interest);
      formData.append("symbol", action.payload.symbol);
      formData.append("loanDisabled", action.payload.loanDisabled);
      formData.append("loanHidden", action.payload.loanHidden);
      formData.append("interestDisabled", action.payload.interestDisabled);
      formData.append("interestHidden", action.payload.interestHidden);
      formData.append("isShow", action.payload.isShow);
      Axios.post(`/currencies`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `  Create Currency Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(loadCurencyList(meta));
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
    case DELETE_CURRENCY_ACTION: {
      Axios.delete(`/currencies/${action.payload.id}`)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `  Delete Currency Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(loadCurencyList(meta));
        })
        .catch((err: AxiosError) => {
          store.dispatch(
            fireNotification({
              message:
                err.response && err.response.data.error.message
                  ? err.response.data.error.message
                  : "error",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
    case UPDATE_CURRENCY_ACTION: {
      const formData = new FormData();
      formData.append("name", action.payload.name);
      if (action.payload.image.length > 0) {
        if (typeof action.payload.image === "object")
          formData.append("image", action.payload.image[0]);
      }
      formData.append("ltv", action.payload.ltv);
      formData.append("interest", action.payload.interest);
      formData.append("symbol", action.payload.symbol);
      formData.append("loanDisabled", action.payload.loanDisabled);
      formData.append("loanHidden", action.payload.loanHidden);
      formData.append("interestDisabled", action.payload.interestDisabled);
      formData.append("interestHidden", action.payload.interestHidden);
      formData.append("isShow", action.payload.isShow);
      Axios.patch(`/currencies/${action.payload.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `  Update Currency Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(loadCurencyList(meta));
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
  }
  return dispatch(action);
};
