import { Action } from "redux";
import {
  LOAD_ACTIVITY_LOGS_LIST,
  SET_ACTIVITY_LOGS_LIST,
} from "../constants/activityLogs.constants";
import {
  ILoadActivityLogsListRequest,
  IObjectActivityLogsList,
} from "../types/activityLogs.type";

export interface ILoadActivityLogsListAction
  extends Action<LOAD_ACTIVITY_LOGS_LIST> {
  payload: ILoadActivityLogsListRequest;
}

export const loadActivityLogsListAction = (
  request: ILoadActivityLogsListRequest
): ILoadActivityLogsListAction => ({
  type: LOAD_ACTIVITY_LOGS_LIST,
  payload: request,
});

export interface ISetActivityLogsListAction
  extends Action<SET_ACTIVITY_LOGS_LIST> {
  payload: IObjectActivityLogsList;
}

export const setActivityLogsListAction = (
  activityLogsList: IObjectActivityLogsList
): ISetActivityLogsListAction => ({
  type: SET_ACTIVITY_LOGS_LIST,
  payload: activityLogsList,
});
