import { IStoreState } from "../types";
import { Dispatch } from "react";
import {
  ILoadUserListAction,
  setUserListAction,
  ILoadUserDetailAction,
  setUserDetailAction,
  ILoadWalletUserAction,
  setWalletUserAction,
  ILoadWithdrawalUserAction,
  setWithdrawalUserAction,
  ILoadTransactionUserAction,
  setTransactionUserAction,
  ILoadActivityLogUserAction,
  setActivityLogUserAction,
  IDeletePhoneUserAction,
  loadUserDetailAction,
  IUpdateStatusGoogleAuthenicatorAction,
} from "../actions";
import { IObjectListUser, IUserInfo } from "../types/user.type";
import { Store } from "redux";
import {
  LOAD_USER_LIST,
  LOAD_USER_DETAIL,
  LOAD_WALLET_USER,
  LOAD_WITHDRAWAL_USER,
  LOAD_TRANSACTION_USER,
  LOAD_ACTIVITY_LOG_USER,
  DELETE_PHONE_USER,
  UPDATE_STATUS_GOOGLE_AUTHENICATOR,
} from "../constants/users.constant";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { IObjectWalletUser } from "../types/wallet.type";
import { IObjectListWithdrawLoan } from "../types/withdraw.type";
import { IObjectTransactionUser } from "../types/users/transactionUser.type";
import { IObjectActivityLogsList } from "../types/activityLogs.type";
import { setListUserAdminAction } from "../actions/userAdmin.action";
import { fireNotification } from "../actions/Notification.action";
import Variant from "../types/Variant.type";

export const getUserList = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadUserListAction
    | ILoadUserDetailAction
    | ILoadWalletUserAction
    | ILoadWithdrawalUserAction
    | ILoadTransactionUserAction
    | ILoadActivityLogUserAction
    | IDeletePhoneUserAction
    | IUpdateStatusGoogleAuthenicatorAction
  >
) => async (
  action:
    | ILoadUserListAction
    | ILoadUserDetailAction
    | ILoadWalletUserAction
    | ILoadWithdrawalUserAction
    | ILoadTransactionUserAction
    | ILoadActivityLogUserAction
    | IDeletePhoneUserAction
    | IUpdateStatusGoogleAuthenicatorAction
) => {
  switch (action.type) {
    case LOAD_USER_LIST:
      Axios.get(`/users`, {
        params: action.payload,
      })
        .then((response: AxiosResponse<IObjectListUser>) => {
          if (response && response.data) {
            if (action.payload.role) {
              store.dispatch(setListUserAdminAction(response.data));
            } else {
              store.dispatch(setUserListAction(response.data));
            }
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
    case LOAD_USER_DETAIL:
      Axios.get(`/users/${action.payload}`, {
        params: {
          id: action.payload,
        },
      })
        .then((response: AxiosResponse<IUserInfo>) => {
          if (response && response.data) {
            store.dispatch(setUserDetailAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
    case LOAD_WALLET_USER:
      Axios.get(`/users/${action.payload}/account`, {
        params: {
          id: action.payload,
        },
      })
        .then((response: AxiosResponse<IObjectWalletUser>) => {
          if (response && response.data) {
            store.dispatch(setWalletUserAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
    case LOAD_WITHDRAWAL_USER:
      Axios.get(`/users/${action.payload.id}/withdrawal`, {
        params: action.payload,
      })
        .then((response: AxiosResponse<IObjectListWithdrawLoan>) => {
          if (response && response.data) {
            store.dispatch(setWithdrawalUserAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
    case LOAD_TRANSACTION_USER:
      Axios.get(`/users/${action.payload.idUser}/transaction`, {
        params: {
          id: action.payload,
          from: action.payload.from,
          to: action.payload.to,
          limit: action.payload.limit,
          offset: action.payload.offset,
          order: action.payload.order,
          asset: action.payload.asset,
          type: action.payload.type,
        },
      })
        .then((response: AxiosResponse<IObjectTransactionUser>) => {
          if (response && response.data) {
            store.dispatch(setTransactionUserAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
    case LOAD_ACTIVITY_LOG_USER:
      Axios.get(`/users/${action.payload.userId}/activity`, {
        params: {
          limit: action.payload.limit,
          offset: action.payload.offset,
          search: action.payload.search ? action.payload.search : undefined,
          category: action.payload.category
            ? action.payload.category
            : undefined,
        },
      })
        .then((response: AxiosResponse<IObjectActivityLogsList>) => {
          if (response && response.data) {
            store.dispatch(setActivityLogUserAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
    case DELETE_PHONE_USER:
      Axios.put(`/users/${action.payload.id}/phone`)
        .then(() => {
          store.dispatch(loadUserDetailAction(action.payload.id));

          store.dispatch(
            fireNotification({
              message: `Delete The Phone Number Successfully`,
              variant: Variant.SUCCESS,
            })
          );
        })
        .catch((errors: AxiosError) => {
          Promise.reject(errors);
          return errors;
        });
      break;
    case UPDATE_STATUS_GOOGLE_AUTHENICATOR:
      Axios.put(`/users/${action.payload.id}/google-authen`)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `Update Status Google Authenicator Successfully`,
              variant: Variant.SUCCESS,
            })
          );
        })
        .catch((errors: AxiosError) => {
          Promise.reject(errors);
          return errors;
        });
      break;
  }
  return dispatch(action);
};
