import { IObjectUserAccountList } from "../types/userAccount.type";
import { ISetUserAccountListAction } from "../actions/userAccount.action";
import { SET_USER_ACCOUNT_LIST } from "../constants/usersAccount.constant";

const initialUserAccount: IObjectUserAccountList = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};
export const UserAccountList = (
  state: IObjectUserAccountList = initialUserAccount,
  action: ISetUserAccountListAction
): IObjectUserAccountList => {
  switch (action.type) {
    case SET_USER_ACCOUNT_LIST:
      return action.payload;

    default:
      return state;
  }
};
