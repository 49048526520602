import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateConfigAction } from "../../actions";
import { NexoInputConfig } from "../share/NexoInputConfig";
import { NexoButton } from "../share/NexoButton";
import { formatNumberToFixed, checkMinMax } from "../../helper";
import { IConfig } from "../../types/config.type";
import { MSG_ERRORS } from "../../constants/config.constants";

const styles = makeStyles({
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#050F47",
  },
  card: {
    background: "#FFFFFF",
    border: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderRadius: 5,
  },
  item: {
    background: " #F9FAFB",
    borderBottom: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: 60,
    paddingLeft: 16,
  },
});

interface IProps {
  dataLtvLevel: IConfig[];
}

export const LtvLevelConfig = (props: IProps) => {
  const { dataLtvLevel } = props;
  const dispatch = useDispatch();
  const classes = styles();
  const [ltvLevel, setLtvLevel] = useState<IConfig[]>([]);
  const [disableButton, setDisableButton] = useState<boolean[]>([
    true,
    true,
    true,
    true,
  ]);
  const [, setRender] = useState(0);

  const [errors, setErrors] = useState<boolean[]>([false, false, false, false]);

  const checkErrors = (value: string, index: number) => {
    let newErrors = errors;
    let newDisableButton = disableButton;

    if (value === "") {
      newErrors[index] = true;
      newDisableButton[index] = true;
    } else {
      newErrors[index] = false;
      newDisableButton[index] = false;
    }

    setDisableButton(newDisableButton);
    setErrors(newErrors);
  };

  const forceUpdate = () => {
    setRender((render) => ++render); // update the state to force render
  };

  const handleChangeInput = (
    type: string | undefined,
    index: number | undefined
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (index !== undefined) {
      let newLtvLevel = ltvLevel;
      newLtvLevel[index].value = checkMinMax(event.target.value);
      setLtvLevel(newLtvLevel);
      checkErrors(event.target.value, index);
    }
    forceUpdate();
  };

  const noti = [
    "LTV High",
    "Ltv Very Hight",
    "Ltv Extremely",
    "Ltv Selling Threshold",
  ];

  const handeSave = (index: number) => () => {
    console.log(index);
    if (index !== undefined) {
      const dataUpdate = {
        id: ltvLevel[index].id,
        value: Number(ltvLevel[index].value),
        typeNoti: noti[index],
      };

      if (dataUpdate.id !== "") {
        dispatch(updateConfigAction(dataUpdate));
        setDisableButton([true, true, true, true]);
      }
    }
  };

  const typeLtvlevel = {
    high: "HIGH",
    veryHigh: "VERY_HIGH",
    extremelyHigh: "EXTREMELY_HIGH",
    selling_threshold: "SELLING_THRESHOLD",
  };

  const checkTitle = (type: string) => {
    let titleLtv = "s";
    switch (type) {
      case typeLtvlevel.high:
        titleLtv = "High";
        break;
      case typeLtvlevel.veryHigh:
        titleLtv = "Very Hight";
        break;
      case typeLtvlevel.extremelyHigh:
        titleLtv = "Extremely High";
        break;

      default:
        titleLtv = "Selling Threshold";
        break;
    }
    return titleLtv;
  };

  useEffect(() => {
    setLtvLevel(dataLtvLevel);
  }, [dataLtvLevel]);

  return (
    <Grid container className={classes.card}>
      <Grid alignItems="center" container className={classes.item}>
        <Typography style={{ fontSize: 16 }} className={classes.title}>
          Loan-to-Value Ratio(LTV)
        </Typography>
      </Grid>

      {ltvLevel.length
        ? ltvLevel.map((el, index) => {
            return (
              <Grid
                container
                style={{ marginBottom: 16, marginTop: 16 }}
                key={index}
              >
                <Grid container style={{ paddingLeft: 16, paddingBottom: 6 }}>
                  <Typography className={classes.title}>
                    {el.type ? checkTitle(el.type) : ""}
                  </Typography>
                </Grid>
                <Grid container style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Grid item xs={7}>
                    <NexoInputConfig
                      type="number"
                      value={formatNumberToFixed(ltvLevel[index].value, 2)}
                      name="LTV High"
                      handleChangeInput={handleChangeInput}
                      error={errors[index]}
                      msgError={MSG_ERRORS[index]}
                      index={index}
                      dvt="dvt"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    alignItems="center"
                    style={{ paddingLeft: 50 }}
                  >
                    <Grid style={{ height: 50, width: "100%" }}>
                      <NexoButton
                        heightButton={40}
                        title="Save"
                        variant="contained"
                        color="primary"
                        handleRedirect={handeSave(index)}
                        disabled={disableButton[index]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};
