export const SET_CONFIG_LIST = "SET_CONFIG_LIST";
export type SET_CONFIG_LIST = typeof SET_CONFIG_LIST;

export const LOAD_CONFIG_LIST = "LOAD_CONFIG_LIST";
export type LOAD_CONFIG_LIST = typeof LOAD_CONFIG_LIST;

export const UPDATE_CONFIG = "UPDATE_CONFIG";
export type UPDATE_CONFIG = typeof UPDATE_CONFIG;

export const ADD_CONFIG = "ADD_CONFIG";
export type ADD_CONFIG = typeof ADD_CONFIG;

export const DELETE_CONFIG = "DELETE_CONFIG";
export type DELETE_CONFIG = typeof DELETE_CONFIG;

export enum CONFIG_TYPE {
  LOAN_INTEREST = "Loan Interest",
  SAVING_WITHDRAW_RATE = "Saving Withdraw Rate",
  SAVING_INTEREST = "Saving Interest",
  LTV_HIGH = "LTV High",
  LTV_VERY_HIGHT = "Ltv Very Hight",
  LTV_EXTREMELY = "Ltv Extremely",
  LTV_SELLING_THRESHOLD = "Ltv Selling Threshold",
  TRANSFER_TYPE = "Transfer Type",
  TRANSFER_TYPE_ADD = "Transfer Type Add",
  PAYMENT_TYPE = "Payment Type",
  PAYMENT_TYPE_ADD = "Payment Type Add",
}

export const MSG_ERRORS = [
  "Please enter High!",
  "Please enter Very Hight!",
  "Please enter Extremely High!",
  "Please enter Selling Threshold!",
];

export const DataDefault = {
  id: "",
  name: "",
  type: "",
  rate: "",
  value: "",
  createdAt: "",
  updatedAt: "",
};
