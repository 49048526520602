import React from "react";
import { TableRow, TableCell, makeStyles } from "@material-ui/core";

const styles = makeStyles({
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
});

interface IProps {
  colSpan: number;
}

export const CardNoDataTable = (props: IProps) => {
  const classes = styles();
  return (
    <TableRow>
      <TableCell
        className={classes.tableBody}
        colSpan={props.colSpan}
        style={{ textAlign: "center" }}
      >
        No Data
      </TableCell>
    </TableRow>
  );
};
