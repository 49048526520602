import { Store } from "redux";
import { IStoreState } from "../types";
import { Dispatch } from "react";
import Axios, { AxiosResponse } from "axios";
import {
  ILoadCountriesAction,
  setCountriesAction,
} from "../actions/country.action";
import { LOAD_COUNTRIES_ACTION } from "../constants/country";
import ICountry from "../types/country";

export const countriesMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ILoadCountriesAction>
) => async (action: ILoadCountriesAction) => {
  switch (action.type) {
    case LOAD_COUNTRIES_ACTION: {
      Axios.get(`/countries`)
        .then((data: AxiosResponse<ICountry[]>) => {
          if (data.data) {
            store.dispatch(setCountriesAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
  }
  return dispatch(action);
};
