import { Dispatch } from "react";
import {
  ILoadDashboardDataAction,
  ISetDashboardDataAction,
  setDashboardData,
} from "../actions/dashboard.action";
import { LOAD_DASHBOARD_DATA } from "../constants/dashboard.constants";
import { Store } from "redux";
import { IStoreState } from "../types";
import { Method, AxiosResponse, AxiosError } from "axios";
import { callApi } from "../helper/callApi";
import { IObjectDashboard } from "../types/dashboard.type";
import { DASHBOARD_URL } from "../helper/environment";

export const getDashboardData = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ISetDashboardDataAction | ILoadDashboardDataAction>
) => async (action: ILoadDashboardDataAction) => {
  switch (action.type) {
    case LOAD_DASHBOARD_DATA:
      const url = DASHBOARD_URL;
      const method: Method = "get";
      const payload = { method, url };

      callApi(payload)
        .then((response: AxiosResponse<IObjectDashboard>) => {
          if (response) {
            dispatch(setDashboardData(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;

    default:
      break;
  }
  return dispatch(action);
};
