import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { History } from "history";
//import { IStoreState } from "./types";
import { Routes } from "./Routes";
import NotificationComponent from "./components/Notification.component";
import AOS from "aos";
import "aos/dist/aos.css";

const mapStateToProps = ({ history }: RouteComponentProps) => {
  return { history };
};
interface IProps {
  history: History;
}

class App extends React.Component<IProps, {}> {
  public componentDidMount = () => {
    AOS.init({
      duration: 2000,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Routes />
        <NotificationComponent />
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, undefined)(App));
