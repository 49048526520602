import React, { useLayoutEffect, useEffect, useState } from "react";
import { drawChart, drawVisualization } from "../helper/loadder";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { TopDepositUser } from "../components/users/TopDepositUser";
import iconTotalUser from "../assets/images/iconTotalUser.svg";
import iconTotalUserApprove from "../assets/images/iconTotalUserApprove.svg";
import { loadDashboardData } from "../actions/dashboard.action";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../types";
import { NexoAutocomplete } from "../components/share/NexoAutocomplete";
import {
  DataType,
  itemsDeposit,
  itemsRepayment,
} from "../constants/dashboard.constants";
import { ISelectItemInfo } from "../types/dashboard.type";
import { formatNumberToFixed } from "../helper";
import { ProfileCard } from "../components/ProfileCard";

const styles = makeStyles({
  noteIcon: {
    height: 10,
    width: 10,
    borderRadius: "50%",
    marginRight: 5,
  },
  noteValue: {
    fontSize: 13,
    fontFamily: "Montserrat",
    color: "#A6ACBE",
    fontWeight: "normal",
  },
  title: {
    fontSize: 14,
    // width: "100%",
    lineHeight: 3,
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  autocomplete: {
    height: 50,
    marginLeft: 20,
    width: 180,
  },
});

export const Dashboards = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state: IStoreState) => state.dashboard);
  const [dataNoteLeft, setDataNoteLeft] = useState(itemsDeposit);
  const [dataNoteRight, setDataNoteRight] = useState<any[]>([]);
  const [type, setType] = useState({
    name: "Deposit",
    value: 0,
  });
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["Dashboard"];

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };
  const classes = styles();

  const handleChangeType = (type: string) => (
    event: any,
    value: ISelectItemInfo | null
  ) => {
    if (value?.title === "Repayment") {
    }
    switch (value?.title) {
      case "Repayment":
        setType({
          name: "Repayment",
          value: parseInt(dashboard.repayment_value, 10),
        });
        setDataNoteLeft(itemsRepayment);
        setDataNoteRight([
          { value: dashboard.repayment_btc, dvt: "BTC" },
          { value: dashboard.repayment_eth, dvt: "ETH" },
          { value: dashboard.repayment_xrp, dvt: "XRP" },
          { value: dashboard.repayment_usdt, dvt: "USDT" },
        ]);
        break;

      default:
        setType({
          name: "Deposit",
          value: parseInt(dashboard.deposit_value, 10),
        });
        setDataNoteLeft(itemsDeposit);
        setDataNoteRight([
          { value: dashboard.deposit_btc, dvt: "BTC" },
          { value: dashboard.deposit_eth, dvt: "ETH" },
          { value: dashboard.deposit_xrp, dvt: "XRP" },
        ]);
        break;
    }
    drawChart(dashboard, value?.title);
  };
  useLayoutEffect(() => {
    drawChart(dashboard, "Deposit");
    drawVisualization(dashboard);
  }, [dashboard]);

  useEffect(() => {
    dispatch(loadDashboardData());
  }, [dispatch]);

  useEffect(() => {
    setDataNoteRight([
      { value: dashboard.deposit_btc, dvt: "BTC" },
      { value: dashboard.deposit_eth, dvt: "ETH" },
      { value: dashboard.deposit_xrp, dvt: "XRP" },
    ]);
    setType({ name: "Deposit", value: parseInt(dashboard.deposit_value, 10) });
  }, [dashboard]);

  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <Grid container>
        <Grid
          container
          style={{
            display: "grid",
            gridTemplateColumns: "431px calc(100% - 431px)",
          }}
        >
          <Grid
            style={{
              width: 395,
              height: 569,
              borderRadius: 10,
              backgroundColor: "#ffffff",
            }}
          >
            <Grid container style={{ padding: 20 }}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{
                  borderBottom: "1px solid #EEF0F7",
                  paddingBottom: 10,
                  marginBottom: 10,
                }}
              >
                <Typography className={classes.title}>Total Coin</Typography>
                <Grid className={classes.autocomplete}>
                  <NexoAutocomplete
                    content=""
                    placeholderInput="Deposit"
                    dataSelect={DataType}
                    onChangeType={handleChangeType("repayment")}
                  />
                </Grid>
              </Grid>
              <Typography
                className={classes.noteValue}
                style={{ marginRight: 10 }}
              >
                {type.name}:{" "}
              </Typography>
              <Typography
                className={classes.noteValue}
                style={{
                  color: "#000000",
                  fontWeight: 600,
                  marginRight: 2,
                }}
              >
                {formatNumberToFixed(type.value ? type.value : 0, 2)}
              </Typography>
              <Typography className={classes.noteValue}>(USD)</Typography>
              <Grid item xs id="chart_div"></Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  {dataNoteLeft.map((value) => {
                    return (
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        style={{ height: 35 }}
                        key={value.name}
                      >
                        <Typography
                          className={classes.noteIcon}
                          style={{ background: value.background }}
                        />
                        <Typography className={classes.noteValue}>
                          {value.name}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item>
                  {dataNoteRight.length > 0
                    ? dataNoteRight.map((el, index) => {
                        return (
                          <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            style={{ height: 35 }}
                            key={index}
                          >
                            <Typography
                              className={classes.noteValue}
                              style={{
                                color: "#000000",
                                fontWeight: 600,
                                marginRight: 2,
                              }}
                            >
                              {formatNumberToFixed(el.value ? el.value : 0, 8)}
                            </Typography>
                            <Typography className={classes.noteValue}>
                              {el.dvt}
                            </Typography>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "grid",
              gridTemplateRows: "115px calc(100% - 115px)",
            }}
          >
            <Grid
              container
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "49%  49%",
                columnGap: "2%",
              }}
            >
              <Grid
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 10,
                  height: 100,
                }}
              >
                <Grid
                  container
                  direction="row"
                  style={{ padding: "15px 25px" }}
                >
                  <Grid item xs={4}>
                    <img src={iconTotalUser} alt="" />
                  </Grid>

                  <Grid item xs>
                    <Typography
                      style={{
                        fontSize: 24,
                        color: "#000000",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {dashboard.userTotal || ""}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        color: "#A6ACBE",
                        fontFamily: "Montserrat",
                      }}
                    >
                      All Users
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 10,
                  height: 100,
                }}
              >
                <Grid
                  container
                  direction="row"
                  style={{ padding: "15px 25px" }}
                >
                  <Grid item xs={4}>
                    <img src={iconTotalUserApprove} alt="" />
                  </Grid>

                  <Grid item xs>
                    <Typography
                      style={{
                        fontSize: 24,
                        color: "#000000",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {dashboard.pendingAdvancedVerificationTotal || ""}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        color: "#A6ACBE",
                        fontFamily: "Montserrat",
                      }}
                    >
                      All Users Pending Advanced
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <TopDepositUser topDeposit={dashboard.topDeposit} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            style={{
              width: "100%",
              marginTop: 28,
              backgroundColor: "#ffffff",
              borderRadius: 10,
              padding: 20,
            }}
          >
            <Typography className={classes.title}>
              Transaction statistics by month
            </Typography>
            <Grid id="chart_column"></Grid>
          </Grid>
        </Grid>
      </Grid>
    </ProfileCard>
  );
};
