import React, { useState, ChangeEvent } from "react";
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import { NexoInput } from "../components/share/NexoInput";
import { validateEmail } from "../helper";
import logoHeader from "../assets/images/logoHeader.svg";
import { NexoButton } from "../components/share/NexoButton";
import { ILoginRequest } from "../types/auth.type";
import { useDispatch } from "react-redux";
import { loginAction } from "../actions/auth.action";
const styles = makeStyles({
  rootPager: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: 15,
    width: 450,
  },
});

export const Login = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const [inputEmail, setInputEmail] = useState({
    email: "",
    errorEmail: false,
  });
  const [inputPassword, setInputPassword] = useState({
    password: "",
    errorPassword: false,
  });

  const handleChangeInputEmail = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const valueEmail = event.target.value;
    let error: boolean = false;

    if (valueEmail.match(validateEmail) || valueEmail.substring(0, 1) === "") {
      error = false;
    } else {
      error = true;
    }
    setInputEmail({
      email: valueEmail,
      errorEmail: error,
    });
  };

  const handleChangeInputPassword = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const valuePassword = event.target.value;
    let error: boolean = false;
    if (valuePassword.length > 5 || valuePassword.substring(0, 1) === "") {
      error = false;
    } else {
      error = true;
    }
    setInputPassword({
      password: valuePassword,
      errorPassword: error,
    });
  };
  const onClickLogin = () => {
    const request: ILoginRequest = {
      email: inputEmail.email,
      password: inputPassword.password,
    };
    dispatch(loginAction(request));
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClickLogin();
    }
  };

  return (
    <Grid
      container
      justify="center"
      direction="row"
      alignItems="center"
      style={{ height: "100%" }}
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <Grid>
        <Grid container style={{ marginBottom: 30 }} justify="center">
          <img src={logoHeader} alt="logo" height={50} />
        </Grid>
        <Paper className={classes.rootPager}>
          <Grid
            style={{
              padding: 35,
            }}
          >
            <Typography variant="h2">Login</Typography>
            <Grid container direction="row" style={{ marginTop: 20 }}>
              <Typography variant="h4">Welcome to Crypto !</Typography>
            </Grid>
            <Grid>
              <Typography variant="h4">Please login to start using.</Typography>
            </Grid>
            <Grid style={{ marginTop: 20 }}>
              <NexoInput
                error={inputEmail.errorEmail}
                msgError="Incorrect email format"
                title="Email"
                type="text"
                value={inputEmail.email}
                handleChangeInput={handleChangeInputEmail}
                onKeyPress={onKeyPress}
              />
              <NexoInput
                error={inputPassword.errorPassword}
                msgError="Password should be at least 6 characters"
                title="Password"
                type="password"
                value={inputPassword.password}
                handleChangeInput={handleChangeInputPassword}
                onKeyPress={onKeyPress}
              />

              <Grid container justify="center" style={{ marginTop: 50 }}>
                <NexoButton
                  title="Login"
                  variant="contained"
                  color="primary"
                  handleRedirect={onClickLogin}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
