import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  TableRow,
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { ILoadUserListRequest, IUserInfo } from "../types/user.type";
import _ from "lodash";
import { RootTable } from "../components/RootTable";
import Search from "@material-ui/icons/Search";
import { IStoreState } from "../types";
import { loadUserList } from "../actions";
import { OrderByTableHeader } from "../components/share/OrderByTableHeader";
import { formatDateTime } from "../helper";
import { CardNoDataTable } from "../components/share/CardNoDataTable";
import Pagination from "../components/share/Pagination";
import IconAdd from "../assets/images/add.svg";
import { AddAdminDialog } from "../components/dialog/AddAdmin.dialog";
import iconDelete from "../assets/images/delete.svg";
import iconUpdate from "../assets/images/edit.svg";
import { INFO_ADMIN } from "../constants/usersAdmin.constant";
import { ConfirmDialog } from "../components/dialog/Confirm.dialog";
import { deleteUserAdminAction } from "../actions/userAdmin.action";
import { EditAdminDialog } from "../components/dialog/EditAdmin.dialog";
import { IUserAdminInfoRequest } from "../types/userAdmin.type";

const styles = makeStyles({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  paddingTableHead: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  rootInputSeach: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "#9FA2B4",
    },
    "& .MuiInputBase-input": {
      color: "#9FA2B4",
      fontSize: 15,
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "20px",
      fontStyle: "italic",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.09) !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.09) !important",
    },
  },
});

export const AdminTable = () => {
  const classes = styles();
  const [meta, setMeta] = useState<ILoadUserListRequest>({
    limit: 10,
    offset: 0,
    order: {},
    role: "Admin",
  });

  const [showDialogAddAdmin, setShowDialogAddAdmin] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [showDialogEditAdminDialog, setShowDialogEditAdminDialog] = useState<
    boolean
  >(false);
  const [userAdminInfo, setUserAdminInfo] = useState<IUserAdminInfoRequest>({
    id: "",
    address: "",
    city: "",
    countryId: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    postCode: "",
  });

  const [userAdminId, setUserAdminId] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const UserList = useSelector((state: IStoreState) => state.userAdminList);
  const userProfileEmail: string = useSelector(
    (state: IStoreState) => state.userProfile.email
  );
  useEffect(() => {
    dispatch(loadUserList(meta));
  }, [dispatch, meta]);

  const handleChangePage = (event: any, page: number) => {
    setMeta({
      ...meta,
      limit: rowsPerPage,
      offset: page * UserList.meta.limit,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMeta({
      ...meta,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    });
  };

  const handleChangeArrow = (
    typeTable: string,
    orderBy: string | undefined
  ) => {
    let newOrder = meta.order;
    if (newOrder) {
      newOrder[typeTable] = orderBy;
    }
    setMeta({
      ...meta,
      order: newOrder,
    });
  };

  const debounceSearchAction = _.debounce((search) => {
    setMeta({
      ...meta,
      search,
      offset: 0,
    });
  }, 500);

  const handleUserAdminDelete = (id: string) => () => {
    if (id) {
      setUserAdminId(id);
      setOpenConfirmDialog(true);
    }
  };

  const handleSubmitUserAdminDelete = () => {
    dispatch(
      deleteUserAdminAction({
        id: userAdminId,
      })
    );
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleChaneInputSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    debounceSearchAction(event.target.value);
  const handleCloseDialog = () => {
    setShowDialogAddAdmin(false);
    setShowDialogEditAdminDialog(false);
  };
  const handleShowDialogAddUserAdmin = () => {
    setShowDialogAddAdmin(true);
  };

  const handleEditAdmin = (data: IUserInfo) => () => {
    setUserAdminInfo({
      id: data.id,
      address: data.address,
      city: data.city,
      countryId: data.countryId,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: "",
      phone: data.phone,
      postCode: data.postCode,
    });
    setShowDialogEditAdminDialog(true);
  };
  return (
    <RootTable
      nameTable="All Admins"
      ActionsComponent={
        <Grid container justify="flex-end" alignItems="center">
          <Grid
            style={{ width: 300, marginRight: 10 }}
            className={classes.rootInputSeach}
          >
            <TextField
              placeholder="Search here..."
              onChange={handleChaneInputSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Tooltip title="Create" aria-label="add">
            <IconButton
              style={{ padding: 7 }}
              onClick={handleShowDialogAddUserAdmin}
            >
              <img src={IconAdd} alt="Add" />
            </IconButton>
          </Tooltip>
        </Grid>
      }
    >
      <Table>
        <TableHead>
          <TableRow style={{ width: "100%" }}>
            <TableCell
              style={{
                width: "1%",
              }}
              className={classes.tableHead}
            >
              #
            </TableCell>
            <TableCell
              style={{
                width: "14%",
              }}
              className={classes.paddingTableHead}
            >
              <OrderByTableHeader
                title="Name"
                type="firstName"
                handleChangeArrow={handleChangeArrow}
              />
            </TableCell>

            <TableCell
              className={classes.paddingTableHead}
              style={{
                width: "13%",
              }}
            >
              <OrderByTableHeader
                title="Email"
                type="email"
                handleChangeArrow={handleChangeArrow}
              />
            </TableCell>
            <TableCell
              style={{
                width: "14%",
              }}
              className={classes.paddingTableHead}
            >
              <OrderByTableHeader
                title="Phone"
                type="phone"
                handleChangeArrow={handleChangeArrow}
              />
            </TableCell>
            <TableCell
              style={{
                width: "14%",
              }}
              className={classes.tableHead}
            >
              Country
            </TableCell>
            <TableCell
              style={{
                width: "14%",
              }}
              className={classes.paddingTableHead}
            >
              <OrderByTableHeader
                title="City"
                type="city"
                handleChangeArrow={handleChangeArrow}
              />
            </TableCell>
            <TableCell
              style={{
                width: "17%",
              }}
              className={classes.paddingTableHead}
            >
              <OrderByTableHeader
                title="Date/time"
                type="createdAt"
                handleChangeArrow={handleChangeArrow}
              />
            </TableCell>
            <TableCell
              style={{
                width: "13%",
                paddingRight: 16,
              }}
              align={"right"}
              className={classes.tableHead}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {UserList.data.length ? (
            UserList.data.map((el, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow className={classes.tableCell}>
                    <TableCell className={classes.tableBody}>
                      {index +
                        1 +
                        UserList.meta.limit * (UserList.meta.currentPage - 1)}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {el.firstName ? el.firstName + " " : "-"}
                      {el.lastName ? el.lastName : ""}
                    </TableCell>

                    <TableCell className={classes.tableBody}>
                      {el.email ? el.email : "-"}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {el.phone ? el.phone : "-"}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {el.country ? el.country.name : "-"}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {el.city ? el.city : "-"}
                    </TableCell>
                    <TableCell
                      className={classes.tableBody}
                      style={{
                        paddingRight: 16,
                      }}
                    >
                      {el.createdAt ? formatDateTime(el.createdAt) : "-"}
                    </TableCell>
                    <TableCell
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 16,
                      }}
                      className={classes.tableBody}
                      align={"right"}
                    >
                      {el.email !== INFO_ADMIN.EMAIL ? (
                        <React.Fragment>
                          {el.email !== userProfileEmail ? (
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={handleUserAdminDelete(el.id)}
                                style={{
                                  width: 30,
                                  height: 30,
                                  padding: 0,
                                }}
                              >
                                <img
                                  src={iconDelete}
                                  alt="icon"
                                  width={15}
                                  height={15}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : null}

                          <Tooltip title="Edit">
                            <IconButton
                              onClick={handleEditAdmin(el)}
                              style={{
                                width: 30,
                                height: 30,
                                padding: 0,
                              }}
                            >
                              <img
                                src={iconUpdate}
                                alt="icon"
                                width={15}
                                height={15}
                              />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      ) : null}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          ) : (
            <CardNoDataTable colSpan={8} />
          )}
        </TableBody>
      </Table>
      <Grid>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={UserList.meta.total ? UserList.meta.total : 0}
          rowsPerPage={rowsPerPage}
          page={UserList.meta.currentPage ? UserList.meta.currentPage - 1 : 0}
          onChangePage={handleChangePage}
          ActionsComponent={Pagination}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      <AddAdminDialog
        handleClose={handleCloseDialog}
        showDialog={showDialogAddAdmin}
      />
      <EditAdminDialog
        handleClose={handleCloseDialog}
        showDialog={showDialogEditAdminDialog}
        dataItemUserAdmin={userAdminInfo}
      />
      <ConfirmDialog
        showDialog={openConfirmDialog}
        handleClose={setOpenConfirmDialog}
        message="Please confirm you want to delete?"
        type="USER ADMIN"
        handleSubmit={handleSubmitUserAdminDelete}
      />
    </RootTable>
  );
};
