import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateConfigAction } from "../../actions";
import { NexoInputConfig } from "../share/NexoInputConfig";
import { NexoButton } from "../share/NexoButton";
import { formatNumberToFixed, checkMinMax } from "../../helper";
import { IConfig } from "../../types/config.type";
import { DataDefault, CONFIG_TYPE } from "../../constants/config.constants";

const styles = makeStyles({
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#050F47",
  },
  card: {
    background: "#FFFFFF",
    border: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderRadius: 5,
  },
  item: {
    background: " #F9FAFB",
    borderBottom: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: 60,
    paddingLeft: 16,
  },
});

interface IProps {
  dataSavingInterest: IConfig;
}

export const SavingInterestConfig = (props: IProps) => {
  const { dataSavingInterest } = props;
  const dispatch = useDispatch();
  const classes = styles();
  const [savingInterrest, setSavingInterrest] = useState<IConfig>(DataDefault);
  const [disableButton, setDisableButton] = useState(true);
  const [errors, setErrors] = useState<boolean>(false);

  const checkErrors = (value: string) => {
    let errorInput = false;
    if (value === "") {
      errorInput = true;
    } else {
      errorInput = false;
    }
    setErrors(errorInput);
  };

  const handleChangeInput = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value !== "") {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    checkErrors(event.target.value);
    setSavingInterrest({
      ...savingInterrest,
      value: checkMinMax(event.target.value),
    });
  };

  const handeSave = () => {
    let dataUpdate = {
      id: savingInterrest.id,
      value: Number(savingInterrest.value),
      typeNoti: CONFIG_TYPE.SAVING_INTEREST,
    };
    if (dataUpdate.id !== "") {
      dispatch(updateConfigAction(dataUpdate));
      setDisableButton(true);
    }
  };

  useEffect(() => {
    setSavingInterrest(dataSavingInterest);
  }, [dataSavingInterest]);

  return (
    <Grid container className={classes.card}>
      <Grid alignItems="center" container className={classes.item}>
        <Typography style={{ fontSize: 16 }} className={classes.title}>
          Saving Interest
        </Typography>
      </Grid>

      <Grid container style={{ padding: 16 }}>
        <Grid item xs={7}>
          <NexoInputConfig
            type="number"
            value={formatNumberToFixed(savingInterrest.value, 2)}
            name="Saving Interest"
            handleChangeInput={handleChangeInput}
            error={errors}
            msgError="Please enter saving interest!"
            dvt="dvt"
          />
        </Grid>
        <Grid
          container
          item
          xs={5}
          alignItems="center"
          style={{ paddingLeft: 50 }}
        >
          <Grid style={{ height: 50, width: "100%" }}>
            <NexoButton
              heightButton={40}
              title="Save"
              variant="contained"
              color="primary"
              handleRedirect={handeSave}
              disabled={disableButton}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
