import React from "react";
import { makeStyles, Theme, Grid } from "@material-ui/core";
import { Route, Switch } from "react-router";
import { PATH } from "../constants/PATH";
import { User } from "../pages/User";
import { MenuLeft } from "./layout/MenuLeft";
import { Transactions } from "../pages/Transactions";
import { UserDetail } from "../pages/UserDetail";
import { Withdraws } from "../pages/Withdraws";
import { Currencies } from "../pages/Currencies";
import { Settings } from "../pages/Settings";
import { UserAccount } from "../pages/UserAccount";
import { Dashboards } from "../pages/Dashboards";
import { ActivityLogs } from "../pages/ActivityLogs";
import { store } from "..";
import { logoutAction } from "../actions/auth.action";

const styles = makeStyles((theme: Theme) => ({
  rootPage: {
    minHeight: "100vh",
    display: "grid",
    gridTemplateColumns: "255px calc(100% - 255px)",
    background: "#30324f",
  },
  rootChildren: {
    padding: "30px 0px 30px 30px",
    backgroundColor: "#f3f5f9",
  },
}));

export const Template = () => {
  const role = localStorage.getItem("role");
  if (role !== "Admin") {
    store.dispatch(logoutAction());
  }

  const classes = styles();
  return (
    <div style={{ minHeight: "100%" }}>
      <Grid className={classes.rootPage}>
        <MenuLeft />
        <Grid className={classes.rootChildren}>
          <Switch>
            <Route path={PATH.users} exact={true} component={User} />
            <Route path={PATH.user} exact={true} component={UserDetail} />
            <Route
              path={PATH.userAccount}
              exact={true}
              component={UserAccount}
            />
            <Route
              path={PATH.transactions}
              exact={true}
              component={Transactions}
            />

            <Route path={PATH.withdraws} exact={true} component={Withdraws} />
            <Route path={PATH.currencies} exact component={Currencies} />
            <Route path={PATH.settings} exact={true} component={Settings} />
            <Route
              path={PATH.activityLogs}
              exact={true}
              component={ActivityLogs}
            />
            <Route path={PATH.dashboards} exact={true} component={Dashboards} />
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};
