import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NexoInput } from "../share/NexoInput";
import { NexoSelectCountry } from "../share/NexoSelectCountry.component";
import ICountry from "../../types/country";
import { NexoPhoneInput } from "../share/NexoInputPhone.component";
import { NexoButton } from "../share/NexoButton";
import { validateEmail } from "../../helper";
import { useDispatch } from "react-redux";
import { IUserAdminInfoRequest } from "../../types/userAdmin.type";
import { editUserAdminAction } from "../../actions/userAdmin.action";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "10px",
      width: 650,
      maxWidth: 650,
      height: "auto",
      overflow: "inherit",
    },
  },

  buttonVerify: {
    width: 150,
  },
  buttonCancel: {
    width: 150,
    marginRight: 7,
  },
}));

interface IProps {
  showDialog: boolean;
  handleClose: (showDialog: boolean) => void;
  dataItemUserAdmin: IUserAdminInfoRequest;
}

export const EditAdminDialog = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState<ICountry[]>([]);
  const [userAdminInfo, setUserAdminInfo] = useState<IUserAdminInfoRequest>({
    id: "",
    address: "",
    city: "",
    countryId: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    postCode: "",
  });

  useEffect(() => {
    setUserAdminInfo(props.dataItemUserAdmin);
  }, [props.dataItemUserAdmin]);

  const [errorInput, setErrorInput] = useState({
    email: false,
    password: false,
  });

  const { showDialog, handleClose } = props;

  const handleOnChangeCountry = (selectedCountry: ICountry[]) => {
    setSelectedCountry(selectedCountry);
  };

  const handleChangeInputVerifyPhone = (phone: string) => {
    setUserAdminInfo({
      ...userAdminInfo,
      phone,
    });
  };
  const handleTextChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (key === "email") {
      const valueEmail = event.target.value;
      let error: boolean = false;

      if (
        valueEmail.match(validateEmail) ||
        valueEmail.substring(0, 1) === ""
      ) {
        error = false;
      } else {
        error = true;
      }
      setErrorInput({
        ...errorInput,
        email: error,
      });
    }
    if (key === "password") {
      const valuePassword = event.target.value;
      let error: boolean = false;
      if (valuePassword.length > 5 || valuePassword.substring(0, 1) === "") {
        error = false;
      } else {
        error = true;
      }
      setErrorInput({
        ...errorInput,
        password: error,
      });
    }
    setUserAdminInfo({
      ...userAdminInfo,
      [key]: event.target.value,
    });
  };

  const handleUpdateUserAdmin = () => {
    const requestNoUpdatePassword: IUserAdminInfoRequest = {
      id: userAdminInfo.id,
      address: userAdminInfo.address,
      city: userAdminInfo.city,
      countryId: selectedCountry[0]?.id || props.dataItemUserAdmin.countryId,
      firstName: userAdminInfo.firstName,
      lastName: userAdminInfo.lastName,
      phone: userAdminInfo.phone,
      password: userAdminInfo.password,
      postCode: userAdminInfo.postCode,
    };

    setTimeout(() => {
      dispatch(editUserAdminAction(requestNoUpdatePassword));

      handleCloseDialog();
      setUserAdminInfo({
        id: "",
        address: "",
        city: "",
        countryId: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        postCode: "",
      });
    }, 500);
  };

  const handleCloseDialog = () => {
    props.handleClose(!showDialog);
  };

  const disableButtonUpdate: boolean =
    userAdminInfo.phone === "" ||
    userAdminInfo.address === "" ||
    userAdminInfo.city === "" ||
    userAdminInfo.firstName === "" ||
    userAdminInfo.lastName === "" ||
    userAdminInfo.postCode === "" ||
    errorInput.email === true ||
    errorInput.password === true ||
    userAdminInfo.countryId === "";
  return (
    <Dialog open={showDialog} onClose={handleClose} className={classes.dialog}>
      <Grid
        container
        style={{
          background: "#F9FAFB",
          borderBottom: "1px solid #D7DCE0",
          height: 60,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
        alignItems="center"
        justify="center"
      >
        <Typography variant="h4" style={{ fontSize: 20, fontWeight: 600 }}>
          Update Admin
        </Typography>
      </Grid>
      <Grid container style={{ padding: 30 }}>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <NexoInput
              title="First Name"
              type="text"
              value={userAdminInfo.firstName}
              handleChangeInput={handleTextChange}
              name="firstName"
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <NexoInput
              title="Last Name"
              type="text"
              value={userAdminInfo.lastName}
              handleChangeInput={handleTextChange}
              name="lastName"
            />
          </Grid>
        </Grid>
        <NexoInput
          title="Email"
          type="text"
          error={errorInput.email}
          msgError={errorInput.email === true ? "Incorrect email format" : ""}
          value={userAdminInfo.email}
          handleChangeInput={handleTextChange}
          disabled={true}
          name="email"
        />
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <NexoInput
              title="Postal Code"
              type="text"
              // error={userAdminInfo.city === "" ? true : false}
              // msgError={userAdminInfo.city === "" ? "Field is required" : ""}
              value={userAdminInfo.postCode}
              handleChangeInput={handleTextChange}
              name="postCode"
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <NexoInput
              title="Password"
              type="text"
              error={errorInput.password}
              msgError={
                errorInput.password === true
                  ? "Password should be at least 6 characters"
                  : ""
              }
              value={userAdminInfo.password}
              handleChangeInput={handleTextChange}
              name="password"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <NexoInput
              title="City"
              type="text"
              value={userAdminInfo.city}
              handleChangeInput={handleTextChange}
              name="city"
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <NexoSelectCountry
              hiddenText={true}
              handleOnChangeCountry={handleOnChangeCountry}
              title="Country"
              placeholder=""
              selectedCountry={selectedCountry}
              countryId={userAdminInfo.countryId}
            />
          </Grid>
        </Grid>
        <NexoInput
          title="Address"
          type="text"
          // error={userAdminInfo.address === "" ? true : false}
          // msgError={userAdminInfo.address === "" ? "Field is required" : ""}
          value={userAdminInfo.address}
          handleChangeInput={handleTextChange}
          name="address"
        />
        <NexoPhoneInput
          handleChangeInputVerifyPhone={handleChangeInputVerifyPhone}
          title="Phone"
          value={userAdminInfo.phone}
        />
        <Grid container justify="center">
          <Grid className={classes.buttonCancel}>
            <NexoButton
              title="Cancel"
              variant="outlined"
              color="primary"
              heightButton={40}
              handleRedirect={handleCloseDialog}
            />
          </Grid>

          <Grid className={classes.buttonVerify}>
            <NexoButton
              title="Update"
              variant="contained"
              color="primary"
              heightButton={40}
              disabled={disableButtonUpdate}
              handleRedirect={handleUpdateUserAdmin}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
