export const LOAD_WITHDRAW_LOAN_ACTION = "LOAD_WITHDRAW_LOAN_ACTION";
export type LOAD_WITHDRAW_LOAN_ACTION = typeof LOAD_WITHDRAW_LOAN_ACTION;

export const SET_WITHDRAW_LOAN_ACTION = "SET_WITHDRAW_LOAN_ACTION";
export type SET_WITHDRAW_LOAN_ACTION = typeof SET_WITHDRAW_LOAN_ACTION;

export const LOAD_WITHDRAW_ASSET_ACTION = "LOAD_WITHDRAW_ASSET_ACTION";
export type LOAD_WITHDRAW_ASSET_ACTION = typeof LOAD_WITHDRAW_ASSET_ACTION;

export const SET_WITHDRAW_ASSET_ACTION = "SET_WITHDRAW_ASSET_ACTION";
export type SET_WITHDRAW_ASSET_ACTION = typeof SET_WITHDRAW_ASSET_ACTION;

export const APPROVE_WITHDRAW_ACTION = "APPROVE_WITHDRAW_ACTION";
export type APPROVE_WITHDRAW_ACTION = typeof APPROVE_WITHDRAW_ACTION;

export const DENIED_WITHDRAW_ACTION = "DENIED_WITHDRAW_ACTION";
export type DENIED_WITHDRAW_ACTION = typeof DENIED_WITHDRAW_ACTION;

export const SET_WITHDRAW_SAVING_ACTION = "SET_WITHDRAW_SAVING_ACTION";
export type SET_WITHDRAW_SAVING_ACTION = typeof SET_WITHDRAW_SAVING_ACTION;

export const LOAD_WITHDRAW_SAVING_ACTION = "LOAD_WITHDRAW_SAVING_ACTION";
export type LOAD_WITHDRAW_SAVING_ACTION = typeof LOAD_WITHDRAW_SAVING_ACTION;

export const WITHDRAWAL_LOAN_MENU = {
  BANK_TRANSFER: 1,
  TETHER: 2,
};
