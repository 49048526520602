import React from "react";
import {
  makeStyles,
  BottomNavigation,
  Grid,
  BottomNavigationAction,
} from "@material-ui/core";
const styles = makeStyles({
  menuHeader: {
    width: "100%",
    "& .MuiButtonBase-root.MuiBottomNavigationAction-root": {
      backgroundColor: "transparent",
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: 160,
      "& .Mui-selected": {
        "&:after": {
          backgroundColor: "transparent",
        },
      },
    },
    "& .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected": {
      background: "#30324f",
      minWidth: 160,
      paddingTop: 0,
      paddingBottom: 0,
      "& span": {
        color: "#FFFFFF",
        fontSize: 14,
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "18px",
      },
    },
    "& .MuiBottomNavigation-root": {
      height: 50,
      border: "1px solid #D7DCE0",
      boxSizing: "border-box",
      padding: 5,
      borderRadius: 5,
      backgroundColor: "transparent",
    },
    "& .MuiBottomNavigationAction-label": {
      transition: "inherit",
      color: "#A4A6B3",
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "18px",
    },
  },
});

interface IProps {
  handleChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: number;
  dataNavigation: string[];
}

export const Navigation = (props: IProps) => {
  const classes = styles();
  const { handleChange, value, dataNavigation } = props;
  return (
    <Grid container className={classes.menuHeader}>
      <BottomNavigation showLabels value={value} onChange={handleChange}>
        {dataNavigation.map((el, index) => (
          <BottomNavigationAction label={el} value={index} key={index} />
        ))}
      </BottomNavigation>
    </Grid>
  );
};
