import { Action } from "redux";
import {
  LOAD_USER_LIST,
  SET_USER_LIST,
  LOAD_USER_DETAIL,
  SET_USER_DETAIL,
  LOAD_WALLET_USER,
  SET_WALLET_USER,
  LOAD_WITHDRAWAL_USER,
  SET_WITHDRAWAL_USER,
  LOAD_TRANSACTION_USER,
  SET_TRANSACTION_USER,
  LOAD_ACTIVITY_LOG_USER,
  SET_ACTIVITY_LOG_USER,
  DELETE_PHONE_USER,
  UPDATE_STATUS_GOOGLE_AUTHENICATOR,
} from "../constants/users.constant";
import {
  ILoadUserListRequest,
  IObjectListUser,
  IUserInfo,
  IDeletePhoneUserRequest,
} from "../types/user.type";
import {
  IObjectWalletUser,
  ILoadUserDetailRequest,
} from "../types/wallet.type";
import { IObjectListWithdrawLoan } from "../types/withdraw.type";
import {
  ILoadTransactionUserRequest,
  IObjectTransactionUser,
} from "../types/users/transactionUser.type";
import { IObjectActivityLogsList } from "../types/activityLogs.type";
import { ILoadActivityLogsUserRequest } from "../types/users/activityLogUser.type";

export interface ILoadUserListAction extends Action<LOAD_USER_LIST> {
  payload: ILoadUserListRequest;
}

export const loadUserList = (
  request: ILoadUserListRequest
): ILoadUserListAction => ({
  type: LOAD_USER_LIST,
  payload: request,
});

export interface ISetUserListAction extends Action<SET_USER_LIST> {
  payload: IObjectListUser;
}

export const setUserListAction = (
  userlist: IObjectListUser
): ISetUserListAction => {
  return {
    type: SET_USER_LIST,
    payload: userlist,
  };
};

export interface ILoadUserDetailAction extends Action<LOAD_USER_DETAIL> {
  payload: string;
}

export const loadUserDetailAction = (
  request: string
): ILoadUserDetailAction => ({
  type: LOAD_USER_DETAIL,
  payload: request,
});

export interface ISetUserDetailAction extends Action<SET_USER_DETAIL> {
  payload: IUserInfo;
}

export const setUserDetailAction = (
  userlist: IUserInfo
): ISetUserDetailAction => {
  return {
    type: SET_USER_DETAIL,
    payload: userlist,
  };
};

export interface ILoadWalletUserAction extends Action<LOAD_WALLET_USER> {
  payload: string;
}

export const loadWalletUserAction = (
  request: string
): ILoadWalletUserAction => ({
  type: LOAD_WALLET_USER,
  payload: request,
});

export interface ISetWalletUserAction extends Action<SET_WALLET_USER> {
  payload: IObjectWalletUser;
}

export const setWalletUserAction = (
  userlist: IObjectWalletUser
): ISetWalletUserAction => {
  return {
    type: SET_WALLET_USER,
    payload: userlist,
  };
};

export interface ILoadWithdrawalUserAction
  extends Action<LOAD_WITHDRAWAL_USER> {
  payload: ILoadUserDetailRequest;
}

export const loadWithdrawalUserAction = (
  request: ILoadUserDetailRequest
): ILoadWithdrawalUserAction => ({
  type: LOAD_WITHDRAWAL_USER,
  payload: request,
});

export interface ISetwithdrawalUserAction extends Action<SET_WITHDRAWAL_USER> {
  payload: IObjectListWithdrawLoan;
}

export const setWithdrawalUserAction = (
  reuquest: IObjectListWithdrawLoan
): ISetwithdrawalUserAction => {
  return {
    type: SET_WITHDRAWAL_USER,
    payload: reuquest,
  };
};

export interface ILoadTransactionUserAction
  extends Action<LOAD_TRANSACTION_USER> {
  payload: ILoadTransactionUserRequest;
}

export const loadTransactionUserAction = (
  request: ILoadTransactionUserRequest
): ILoadTransactionUserAction => {
  return {
    type: LOAD_TRANSACTION_USER,
    payload: request,
  };
};

export interface ISetTransactionUserAction
  extends Action<SET_TRANSACTION_USER> {
  payload: IObjectTransactionUser;
}

export const setTransactionUserAction = (
  userlist: IObjectTransactionUser
): ISetTransactionUserAction => {
  return {
    type: SET_TRANSACTION_USER,
    payload: userlist,
  };
};

export interface ILoadActivityLogUserAction
  extends Action<LOAD_ACTIVITY_LOG_USER> {
  payload: ILoadActivityLogsUserRequest;
}

export const loadActivityLogUserAction = (
  request: ILoadActivityLogsUserRequest
): ILoadActivityLogUserAction => {
  return {
    type: LOAD_ACTIVITY_LOG_USER,
    payload: request,
  };
};

export interface ISetActivityLogUserAction
  extends Action<SET_ACTIVITY_LOG_USER> {
  payload: IObjectActivityLogsList;
}

export const setActivityLogUserAction = (
  request: IObjectActivityLogsList
): ISetActivityLogUserAction => {
  return {
    type: SET_ACTIVITY_LOG_USER,
    payload: request,
  };
};

export interface IDeletePhoneUserAction extends Action<DELETE_PHONE_USER> {
  payload: IDeletePhoneUserRequest;
}

export const deletePhoneUserAction = (
  request: IDeletePhoneUserRequest
): IDeletePhoneUserAction => {
  return {
    type: DELETE_PHONE_USER,
    payload: request,
  };
};

export interface IUpdateStatusGoogleAuthenicatorAction
  extends Action<UPDATE_STATUS_GOOGLE_AUTHENICATOR> {
  payload: { id: string };
}

export const updateStatusGoogleAuthenicatorAction = (request: {
  id: string;
}): IUpdateStatusGoogleAuthenicatorAction => {
  return {
    type: UPDATE_STATUS_GOOGLE_AUTHENICATOR,
    payload: request,
  };
};
