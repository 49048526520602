export const LOAD_USER_LIST = "LOAD_USER_LIST";
export type LOAD_USER_LIST = typeof LOAD_USER_LIST;

export const SET_USER_LIST = "SET_USER_LIST";
export type SET_USER_LIST = typeof SET_USER_LIST;

export const LOAD_USER_DETAIL = "LOAD_USER_DETAIL";
export type LOAD_USER_DETAIL = typeof LOAD_USER_DETAIL;

export const SET_USER_DETAIL = "SET_USER_DETAIL";
export type SET_USER_DETAIL = typeof SET_USER_DETAIL;

export const LOAD_WALLET_USER = "LOAD_WALLET_USER";
export type LOAD_WALLET_USER = typeof LOAD_WALLET_USER;

export const SET_WALLET_USER = "SET_WALLET_USER";
export type SET_WALLET_USER = typeof SET_WALLET_USER;

export const LOAD_WITHDRAWAL_USER = "LOAD_WITHDRAWAL_USER";
export type LOAD_WITHDRAWAL_USER = typeof LOAD_WITHDRAWAL_USER;

export const SET_WITHDRAWAL_USER = "SET_WITHDRAWAL_USER";
export type SET_WITHDRAWAL_USER = typeof SET_WITHDRAWAL_USER;

export const LOAD_TRANSACTION_USER = "LOAD_TRANSACTION_USER";
export type LOAD_TRANSACTION_USER = typeof LOAD_TRANSACTION_USER;

export const SET_TRANSACTION_USER = "SET_TRANSACTION_USER";
export type SET_TRANSACTION_USER = typeof SET_TRANSACTION_USER;

export const LOAD_ACTIVITY_LOG_USER = "LOAD_ACTIVITY_LOG_USER";
export type LOAD_ACTIVITY_LOG_USER = typeof LOAD_ACTIVITY_LOG_USER;

export const SET_ACTIVITY_LOG_USER = "SET_ACTIVITY_LOG";
export type SET_ACTIVITY_LOG_USER = typeof SET_ACTIVITY_LOG_USER;

export const DELETE_PHONE_USER = "DELETE_PHONE_USER";
export type DELETE_PHONE_USER = typeof DELETE_PHONE_USER;

export const UPDATE_STATUS_GOOGLE_AUTHENICATOR =
  "UPDATE_STATUS_GOOGLE_AUTHENICATOR";
export type UPDATE_STATUS_GOOGLE_AUTHENICATOR = typeof UPDATE_STATUS_GOOGLE_AUTHENICATOR;

export const ADD_USER_ADMIN = "ADD_USER_ADMIN";
export type ADD_USER_ADMIN = typeof ADD_USER_ADMIN;

export enum ARROW_UPWARD {
  NO = "",
  UP = "ASC",
  DOWN = "DESC",
}

export enum PAGE_USER_DETAIL {
  PROFILE = 0,
  WALLET = 1,
  WITHDRAW = 2,
  TRANSACTION = 3,
  ACTIVITY_LOG = 4,
}
