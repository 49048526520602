import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  makeStyles,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { RootTable } from "../RootTable";
import { CardNoDataTable } from "../share/CardNoDataTable";
import {
  formatNumberToFixed,
  formatAddress,
  formatDateTime,
} from "../../helper";
import Pagination from "../share/Pagination";
import { ILoadUserDetailRequest } from "../../types/wallet.type";
import { useDispatch, useSelector } from "react-redux";
import { loadWithdrawalUserAction } from "../../actions";
import { IStoreState } from "../../types";
import {
  TRANSACTION_TYPE,
  SYMBOL_DECIMAL,
  DataAllTypesUserWithdrawal,
} from "../../constants/transaction.constant";
import iconRefuse from "../../assets/images/refuse.svg";
import iconApprove from "../../assets/images/approve.svg";
import iconPending from "../../assets/images/pending.svg";
import showItem from "../../assets/images/showItem.svg";
import {
  IUserBankAccountInfo,
  IWithdrawLoanInfo,
} from "../../types/withdraw.type";
import { BankAccountUser } from "../dialog/BankAccountUser.dialog";
import { WithdrawAssetUser } from "../dialog/WithdrawAssetUser.dialog";
import { useHistory } from "react-router";
import { WithdrawSavingUser } from "../dialog/WithdrawSavingUser.dialog";
import { NexoAutocomplete } from "../share/NexoAutocomplete";
import { ISelectInfo } from "../../types/transaction.type";
import { StatusTransaction } from "../share/StatusTransaction";
import { CopyDepositAddress } from "../share/CopyDepositAddress";
const styles = makeStyles({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  paddingTableHead: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  textAmountIn: {
    color: "#45BB83",
  },
  textAmountOut: {
    color: "#C61615",
  },
  autocomplete: {
    height: 40,
    width: 200,
  },
});

interface IProps {
  id: string;
  idWithdraw: string;
}

export interface IWithdrawIntemInfo {
  adminApprovedItem: boolean;
  adminDeniedItem: boolean;
  amount: string;
  id: string;
  symbol: string;
  recipientAddress: string;
  userId: string;

  userBankAccount?: IUserBankAccountInfo;
}

export const Withdrawal = (props: IProps) => {
  const dispatch = useDispatch();
  const classes = styles();
  const history = useHistory();
  const userWithdrawals = useSelector(
    (state: IStoreState) => state.userWithdrawals
  );
  const [meta, setMeta] = useState<ILoadUserDetailRequest>({
    limit: 10,
    offset: 0,
    order: {},
    where: {},
    id: props.id,
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showDialogItem, setShowDialogItem] = useState(false);
  const [showDialogAssetItem, setShowDialogAssetItem] = useState(false);
  const [showDialogSavingItem, setShowDialogSavingItem] = useState(false);
  const [activeIconCopy, setActiveIconCopy] = useState<string[]>([]);

  const [withdrawIntemInfo, setWithdrawIntemInfo] = useState<
    IWithdrawIntemInfo
  >({
    adminApprovedItem: false,
    adminDeniedItem: false,
    amount: "",
    id: "",
    recipientAddress: "",
    symbol: "",
    userId: "",
    userBankAccount: {
      accountNumber: "",
      address: "",
      city: "",
      countryId: "",
      description: "",
      id: "",
      name: "",
      postalCode: "",
      recipientType: "",
      swiftCode: "",
      transferType: "",
      userId: "",
    },
  });

  const copyDepositAddress = (id: string) => () => {
    let checkActiveCopy = [];
    checkActiveCopy.push(id);
    setActiveIconCopy(checkActiveCopy);
  };

  useEffect(() => {
    dispatch(loadWithdrawalUserAction(meta));
  }, [meta, dispatch]);

  useEffect(() => {
    if (props.idWithdraw !== "") {
      const newValueWithdrawItem: IWithdrawLoanInfo[] = userWithdrawals.data.filter(
        (propEl) => propEl.id === props.idWithdraw
      );
      if (newValueWithdrawItem.length > 0) {
        let newWithdrawIntemInfo: IWithdrawIntemInfo = {
          adminApprovedItem: false,
          adminDeniedItem: false,
          amount: "",
          id: "",
          recipientAddress: "",
          symbol: "",
          userId: "",
          userBankAccount: {
            accountNumber: "",
            address: "",
            city: "",
            countryId: "",
            description: "",
            id: "",
            name: "",
            postalCode: "",
            recipientType: "",
            swiftCode: "",
            transferType: "",
            userId: "",
          },
        };

        newWithdrawIntemInfo.adminApprovedItem =
          newValueWithdrawItem[0].adminApproved;
        newWithdrawIntemInfo.adminDeniedItem =
          newValueWithdrawItem[0].adminDenied;
        newWithdrawIntemInfo.amount = newValueWithdrawItem[0].amount;
        newWithdrawIntemInfo.id = newValueWithdrawItem[0].id;
        newWithdrawIntemInfo.recipientAddress =
          newValueWithdrawItem[0].recipientAddress;

        newWithdrawIntemInfo.symbol = newValueWithdrawItem[0].currency.symbol;
        newWithdrawIntemInfo.userId = newValueWithdrawItem[0].userId;
        newWithdrawIntemInfo.userBankAccount =
          newValueWithdrawItem[0].userBankAccount;

        setWithdrawIntemInfo(newWithdrawIntemInfo);

        switch (newValueWithdrawItem[0].withdrawalType) {
          case TRANSACTION_TYPE.ASSET_WITHDRAWAL:
            setShowDialogAssetItem(true);

            break;
          case TRANSACTION_TYPE.SAVING_WITHDRAWAL:
            setShowDialogSavingItem(true);
            break;
          default:
            setShowDialogItem(true);

            break;
        }
      }
    }
  }, [props.idWithdraw, userWithdrawals]);

  const handleChangePage = (event: any, page: number) => {
    setMeta({
      ...meta,
      limit: rowsPerPage,
      offset: page * userWithdrawals.meta.limit,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMeta({
      ...meta,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    });
  };
  const renderAmountTransaction = (
    type: string,
    amount: number,
    symbol: string
  ) => {
    let colorAmount: boolean = false;
    let textAmount: string = "-";
    if (
      type === TRANSACTION_TYPE.DEPOSIT ||
      type === TRANSACTION_TYPE.REPAYMENT ||
      type === TRANSACTION_TYPE.SAVING ||
      type === TRANSACTION_TYPE.SAVING_INTEREST
    ) {
      colorAmount = true;
      textAmount = "+";
    } else {
      colorAmount = false;
      textAmount = "-";
    }
    return (
      <span
        className={
          colorAmount === true ? classes.textAmountIn : classes.textAmountOut
        }
      >
        {`${textAmount} ${formatNumberToFixed(
          amount,
          Number(SYMBOL_DECIMAL[symbol as any]) || 8
        )} ${symbol}`}
      </span>
    );
  };

  const handleClose = () => {
    history.replace({
      state: {
        idWithdraw: "",
      },
    });
    setShowDialogItem(false);
    setShowDialogAssetItem(false);
    setShowDialogSavingItem(false);
  };

  const handleChildWithdrawLoanInfo = (dataItem: IWithdrawLoanInfo) => () => {
    switch (dataItem.withdrawalType) {
      case TRANSACTION_TYPE.ASSET_WITHDRAWAL:
        setShowDialogAssetItem(true);
        break;
      case TRANSACTION_TYPE.SAVING_WITHDRAWAL:
        setShowDialogSavingItem(true);
        break;
      default:
        setShowDialogItem(true);
        break;
    }
    setWithdrawIntemInfo({
      ...withdrawIntemInfo,
      adminApprovedItem: dataItem.adminApproved,
      adminDeniedItem: dataItem.adminDenied,
      amount: dataItem.amount,
      id: dataItem.id,
      recipientAddress: dataItem.recipientAddress,
      symbol: dataItem.currency.symbol,
      userBankAccount: dataItem.userBankAccount,
      userId: dataItem.userId,
    });
  };

  const handleChangeType = (type: string) => (
    event: any,
    value: ISelectInfo | null
  ) => {
    let newWhere = meta.where;
    if (newWhere) {
      newWhere[type] = value ? value.symbol : "";
    }
    setMeta({
      ...meta,
      offset: 0,
      where: value ? newWhere : {},
    });
  };

  return (
    <Grid
      style={{
        marginTop: 50,
      }}
    >
      <RootTable
        nameTable="All Withdrawals"
        ActionsComponent={
          <Grid className={classes.autocomplete}>
            <NexoAutocomplete
              content=""
              placeholderInput="All Types"
              dataSelect={DataAllTypesUserWithdrawal}
              onChangeType={handleChangeType("withdrawalType")}
            />
          </Grid>
        }
      >
        <Table>
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: "1%",
                }}
              >
                {"#"}
              </TableCell>
              <TableCell
                style={{
                  width: "10%",
                }}
                align="center"
                className={classes.tableHead}
              >
                Currency
              </TableCell>
              <TableCell
                style={{
                  width: "10%",
                }}
                className={classes.tableHead}
              >
                Type
              </TableCell>
              <TableCell
                style={{
                  width: "12%",
                }}
                className={classes.tableHead}
              >
                Amount
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                }}
                className={classes.tableHead}
              >
                Recipient Name
              </TableCell>

              <TableCell
                style={{
                  width: "12%",
                }}
                align="right"
                className={classes.tableHead}
              >
                Recipient Address
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                }}
                align="right"
                className={classes.tableHead}
              >
                User Apporved/Reject
              </TableCell>
              <TableCell
                align="center"
                style={{
                  width: "10%",
                }}
                className={classes.tableHead}
              >
                Status
              </TableCell>

              <TableCell
                style={{
                  width: "10%",
                }}
                className={classes.tableHead}
              >
                Date/Time
              </TableCell>

              <TableCell
                align="right"
                style={{
                  width: "5%",
                  paddingRight: 16,
                }}
                className={classes.tableHead}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userWithdrawals.data.length ? (
              userWithdrawals.data.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableCell}>
                      <TableCell className={classes.tableBody}>
                        {index +
                          1 +
                          userWithdrawals.meta.limit *
                            (userWithdrawals.meta.currentPage - 1)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        className={classes.tableBody}
                      >
                        <img
                          src={
                            (`${process.env.REACT_APP_API_URL}static/images/currencies/` ||
                              "") + el.currency.image
                          }
                          width={25}
                          height={25}
                          alt="icon currency"
                        />
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.withdrawalType}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {renderAmountTransaction(
                          el.withdrawalType,
                          Number(el.amount),
                          el.currency.symbol
                        )}
                      </TableCell>

                      <TableCell className={classes.tableBody}>
                        {el.userBankAccount ? el.userBankAccount.name : "-"}
                      </TableCell>

                      <TableCell align="right" className={classes.tableBody}>
                        {el.userBankAccount?.accountNumber ||
                          formatAddress(el.recipientAddress)}
                        <CopyDepositAddress
                          id={el.id}
                          text={
                            el.userBankAccount
                              ? el.userBankAccount.accountNumber
                              : el.recipientAddress
                          }
                          copyDepositAddress={copyDepositAddress(el.id)}
                          activeIconCopy={activeIconCopy}
                        />
                      </TableCell>
                      <TableCell align="right" className={classes.tableBody}>
                        -
                      </TableCell>

                      <TableCell className={classes.tableBody} align="center">
                        {el.adminApproved === true ? (
                          <StatusTransaction
                            title="Apporved"
                            srcImage={iconApprove}
                          />
                        ) : el.adminDenied === true || el.deletedAt !== null ? (
                          <StatusTransaction
                            title="Reject"
                            srcImage={iconRefuse}
                          />
                        ) : null}

                        {el.adminApproved === false &&
                        el.adminDenied === false &&
                        el.deletedAt === null ? (
                          <StatusTransaction
                            title="Pending"
                            srcImage={iconPending}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.createdAt ? formatDateTime(el.createdAt) : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 16,
                        }}
                        align="right"
                        className={classes.tableBody}
                      >
                        <Tooltip title="View" aria-label="add">
                          <IconButton
                            onClick={handleChildWithdrawLoanInfo(el)}
                            style={{
                              width: 30,
                              height: 30,
                              padding: 0,
                            }}
                          >
                            <img
                              src={showItem}
                              alt="icon"
                              width={15}
                              height={15}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <CardNoDataTable colSpan={10} />
            )}
          </TableBody>
        </Table>
        <Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={userWithdrawals.meta.total ? userWithdrawals.meta.total : 0}
            rowsPerPage={rowsPerPage}
            page={
              userWithdrawals.meta.currentPage
                ? userWithdrawals.meta.currentPage - 1
                : 0
            }
            onChangePage={handleChangePage}
            ActionsComponent={Pagination}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </RootTable>
      <BankAccountUser
        showDialog={showDialogItem}
        handleClose={handleClose}
        withdrawIntemInfo={withdrawIntemInfo}
      />
      <WithdrawAssetUser
        showDialog={showDialogAssetItem}
        handleClose={handleClose}
        withdrawIntemInfo={withdrawIntemInfo}
      />
      <WithdrawSavingUser
        showDialog={showDialogSavingItem}
        handleClose={handleClose}
        withdrawIntemInfo={withdrawIntemInfo}
      />
    </Grid>
  );
};
