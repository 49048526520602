import React, { useState } from "react";
import { Grid, makeStyles, IconButton, Theme } from "@material-ui/core";
import { ARROW_UPWARD } from "../../constants/users.constant";
import iconArrowUp from "../../assets/images/iconArrowUp.svg";
import iconNoArrow from "../../assets/images/iconNoArrow.svg";
import iconArrowDown from "../../assets/images/iconArrowDown.svg";

const styles = makeStyles((theme: Theme) => ({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    [theme.breakpoints.down(1440)]: {
      maxWidth: 60,
    },
  },
  rootSvgButton: {
    padding: 7,
    marginLeft: 5,
  },
}));

interface IProps {
  title: string;
  type: string;
  handleChangeArrow: (typeTable: string, orderBy: string | undefined) => void;
  justifyTableHeader?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
}

export const OrderByTableHeader = (props: IProps) => {
  const { title, type, justifyTableHeader } = props;
  const classes = styles();
  const [arrowTable, setArrowTable] = useState<string>(ARROW_UPWARD.NO);

  const handleChangeOrderBy = (typeTable: string, arrow: string) => () => {
    let newOrderBy: string | undefined = undefined;
    let showIcon: string = "";
    switch (arrow) {
      case "":
        newOrderBy = ARROW_UPWARD.UP;
        showIcon = ARROW_UPWARD.UP;
        break;
      case "up":
        newOrderBy = ARROW_UPWARD.DOWN;
        showIcon = ARROW_UPWARD.DOWN;
        break;
      case "down":
        newOrderBy = undefined;
        showIcon = ARROW_UPWARD.NO;
        break;
    }
    setArrowTable(showIcon);
    props.handleChangeArrow(typeTable, newOrderBy);
  };

  return (
    <Grid
      container
      // style={{ display: "grid", gridTemplateColumns: "calc(100% - 26px) 26px" }}
      justify={justifyTableHeader}
      alignItems="center"
    >
      <span className={classes.tableHead}>{title}</span>
      <IconButton
        onClick={
          arrowTable === ARROW_UPWARD.NO
            ? handleChangeOrderBy(type, "")
            : arrowTable === ARROW_UPWARD.UP
            ? handleChangeOrderBy(type, "up")
            : handleChangeOrderBy(type, "down")
        }
        className={classes.rootSvgButton}
      >
        <img
          src={
            arrowTable === ARROW_UPWARD.NO
              ? iconNoArrow
              : arrowTable === ARROW_UPWARD.UP
              ? iconArrowUp
              : iconArrowDown
          }
          alt="icon Arrow"
          width={12}
        />
      </IconButton>
    </Grid>
  );
};
