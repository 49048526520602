import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { WithdrawsLoans } from "../components/WithdrawLoans";
import { WithdrawAssets } from "../components/WithdrawAssets";
import { WithdrawSaving } from "../components/WithdrawSaving";
import { ProfileCard } from "../components/ProfileCard";

export const Withdraws = (props: any) => {
  const [value, setValue] = useState<number>(0);
  const dataNavigation = [
    "Withdraw Loans",
    "Withdraw Assets",
    "Withdraw Savings",
  ];

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setValue(props.history.location.state);
    }
  }, [props.history.location.state]);
  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <Grid>
        {value === 0 ? (
          <WithdrawsLoans />
        ) : value === 1 ? (
          <WithdrawAssets />
        ) : (
          <WithdrawSaving />
        )}
      </Grid>
    </ProfileCard>
  );
};
