import { REACT_APP_API_URL } from "./environment";
import axios, { Method } from "axios";
import { ICallApiRequest } from "../types/callApi.type";

axios.defaults.baseURL = `${REACT_APP_API_URL}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem("token") || "";
    //retrieve new access token and assign to authorization header
    config.headers.common["Authorization"] = `Bearer ${token}`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const callApi = async (request: ICallApiRequest) => {
  const method: Method = request.method;
  const option = {
    url: request.url,
    method,
    data: request.data ? request.data : {},
    params: request.params ? request.params : {},
  };
  const response = await axios(option);
  return response;
};
