export const INIT_AXIOS_INTERCEPTOR = "INIT_AXIOS_INTERCEPTOR";
export type INIT_AXIOS_INTERCEPTOR = typeof INIT_AXIOS_INTERCEPTOR;

export const LOGIN = "LOGIN";
export type LOGIN = typeof LOGIN;

export const LOGOUT = "LOGOUT";
export type LOGOUT = typeof LOGOUT;

export const SET_USER_PROFILE = "SET_USER_PROFILE";
export type SET_USER_PROFILE = typeof SET_USER_PROFILE;

export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export type LOAD_USER_PROFILE = typeof LOAD_USER_PROFILE;
