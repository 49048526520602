import { ISetCurrencyList } from "../actions/currencies.action";
import { IObjectCurrencyList } from "../types/currencies.type";

const initialCurrency: IObjectCurrencyList = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};
export const currencyList = (
  state: IObjectCurrencyList = initialCurrency,
  action: ISetCurrencyList
) => {
  switch (action.type) {
    case "SET_CURRENCY_LIST":
      return action.payload;
    default:
      return state;
  }
};
