import React, { useState, useEffect } from "react";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { IVerfifycationImage } from "../../types/user.type";
import Lightbox from "react-image-lightbox";

const styles = makeStyles({
  avatarIcons: {
    width: 100,
    height: 100,
    border: "2px dashed #B2B6BA",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  mgr_10: {
    marginRight: 10,
  },
  buttonImage: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});
interface IProps {
  header: string;
  listImage: IVerfifycationImage[];
}
export const ListImageUser = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const classes = styles();
  const { header, listImage } = props;

  const handleZoomImage = (key: number) => () => {
    setPhotoIndex(key);
    setIsOpen(true);
  };
  const onCloseRequest = () => {
    setIsOpen(false);
  };
  const onMoveRequest = (type: string) => () => {
    let photo = 0;
    if (type === "prew") {
      photo = (photoIndex + images.length - 1) % images.length;
    } else {
      photo = (photoIndex + 1) % images.length;
    }
    setPhotoIndex(photo);
  };

  useEffect(() => {
    if (listImage) {
      let picture: string[] = [];
      listImage.map((el, index) => {
        picture.push((process.env.REACT_APP_API_URL || "") + el.url);
        return picture;
      });
      setImages(picture);
    }
  }, [listImage]);
  return (
    <Grid
      style={{ paddingLeft: 16, minHeight: 150 }}
      container
      alignItems="center"
    >
      <Grid container>
        <Typography variant="h4" style={{ color: "rgba(5, 15, 71, 0.31)" }}>
          {header}
        </Typography>
      </Grid>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={onCloseRequest}
          onMovePrevRequest={onMoveRequest("prev")}
          onMoveNextRequest={onMoveRequest("next")}
        />
      )}
      {listImage.length > 0
        ? listImage.map((el, key) => (
            <Button
              className={classes.buttonImage}
              key={key}
              onClick={handleZoomImage(key)}
            >
              <img
                key={key}
                src={(process.env.REACT_APP_API_URL || "") + el.url}
                alt=""
                className={`${classes.avatarIcons} ${classes.mgr_10}`}
              />
            </Button>
          ))
        : null}
    </Grid>
  );
};
