import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../actions/auth.action";
import iconProfile from "../assets/images/iconProfile.svg";
import iconLogout from "../assets/images/iconLogout.svg";
import { Navigation } from "./share/Navigation";
import Scrollbars from "react-custom-scrollbars";
import { IStoreState } from "../types";

interface IProps {
  handleChangeBottomNavigation: (
    event: React.ChangeEvent<{}>,
    value: any
  ) => void;
  value: number;
  dataNavigation: string[];
  children: React.ReactNode;
}
export const ProfileCard = (props: IProps) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state: IStoreState) => state.userProfile);
  const handleLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <React.Fragment>
      <Scrollbars
        style={{
          overflow: "hidden",
          width: "100%",
          height: "calc(100vh - 60px)",
        }}
      >
        <Grid
          style={{
            marginBottom: 56,
            display: "grid",
            minWidth: 1100,
            gridTemplateColumns: "calc(100% - 250px) 250px",
          }}
        >
          <Navigation
            handleChange={props.handleChangeBottomNavigation}
            value={props.value}
            dataNavigation={props.dataNavigation}
          />
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            style={{ paddingRight: 30 }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: 600, color: "#252733" }}
            >
              {`${userProfile.firstName ? userProfile.firstName : ""} ${
                userProfile.lastName ? userProfile.lastName : ""
              }`}
            </Typography>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                width: 44,
                height: 44,
                border: "1px solid #DFE0EB",
                borderRadius: "50%",
                margin: "0px 15px",
              }}
            >
              <img src={iconProfile} alt="icon avatar" width={30} height={30} />
            </Grid>

            <img
              src={iconLogout}
              onClick={handleLogout}
              alt="log out"
              width={20}
              height={20}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <Grid style={{ minWidth: 1100, marginBottom: 30 }}>
          <Grid style={{ paddingRight: 30 }}>{props.children}</Grid>
        </Grid>
      </Scrollbars>
    </React.Fragment>
  );
};
