import { Store } from "redux";
import { IStoreState } from "../types";
import { Dispatch } from "react";
import {
  setUserApproveListAction,
  ILoadUserApproveListAction,
  IVerificationAdvancedUserAction,
  IVerificationRefuseUserAction,
} from "../actions/userApprove.action";
import Axios, { AxiosResponse } from "axios";
import { IObjectListUser } from "../types/user.type";
import {
  lOAD_USER_APPROVE_LIST,
  VERIFICATION_ADVANCED_USER_APPROVE,
  VERIFICATION_REFUSE_USER_APPROVE,
} from "../constants/userApprove.constant";
import { fireNotification } from "../actions/Notification.action";
import Variant from "../types/Variant.type";
import { PATH } from "../constants/PATH";
import { loadUserDetailAction } from "../actions";

export const userMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadUserApproveListAction
    | IVerificationAdvancedUserAction
    | IVerificationRefuseUserAction
  >
) => async (
  action:
    | ILoadUserApproveListAction
    | IVerificationAdvancedUserAction
    | IVerificationRefuseUserAction
) => {
  switch (action.type) {
    case lOAD_USER_APPROVE_LIST: {
      Axios.get(`/users/verification`, {
        params: action.payload,
      })
        .then((data: AxiosResponse<IObjectListUser>) => {
          if (data.data) {
            store.dispatch(setUserApproveListAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }

    case VERIFICATION_ADVANCED_USER_APPROVE: {
      Axios.patch(`/users/${action.payload}/verification/advanced`, {
        status: true,
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `Approve Successfully`,
              variant: Variant.SUCCESS,
              path: PATH.users,
              state: 0,
            })
          );
          store.dispatch(loadUserDetailAction(action.payload));
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
    case VERIFICATION_REFUSE_USER_APPROVE: {
      Axios.patch(`/users/${action.payload}/verification/advanced`, {
        status: false,
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `Reject Successfully`,
              variant: Variant.SUCCESS,
              path: PATH.users,
              state: 0,
            })
          );
          store.dispatch(loadUserDetailAction(action.payload));
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
  }
  return dispatch(action);
};
