import {
  ISetUserListAction,
  ISetUserDetailAction,
  ISetWalletUserAction,
  ISetwithdrawalUserAction,
  ISetTransactionUserAction,
  ISetActivityLogUserAction,
} from "../actions";
import { IObjectListUser, IUserInfo } from "../types/user.type";
import {
  SET_USER_LIST,
  SET_USER_DETAIL,
  SET_WALLET_USER,
  SET_WITHDRAWAL_USER,
  SET_TRANSACTION_USER,
  SET_ACTIVITY_LOG_USER,
} from "../constants/users.constant";
import { IObjectWalletUser } from "../types/wallet.type";
import { IObjectListWithdrawLoan } from "../types/withdraw.type";
import { emptyWithdrawLoan } from "./withdraw.reducer";
import { IObjectTransactionUser } from "../types/users/transactionUser.type";
import { IObjectActivityLogsList } from "../types/activityLogs.type";

const initialUser: IObjectListUser = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};
export const initialProfileUser: IUserInfo = {
  address: "",
  city: "",
  country: {
    id: "",
    isoCode: "",
    name: "",
    nameEn: "",
  },
  countryId: "",
  createdAt: "",
  created_at: "",
  dob: new Date(),
  email: "",
  firstName: "",
  first_name: "",
  id: "",
  isGoogleAuthenticator: false,
  isSmsAuthentication: false,
  isVerified: false,
  lastName: "",
  last_name: "",
  phone: "",
  postCode: "",
  postcode: "",
  roleId: "",
  verificationImages: [],
  advancedVerificationStatus: "",
  approvedImage: [],
  verificationLevel2: false,
  verificationLevel1: false,
  isPendingAdvanceVerification: false,
};
const initialWalletUser: IObjectWalletUser = {
  availableCredit: "",
  creditLine: "",
  id: "",
  interest: "",
  loan: "",
  ltv: "",
  saving: "",
  savingInterest: "",
  userId: "",
  wallets: [],
};

export const UserList = (
  state: IObjectListUser = initialUser,
  action: ISetUserListAction
): IObjectListUser => {
  switch (action.type) {
    case SET_USER_LIST:
      return action.payload;

    default:
      return state;
  }
};

export const profileUser = (
  state: IUserInfo = initialProfileUser,
  action: ISetUserDetailAction
): IUserInfo => {
  switch (action.type) {
    case SET_USER_DETAIL:
      return action.payload;

    default:
      return state;
  }
};

export const wallets = (
  state: IObjectWalletUser = initialWalletUser,
  action: ISetWalletUserAction
): IObjectWalletUser => {
  switch (action.type) {
    case SET_WALLET_USER:
      return action.payload;

    default:
      return state;
  }
};

export const userWithdrawals = (
  state: IObjectListWithdrawLoan = emptyWithdrawLoan,
  action: ISetwithdrawalUserAction
): IObjectListWithdrawLoan => {
  switch (action.type) {
    case SET_WITHDRAWAL_USER:
      return action.payload;

    default:
      return state;
  }
};

const initialTransactionUser: IObjectTransactionUser = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};

export const transactionUser = (
  state: IObjectTransactionUser = initialTransactionUser,
  action: ISetTransactionUserAction
): IObjectTransactionUser => {
  switch (action.type) {
    case SET_TRANSACTION_USER:
      return action.payload;

    default:
      return state;
  }
};

const initialActivityLog: IObjectActivityLogsList = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};

export const activityLogUser = (
  state: IObjectActivityLogsList = initialActivityLog,
  action: ISetActivityLogUserAction
): IObjectActivityLogsList => {
  switch (action.type) {
    case SET_ACTIVITY_LOG_USER:
      return action.payload;

    default:
      return state;
  }
};
