import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Typography, InputAdornment } from "@material-ui/core";
import classNames from "classnames";
import errorIcon from "../../assets/images/shape.svg";
import openPassword from "../../assets/images/openPassword.svg";
import hiddenPassword from "../../assets/images/hiddenPassword.svg";

const styles = makeStyles({
  root: {
    marginBottom: 20,
    width: "100%",
  },
  inputNormal: {
    border: "1px solid #bbbdc0",
    height: 40,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: "normal",
    fontFamily: "Montserrat",
    "&:hover": {
      border: "1px solid #34CEA9",
    },
    "& .MuiInputAdornment-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F9F9FB",
      minWidth: 100,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      margin: "0 auto",
      padding: "10px 0px",
      borderRight: "1px solid #D7DCE0",
      height: 18,
      fontWeight: 600,
    },
    "& .MuiInput-input": {
      backgroundColor: "#fff",
      height: 36,
      padding: "0px 10px",
      borderRadius: "0px 5px 5px 0px",
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
      color: "#050F47",
    },
  },
  inputError: {
    border: "1px solid #EB5757 !important",
  },
  errorGroup: {
    marginTop: 10,
    fontSize: 12,
    color: "#E3001E",
    fontFamily: "Montserrat",
  },
  errorIcon: {
    marginRight: 10,
    width: 15.11,
    height: 13.33,
  },
  inputDisabled: {
    backgroundColor: "#F9F9FB",
    "&:hover": {
      border: "1px solid #D7DCE0",
    },
    "& .MuiInput-input": {
      backgroundColor: "#F9F9FB !important",
    },
  },
  img: {
    paddingRight: 12,
    paddingLeft: 12,
    cursor: "pointer",
  },
});

interface IProps {
  title: string;
  type: string;
  error?: boolean;
  msgError?: string;
  disabled?: boolean;
  value?: unknown;
  name?: string;
  handleChangeInput?: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const NexoInput = (props: IProps) => {
  const classes = styles();

  const [changeIconPassword, setChangeIconPassword] = useState(false);

  const handleChangeIconPassword = () => {
    setChangeIconPassword(!changeIconPassword);
  };

  const showIconError = () => {
    const { error } = props;
    if (error) {
      return (
        <img className={classes.errorIcon} src={errorIcon} alt="errorIcon" />
      );
    }
    return;
  };

  const { title, type, error, msgError, disabled, value } = props;

  return (
    <div className={classes.root}>
      <Input
        className={
          error
            ? classNames(classes.inputError, classes.inputNormal)
            : disabled
            ? classNames(classes.inputDisabled, classes.inputNormal)
            : classes.inputNormal
        }
        value={value ? value : ""}
        disableUnderline={true}
        startAdornment={
          <InputAdornment position="start">
            <Typography variant="h4" style={{ fontSize: 12 }}>
              {title}
            </Typography>
          </InputAdornment>
        }
        endAdornment={
          type === "password" ? (
            <img
              src={changeIconPassword ? hiddenPassword : openPassword}
              onClick={handleChangeIconPassword}
              className={classes.img}
              alt="icon change password"
            />
          ) : null
        }
        inputProps={{
          style:
            !changeIconPassword && type === "password"
              ? {
                  fontSize: 40,
                  letterSpacing: "0.2em",
                  height: 14,
                  paddingBottom: 5,
                }
              : {},
        }}
        type={changeIconPassword ? "password " : type}
        error={error}
        disabled={disabled}
        onChange={
          props.handleChangeInput && props.handleChangeInput(props.name || "")
        }
        onKeyPress={props.onKeyPress}
      />
      {error ? (
        <Typography className={classes.errorGroup}>
          {showIconError()}
          {msgError}
        </Typography>
      ) : null}
    </div>
  );
};
