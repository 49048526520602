import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  updateConfigAction,
  addConfigAction,
  deleteConfigAction,
} from "../../actions";
import { NexoInputConfig } from "../share/NexoInputConfig";
import { NexoButton } from "../share/NexoButton";
import { IConfig } from "../../types/config.type";
import { DataDefault, CONFIG_TYPE } from "../../constants/config.constants";
import IconAdd from "../../assets/images/add.svg";
import { ConfirmDialog } from "../dialog/Confirm.dialog";

const styles = makeStyles({
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#050F47",
  },
  card: {
    background: "#FFFFFF",
    border: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderRadius: 5,
  },
  item: {
    background: " #F9FAFB",
    borderBottom: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: 60,
    paddingLeft: 16,
    paddingRight: 16,
  },
});

interface IProps {
  dataTransferType: IConfig[];
  dataDisable: boolean[];
}

export const TransferTypeConfig = (props: IProps) => {
  const { dataTransferType, dataDisable } = props;
  const dispatch = useDispatch();
  const classes = styles();
  const [disableAddtransferType, setDisableAddTransferType] = useState<Boolean>(
    false
  );
  const [transferTypeAdd, setTransferTypeAdd] = useState<IConfig>(DataDefault);
  const [transferTypeId, setTransferTypeId] = useState("");
  const [transferType, settransferType] = useState<IConfig[]>([]);
  const [, setRender] = useState(0);

  const [disableButton, setDisableButton] = useState<boolean[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [indexItem, setIndexItem] = useState(-1);

  const checkDisable = (index: number, value: string) => {
    const newDisableButton = disableButton;
    if (value === "") {
      newDisableButton[index] = true;
    } else {
      newDisableButton[index] = false;
    }
  };

  const handleSetDisableAddTransferType = (type: string) => () => {
    setDisableAddTransferType(true);
  };

  const handlleDeleteConfig = () => {
    if (transferTypeId !== "") {
      dispatch(
        deleteConfigAction({ id: transferTypeId, typeNoti: "Transfer Type" })
      );
      setOpenConfirmDialog(!openConfirmDialog);
    }
  };

  const handleOpenConfirmDialog = (id: string) => () => {
    setTransferTypeId(id);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const forceUpdate = () => {
    setRender((render) => ++render); // update the state to force render
  };

  const handleChangeInput = (
    type: string | undefined,
    index: number | undefined
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (type) {
      case CONFIG_TYPE.TRANSFER_TYPE:
        if (index !== undefined) {
          setIndexItem(index);

          checkDisable(index, event.target.value);
          let newtransferType = transferType;
          newtransferType[index].type = event.target.value;

          settransferType(newtransferType);
          forceUpdate();
        }
        break;
      case CONFIG_TYPE.TRANSFER_TYPE_ADD:
        setIndexItem(-1);
        setTransferTypeAdd({
          ...transferTypeAdd,
          type: event.target.value,
        });
        break;
    }
  };

  const handeSave = (index?: number) => () => {
    let dataUpdate = {
      id: "",
      value: 0,
      type: "",
      typeNoti: "",
    };

    if (index !== undefined) {
      dataUpdate.id = transferType[index].id;
      dataUpdate.type = transferType[index].type;
      dataUpdate.typeNoti = "Transfer Type";
    }

    if (dataUpdate.id !== "") {
      dispatch(updateConfigAction(dataUpdate));
      if (index !== undefined) {
        setDisableButton({ ...disableButton, [index]: true });
      }
      forceUpdate();
    }
  };

  const handleAddConfig = (type: string) => () => {
    let dataAdd = { type: "", name: "", typeNoti: "Transfer Type" };
    dataAdd.type = transferTypeAdd.type;
    dataAdd.name = "TRANSFER_TYPE";
    setDisableAddTransferType(false);
    setTransferTypeAdd(DataDefault);
    dispatch(addConfigAction(dataAdd));
  };

  const handleWhenClickDelete = () => {
    setDisableAddTransferType(false);
  };

  useEffect(() => {
    settransferType(dataTransferType);
    setDisableButton(dataDisable);
  }, [dataTransferType, dataDisable]);

  return (
    <Grid container className={classes.card}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.item}
      >
        <Typography style={{ fontSize: 16 }} className={classes.title}>
          Transfer Type
        </Typography>
        <img
          src={IconAdd}
          alt="Add"
          onClick={handleSetDisableAddTransferType("Transfer Type")}
        />
      </Grid>
      {disableAddtransferType ? (
        <Grid container style={{ padding: 16 }}>
          <Grid item xs={7}>
            <NexoInputConfig
              type="text"
              value={transferTypeAdd.type}
              name="Transfer Type Add"
              handleChangeInput={handleChangeInput}
              error={transferTypeAdd.type === "" ? true : false}
              msgError="Please enter transfer type!"
            />
          </Grid>

          <Grid container item xs={5} style={{ paddingLeft: 50 }}>
            <Grid
              style={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "49% 49%",
                columnGap: "2%",
              }}
            >
              <NexoButton
                heightButton={40}
                title="Save"
                variant="contained"
                color="primary"
                handleRedirect={handleAddConfig(CONFIG_TYPE.TRANSFER_TYPE)}
                disabled={transferTypeAdd.type === "" ? true : false}
              />
              <NexoButton
                heightButton={40}
                title="delete"
                variant="contained"
                color="inherit"
                handleRedirect={handleWhenClickDelete}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {transferType.length > 0
        ? transferType.map((el, index) => {
            return (
              <Grid container style={{ padding: 16 }} key={index}>
                <Grid item xs={7}>
                  <NexoInputConfig
                    type="text"
                    value={transferType[index].type}
                    name="Transfer Type"
                    index={index}
                    handleChangeInput={handleChangeInput}
                    error={transferType[index].type === "" ? true : false}
                    msgError="Please enter transfer type!"
                    disabled={
                      el.type !== "SWIFT Code"
                        ? false
                        : el.type === "SWIFT Code" && indexItem === index
                        ? false
                        : true
                    }
                  />
                </Grid>
                <Grid container item xs={5} style={{ paddingLeft: 50 }}>
                  {el.type !== "SWIFT Code" ? (
                    <Grid
                      container
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "49% 49%",
                        columnGap: "2%",
                      }}
                    >
                      <NexoButton
                        heightButton={40}
                        title="Save"
                        variant="contained"
                        color="primary"
                        handleRedirect={handeSave(index)}
                        disabled={disableButton[index]}
                      />
                      <NexoButton
                        heightButton={40}
                        title="delete"
                        variant="contained"
                        color="inherit"
                        handleRedirect={handleOpenConfirmDialog(el.id)}
                      />
                    </Grid>
                  ) : el.type === "SWIFT Code" &&
                    indexItem === index &&
                    disableButton[index] === false ? (
                    <Grid
                      container
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "49% 49%",
                        columnGap: "2%",
                      }}
                    >
                      <NexoButton
                        heightButton={40}
                        title="Save"
                        variant="contained"
                        color="primary"
                        handleRedirect={handeSave(index)}
                        disabled={disableButton[index]}
                      />
                      <NexoButton
                        heightButton={40}
                        title="delete"
                        variant="contained"
                        color="inherit"
                        handleRedirect={handleOpenConfirmDialog(el.id)}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            );
          })
        : null}
      <ConfirmDialog
        showDialog={openConfirmDialog}
        handleClose={setOpenConfirmDialog}
        message="Please confirm you want to delete?"
        type="TRANSFER TYPE"
        handleSubmit={handlleDeleteConfig}
      />
    </Grid>
  );
};
