import { Store } from "redux";
import { IStoreState } from "../types";
import { Dispatch } from "react";
import Axios, { AxiosResponse } from "axios";
import {
  ILoadWithdrawLoanAction,
  setWithdrawLoanAction,
  ILoadWithdrawAssetAction,
  setWithdrawAssetAction,
  IApproveWithdrawAction,
  IDeniedWithdrawAction,
  ILoadWithdrawSavingAction,
  setWithdrawSavingAction,
} from "../actions/withdraw.action";
import {
  LOAD_WITHDRAW_LOAN_ACTION,
  LOAD_WITHDRAW_ASSET_ACTION,
  APPROVE_WITHDRAW_ACTION,
  DENIED_WITHDRAW_ACTION,
  LOAD_WITHDRAW_SAVING_ACTION,
} from "../constants/withdraw.constant";
import {
  IObjectListWithdrawLoan,
  IOjectListWithdrawAsset,
} from "../types/withdraw.type";
import { fireNotification } from "../actions/Notification.action";
import Variant from "../types/Variant.type";
import { loadWithdrawalUserAction } from "../actions";

export const withdrawMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadWithdrawLoanAction
    | ILoadWithdrawAssetAction
    | IApproveWithdrawAction
    | IDeniedWithdrawAction
    | ILoadWithdrawSavingAction
  >
) => async (
  action:
    | ILoadWithdrawLoanAction
    | ILoadWithdrawAssetAction
    | IApproveWithdrawAction
    | IDeniedWithdrawAction
    | ILoadWithdrawSavingAction
) => {
  switch (action.type) {
    case LOAD_WITHDRAW_LOAN_ACTION: {
      Axios.get(`/withdraw/${action.payload.type}`, {
        params: action.payload,
      })
        .then((data: AxiosResponse<IObjectListWithdrawLoan>) => {
          if (data.data) {
            store.dispatch(setWithdrawLoanAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAD_WITHDRAW_ASSET_ACTION: {
      Axios.get(`/withdraw/${action.payload.type}`, {
        params: action.payload,
      })
        .then((data: AxiosResponse<IOjectListWithdrawAsset>) => {
          if (data.data) {
            store.dispatch(setWithdrawAssetAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAD_WITHDRAW_SAVING_ACTION: {
      Axios.get(`/withdraw/${action.payload.type}`, {
        params: action.payload,
      })
        .then((data: AxiosResponse<IOjectListWithdrawAsset>) => {
          if (data.data) {
            store.dispatch(setWithdrawSavingAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case APPROVE_WITHDRAW_ACTION: {
      Axios.patch(`/withdraw/${action.payload.id}/approve`, {
        status: true,
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `Approve Withdraw Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(
            loadWithdrawalUserAction({
              limit: 10,
              offset: 0,
              order: {},
              id: action.payload?.idUser || "",
            })
          );
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
    case DENIED_WITHDRAW_ACTION: {
      Axios.patch(`/withdraw/${action.payload.id}/approve`, {
        status: false,
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `  Denied Withdraw Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(
            loadWithdrawalUserAction({
              limit: 10,
              offset: 0,
              order: {},
              id: action.payload?.idUser || "",
            })
          );
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
  }
  return dispatch(action);
};
