import React from "react";
import { Typography, TextField, Paper, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ISelectInfo } from "../../types/transaction.type";
import { ISelectCategory } from "../../types/activityLogs.type";

const styles = makeStyles({
  autoComplete: {
    width: "100%",
    height: 40,
    "& .MuiFormControl-fullWidth": {
      height: "100%",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
    "& .MuiInputBase-input": {
      color: "#050F47",
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 600,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CEA9",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: " #34CEA9",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

// tslint:disable-next-line
const PaperMy = function (props: any) {
  return (
    <Paper
      {...props}
      style={{
        width: "100%",
        color: "#050F47",
        fontWeight: 500,
        fontFamily: "Montserrat",
      }}
    />
  );
};

interface IProps {
  content?: string;
  placeholderInput?: string;
  dataSelect?: ISelectInfo[] | ISelectCategory[];
  onChangeType?: (event: any, value: ISelectInfo | null) => void;
}

export const NexoAutocomplete = (props: IProps) => {
  const { content, placeholderInput, dataSelect, onChangeType } = props;
  const classes = styles();
  return (
    <div>
      {content !== "" ? (
        <Typography variant="h5" style={{ marginBottom: 5 }}>
          {content}
        </Typography>
      ) : null}

      <Autocomplete
        onChange={onChangeType}
        PaperComponent={PaperMy}
        options={dataSelect ? dataSelect : []}
        // tslint:disable-next-line
        getOptionLabel={(option) => option.title}
        className={classes.autoComplete}
        // tslint:disable-next-line
        renderInput={(params) => (
          <TextField
            placeholder={placeholderInput}
            {...params}
            variant="outlined"
          />
        )}
      />
    </div>
  );
};
