import { IStoreState } from "../types";
import { Dispatch } from "react";
import { Store } from "redux";
import { TRANSACTION_URL } from "../helper/environment";
import { Method, AxiosResponse, AxiosError } from "axios";
import { callApi } from "../helper/callApi";
import {
  ILoadTransactionList,
  ISetTransactionList,
  setTransactionListAction,
} from "../actions/transaction.action";
import { IObjectTransactionList } from "../types/transaction.type";
import { LOAD_TRANSACTION_LIST } from "../constants/transaction.constant";

export const getTransactionList = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ILoadTransactionList | ISetTransactionList>
) => async (action: ILoadTransactionList) => {
  switch (action.type) {
    case LOAD_TRANSACTION_LIST:
      const asset = action.payload.asset;
      const type = action.payload.type;
      const url = `${TRANSACTION_URL}/${asset}/${type}`;
      const method: Method = "get";
      const params = {
        from: action.payload.from,
        to: action.payload.to,
        limit: action.payload.limit,
        offset: action.payload.offset,
        order: action.payload.order,
      };
      const payload = { method, url, params };

      callApi(payload)
        .then((response: AxiosResponse<IObjectTransactionList>) => {
          if (response && response.data) {
            dispatch(setTransactionListAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
  }
  return dispatch(action);
};
