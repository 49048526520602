import { Store } from "redux";
import { IStoreState } from "../types";
import { Dispatch } from "react";
import {
  IDeleteUserAdminAction,
  IAddUserAdminAction,
  IEditUserAdminAction,
} from "../actions/userAdmin.action";
import {
  DELETE_USER_ADMIN_ACTION,
  EDIT_USER_ADMIN,
} from "../constants/usersAdmin.constant";
import Axios, { AxiosError } from "axios";
import { fireNotification } from "../actions/Notification.action";
import Variant from "../types/Variant.type";
import { loadUserList } from "../actions";
import { ADD_USER_ADMIN } from "../constants/users.constant";
import { MESSAGE_NOTIFICATION_SUCCESS } from "../constants/Notification.constant";

export const userAdminMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    IDeleteUserAdminAction | IAddUserAdminAction | IEditUserAdminAction
  >
) => async (
  action: IDeleteUserAdminAction | IAddUserAdminAction | IEditUserAdminAction
) => {
  const meta = {
    limit: 10,
    offset: 0,
    order: {},
    role: "Admin",
  };
  switch (action.type) {
    case ADD_USER_ADMIN: {
      Axios.post(`/users`, {
        countryId: action.payload.countryId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        password: action.payload.password,
        phone: action.payload.phone,
        address: action.payload.address,
        city: action.payload.city,
        postCode: action.payload.postCode,
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.ADD_ADMIN,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(
            loadUserList({
              limit: 10,
              offset: 0,
              order: {},
              role: "Admin",
            })
          );
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }
    case EDIT_USER_ADMIN: {
      Axios.patch(`/users/${action.payload.id}`, {
        countryId: action.payload.countryId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        password:
          action.payload.password !== "" ? action.payload.password : undefined,
        address: action.payload.address,
        city: action.payload.city,
        postCode: action.payload.postCode,
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.EDIT_ADMIN,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(
            loadUserList({
              limit: 10,
              offset: 0,
              order: {},
              role: "Admin",
            })
          );
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : "Something went wrong. Please try again",
              variant: Variant.ERROR,
            })
          );
        });
      break;
    }

    case DELETE_USER_ADMIN_ACTION:
      Axios.delete(`/users/${action.payload.id}`)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `Delete User Admin Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          store.dispatch(loadUserList(meta));
        })
        .catch((errors: AxiosError) => {
          Promise.reject(errors);
          return errors;
        });
      break;
  }
  return dispatch(action);
};
