import { Store } from "redux";
import { IStoreState } from "../types";
import { Dispatch } from "react";
import {
  ILoadUserAccountListAction,
  ISetUserAccountListAction,
  setUserAccountListAction,
} from "../actions/userAccount.action";
import { LOAD_USER_ACCOUNT_LIST } from "../constants/usersAccount.constant";
import { USER_ACCOUNT_URL } from "../helper/environment";
import { Method, AxiosResponse, AxiosError } from "axios";
import { callApi } from "../helper/callApi";
import { IObjectUserAccountList } from "../types/userAccount.type";

export const getUserAccountList = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ILoadUserAccountListAction | ISetUserAccountListAction>
) => async (action: ILoadUserAccountListAction) => {
  switch (action.type) {
    case LOAD_USER_ACCOUNT_LIST:
      const url = USER_ACCOUNT_URL;
      const method: Method = "get";
      const params = action.payload;
      const payload = { method, url, params };

      callApi(payload)
        .then((response: AxiosResponse<IObjectUserAccountList>) => {
          if (response && response.data) {
            dispatch(setUserAccountListAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
  }
  return dispatch(action);
};
