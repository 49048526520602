import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Dialog,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { NexoInput } from "../../share/NexoInput";
import { NexoUploadFile } from "../../share/NexoUploadFile";
import { NexoButton } from "../../share/NexoButton";
import {
  ICurrency,
  IUpdateCurrencyRequest,
} from "../../../types/currencies.type";
import { updateCurrencyAction } from "../../../actions/currencies.action";
import { useDispatch } from "react-redux";

const styles = makeStyles({
  dialogTitle: {
    padding: 0,
  },
  rootSwitch: {
    margin: "5px 0px",
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: "#050F47",
      lineHeight: "28px",
      width: 150,
    },
  },
  buttonCancel: {
    width: 150,
    marginRight: 7,
  },
  buttonVerify: {
    width: 150,
  },
});
interface IProps {
  showDialog: boolean;
  handleCloseDialog: () => void;
  textHeader: string;
  data: ICurrency;
}

export const UpdateCurrency = (props: IProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { handleCloseDialog, showDialog, textHeader, data } = props;
  const [currencyUpdate, setcurrencyUpdate] = useState<any>({
    id: "",
    name: "",
    ltv: "0",
    interest: "0",
    symbol: "",
    loanDisabled: false,
    loanHidden: false,
    interestDisabled: false,
    interestHidden: false,
    isShow: false,
    image: "",
  });

  const [errors, setErrors] = useState<any>({
    name: false,
    symbol: false,
  });

  const handleUploadFile = (files: any) => {
    setcurrencyUpdate({
      ...currencyUpdate,
      image: files,
    });
  };

  const handleChangeInput = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (key === "ltv" || key === "interest") {
      let number = Number(event.target.value);

      if (number > 100) {
        number = 100;
      }
      if (number < 0) {
        number = 0;
      }

      setcurrencyUpdate({
        ...currencyUpdate,
        [key]: number.toString(),
      });
    } else {
      setcurrencyUpdate({
        ...currencyUpdate,
        [key]: event.target.value,
      });
    }

    if (event.target.value) {
      setErrors({
        ...errors,
        [key]: false,
      });
    } else {
      setErrors({
        ...errors,
        [key]: true,
      });
    }
  };

  const handleChangeSwitch = (key: any) => () => {
    setcurrencyUpdate({
      ...currencyUpdate,
      [key]: !currencyUpdate[key],
    });
  };
  const handleSubmit = () => {
    const request: IUpdateCurrencyRequest = {
      interestDisabled: currencyUpdate.interestDisabled,
      interest: currencyUpdate.interest,
      interestHidden: currencyUpdate.interestHidden,
      isShow: currencyUpdate.isShow,
      loanDisabled: currencyUpdate.loanDisabled,
      loanHidden: currencyUpdate.loanHidden,
      ltv: currencyUpdate.ltv,
      name: currencyUpdate.name,
      symbol: currencyUpdate.symbol,
      image: currencyUpdate.image,
      id: currencyUpdate.id,
    };

    if (request.name && request.symbol) {
      dispatch(updateCurrencyAction(request));
      handleCloseDialog();
    }
    validateRequest(request);
  };

  const validateRequest = (request: IUpdateCurrencyRequest) => {
    if (!request.name && !request.symbol) {
      setErrors({
        ...errors,
        name: true,
        symbol: true,
      });
    } else if (!request.name) {
      setErrors({
        ...errors,
        name: true,
      });
    } else if (!request.symbol) {
      setErrors({
        ...errors,
        symbol: true,
      });
    }
  };

  useEffect(() => {
    setcurrencyUpdate(props.data);
  }, [props.data, showDialog]);

  const disableButtonUpdate: boolean =
    currencyUpdate.name === "" ||
    currencyUpdate.symbol === "" ||
    currencyUpdate.image === ""
      ? true
      : false;

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      maxWidth="lg"
    >
      <Grid className={classes.dialogTitle}>
        <Grid
          container
          justify="center"
          alignContent="center"
          style={{
            height: 80,
            borderBottom: "1px solid #E4EAEE",
            background: "#F9FAFB",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <Typography variant="subtitle1" style={{ color: "#050F47" }}>
            {textHeader}
          </Typography>
        </Grid>
        <Grid style={{ padding: 30 }}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 10 }}>
              <NexoInput
                error={errors.name}
                msgError="Please enter name currency !"
                title="Name"
                type="text"
                value={currencyUpdate.name}
                name="name"
                handleChangeInput={handleChangeInput}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              <NexoInput
                error={errors.symbol}
                msgError="Please enter symbol currency !"
                title="Symbol"
                type="text"
                value={currencyUpdate.symbol}
                name="symbol"
                handleChangeInput={handleChangeInput}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 10 }}>
              <NexoInput
                title="Ltv"
                type="number"
                value={currencyUpdate.ltv}
                name="ltv"
                handleChangeInput={handleChangeInput}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              <NexoInput
                title="Interest"
                type="number"
                value={currencyUpdate.interest}
                name="interest"
                handleChangeInput={handleChangeInput}
              />
            </Grid>
          </Grid>
          <Grid container style={{ height: 230 }}>
            <Grid container alignItems="flex-end" item xs={6}>
              <NexoUploadFile
                maxFiles={1}
                handleUploadFile={handleUploadFile}
                fileName={data.image}
              />
            </Grid>
            <Grid container justify="flex-end" item xs={6}>
              <FormControlLabel
                className={classes.rootSwitch}
                style={{ marginLeft: 0 }}
                onClick={handleChangeSwitch("loanDisabled")}
                control={<Switch checked={currencyUpdate.loanDisabled} />}
                label="Loan Disabled"
                labelPlacement="start"
              />
              <FormControlLabel
                className={classes.rootSwitch}
                style={{ marginLeft: 0 }}
                onClick={handleChangeSwitch("loanHidden")}
                control={<Switch checked={currencyUpdate.loanHidden} />}
                label="Loan Hidden"
                labelPlacement="start"
              />
              <FormControlLabel
                className={classes.rootSwitch}
                style={{ marginLeft: 0 }}
                onClick={handleChangeSwitch("interestDisabled")}
                control={<Switch checked={currencyUpdate.interestDisabled} />}
                label="Interest Disabled"
                labelPlacement="start"
              />
              <FormControlLabel
                className={classes.rootSwitch}
                style={{ marginLeft: 0 }}
                onClick={handleChangeSwitch("interestHidden")}
                control={<Switch checked={currencyUpdate.interestHidden} />}
                label="Interest Hidden"
                labelPlacement="start"
              />
              <FormControlLabel
                className={classes.rootSwitch}
                onClick={handleChangeSwitch("isShow")}
                style={{ marginLeft: 0 }}
                control={<Switch checked={currencyUpdate.isShow} />}
                label="Is Shown"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ marginTop: 50 }}>
            <Grid className={classes.buttonCancel}>
              <NexoButton
                title="Cancel"
                variant="outlined"
                color="primary"
                heightButton={40}
                handleRedirect={handleCloseDialog}
              />
            </Grid>

            <Grid className={classes.buttonVerify}>
              <NexoButton
                title="Update"
                variant="contained"
                color="primary"
                heightButton={40}
                handleRedirect={handleSubmit}
                disabled={disableButtonUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
