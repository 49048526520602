import {
  IObjectListWithdrawLoan,
  IOjectListWithdrawAsset,
} from "../types/withdraw.type";
import {
  ISetWithdrawLoanAction,
  ISetWithdrawAssetAction,
  ISetWithdrawSavingAction,
} from "../actions/withdraw.action";
import {
  SET_WITHDRAW_LOAN_ACTION,
  SET_WITHDRAW_ASSET_ACTION,
  SET_WITHDRAW_SAVING_ACTION,
} from "../constants/withdraw.constant";

export const emptyWithdrawLoan: IObjectListWithdrawLoan = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};
const emptyWithdrawAsset: IOjectListWithdrawAsset = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};

export const listWithdrawLoans = (
  state: IObjectListWithdrawLoan = emptyWithdrawLoan,
  action: ISetWithdrawLoanAction
): IObjectListWithdrawLoan => {
  if (action.type === SET_WITHDRAW_LOAN_ACTION) {
    return action.payload;
  }
  return state;
};

export const listWithdrawAssets = (
  state: IOjectListWithdrawAsset = emptyWithdrawAsset,
  action: ISetWithdrawAssetAction
): IOjectListWithdrawAsset => {
  if (action.type === SET_WITHDRAW_ASSET_ACTION) {
    return action.payload;
  }
  return state;
};

export const listWithdrawSavings = (
  state: IOjectListWithdrawAsset = emptyWithdrawAsset,
  action: ISetWithdrawSavingAction
): IOjectListWithdrawAsset => {
  if (action.type === SET_WITHDRAW_SAVING_ACTION) {
    return action.payload;
  }
  return state;
};
