import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const styles = makeStyles({
  root: {
    padding: 0,
    width: "100%",
    "& .MuiButton-root": {
      borderRadius: 5,
      width: "100%",
      height: 50,
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 600,
      color: "#fff",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
    },
  },
  contained: {
    backgroundColor: "#F2994A",
    "&:hover": {
      backgroundColor: "#F2994A",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#DA771F",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#DA771F",
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#fff",
      background: "#C4C4C4",
      boxShadow: "none",
    },
  },
  outlined: {
    border: "1px solid #2577E4",
    color: "#2577E4",
    "&:hover": {
      background: "#30324f",
      boxShadow: "none",
    },
    "&:focus": {
      background: "#30324f",
      boxShadow: "none",
    },
    "&:active": {
      background: "#30324f",
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#fff",
      background: "#C4C4C4",
      boxShadow: "none",
    },
  },
  containedPrimary: {
    background: "#30324f",
    boxShadow: "none",
    "&:hover": {
      background: "#30324f",
      boxShadow: "none",
    },
    "&:focus": {
      background: "#30324f",
      boxShadow: "none",
    },
    "&:active": {
      background: "#30324f",
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#fff",
      background: "#C4C4C4",
      boxShadow: "none",
    },
  },
  containedSecondary: {
    backgroundColor: "#34CEA9",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#34CEA9",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#1BB791",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#1BB791",
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#fff",
      background: "#C4C4C4",
      boxShadow: "none",
    },
  },
  containedInherit: {
    backgroundColor: "#bb1717bd",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#bb1717bd",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#bb1717bd",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#bb1717bd",
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#fff",
      background: "#C4C4C4",
      boxShadow: "none",
    },
  },
  outlinedPrimary: {
    color: "#050F47 !important",
    border: "1px solid #D7DCE0",
    "&:hover": {
      color: "#FFFFFF !important",
      background: "#050F47",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#282B2F",
      color: "#FFFFFF !important",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#282B2F",
      color: "#FFFFFF !important",
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#fff",
      background: "#C4C4C4",
      boxShadow: "none",
      border: "transparent",
    },
  },
});

interface IProps {
  title: string;
  variant: "contained" | "outlined";
  color?: "primary" | "secondary" | "inherit";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  heightButton?: number;
  handleRedirect?: () => void;
}

export const NexoButton = (props: IProps) => {
  const classes = styles();
  const getClassNameForButton = () => {
    const { variant, color } = props;
    let className = classes.contained;
    switch (color) {
      case "primary":
        className =
          variant === "contained"
            ? classes.containedPrimary
            : classes.outlinedPrimary;
        break;
      case "secondary":
        className = classes.containedSecondary;
        break;
      case "inherit":
        className = classes.containedInherit;
        break;
      default:
        className =
          variant === "contained" ? classes.contained : classes.outlined;
        break;
    }

    return className;
  };

  const {
    title,
    variant,
    color,
    size,
    disabled,
    handleRedirect,
    heightButton,
  } = props;
  return (
    <div className={classes.root}>
      <Button
        className={getClassNameForButton()}
        variant={variant}
        color={color}
        size={size}
        style={{ textTransform: "none", height: heightButton }}
        disabled={disabled}
        onClick={handleRedirect}
      >
        {title}
      </Button>
    </div>
  );
};
