import { IObjectListUser } from "../types/user.type";
import { ISetUserAdminListAction } from "../actions/userAdmin.action";
import { SET_LIST_USER_ADMIN_ACTION } from "../constants/usersAdmin.constant";

const initialUser: IObjectListUser = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};

export const userAdminList = (
  state: IObjectListUser = initialUser,
  action: ISetUserAdminListAction
): IObjectListUser => {
  switch (action.type) {
    case SET_LIST_USER_ADMIN_ACTION:
      return action.payload;

    default:
      return state;
  }
};
