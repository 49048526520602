import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Grid,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../types";
import Pagination from "../components/share/Pagination";
import { ILoadUserAccountListRequest } from "../types/userAccount.type";
import { loadUserAccountList } from "../actions/userAccount.action";
import { formatNumberToFixed } from "../helper";
import { ProfileCard } from "../components/ProfileCard";
import { RootTable } from "../components/RootTable";
import { CardNoDataTable } from "../components/share/CardNoDataTable";
import { OrderByTableHeader } from "../components/share/OrderByTableHeader";
import iconRedirect from "../assets/images/iconRedirect.svg";
import { PATH } from "../constants/PATH";
import { useHistory } from "react-router";
import { PAGE_USER_DETAIL } from "../constants/users.constant";
import { DVT_USD } from "../constants/transaction.constant";
import Search from "@material-ui/icons/Search";
import { debounce } from "lodash";

const styles = makeStyles({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  paddingTableHead: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  rootInputSeach: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "#9FA2B4",
    },
    "& .MuiInputBase-input": {
      color: "#9FA2B4",
      fontSize: 15,
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "20px",
      fontStyle: "italic",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.09) !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.09) !important",
    },
  },
});

export const UserAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = styles();
  const [meta, setMeta] = useState<ILoadUserAccountListRequest>({
    limit: 10,
    offset: 0,
    order: {},
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["Accounts"];

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };
  const userAccountList = useSelector(
    (state: IStoreState) => state.UserAccountList
  );

  const handleChangePage = (event: any, page: number) => {
    setMeta({
      ...meta,
      limit: rowsPerPage,
      offset: page * userAccountList.meta.limit,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMeta({
      ...meta,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    });
  };

  useEffect(() => {
    dispatch(loadUserAccountList(meta));
  }, [meta, dispatch]);

  const handleChangeArrow = (
    typeTable: string,
    orderBy: string | undefined
  ) => {
    let newOrder = meta.order;
    if (newOrder) {
      newOrder[typeTable] = orderBy;
    }
    setMeta({
      ...meta,
      order: newOrder,
    });
  };
  const debounceSearchAction = debounce((search) => {
    setMeta({
      ...meta,
      search,
      offset: 0,
    });
  }, 500);

  const handleChaneInputSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    debounceSearchAction(event.target.value);

  const handleChildWithdrawLoanInfo = (id: string) => () => {
    history.push(PATH.user.replace(":id", id), {
      idWithdraw: id,
      type: PAGE_USER_DETAIL.PROFILE,
    });
  };
  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <RootTable
        nameTable="All Accounts"
        ActionsComponent={
          <Grid container justify="flex-end" alignItems="center">
            <Grid
              style={{ width: 300, marginRight: 10 }}
              className={classes.rootInputSeach}
            >
              <TextField
                placeholder="Search here..."
                onChange={handleChaneInputSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        }
      >
        <Table>
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: "1%",
                }}
              >
                {"#"}
              </TableCell>
              <TableCell
                style={{
                  width: "12%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Email"
                  type="email"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Credit Line"
                  type="credit_line"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "12%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Available Credit"
                  type="available_credit"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "12%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Ltv"
                  type="ltv"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "10%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Loan"
                  type="loan"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "10%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Interest"
                  type="interest"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "10%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Saving"
                  type="saving"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "12%",
                  paddingLeft: 0,
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Saving Interest"
                  type="saving_interest"
                  justifyTableHeader="flex-end"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: "6%",
                  paddingRight: 16,
                  paddingLeft: 0,
                }}
                className={classes.tableHead}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userAccountList.data && userAccountList.data.length ? (
              userAccountList.data.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableCell}>
                      <TableCell className={classes.tableBody}>
                        {index +
                          1 +
                          userAccountList.meta.limit *
                            (userAccountList.meta.currentPage - 1)}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.email || "-"}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.credit_line
                          ? formatNumberToFixed(el.credit_line, 2) + DVT_USD
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.available_credit
                          ? formatNumberToFixed(el.available_credit, 2) +
                            DVT_USD
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.ltv ? formatNumberToFixed(el.ltv, 2) + " %" : "-"}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.loan
                          ? formatNumberToFixed(el.loan, 2) + DVT_USD
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.interest
                          ? formatNumberToFixed(el.interest, 2) + DVT_USD
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.saving
                          ? formatNumberToFixed(el.saving, 2) + " USDT"
                          : "-"}
                      </TableCell>
                      <TableCell
                        className={classes.tableBody}
                        style={{ paddingRight: 16 }}
                        align="right"
                      >
                        {el.saving_interest
                          ? formatNumberToFixed(el.saving_interest, 2) + " USDT"
                          : "-"}
                      </TableCell>
                      <TableCell
                        className={classes.tableBody}
                        style={{
                          paddingRight: 16,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        align="right"
                      >
                        <IconButton
                          onClick={handleChildWithdrawLoanInfo(el.id)}
                          style={{
                            width: 30,
                            height: 30,
                            padding: 0,
                          }}
                        >
                          <Tooltip title="Redirect" aria-label="redirect">
                            <img
                              src={iconRedirect}
                              alt="icon"
                              width={15}
                              height={15}
                            />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <CardNoDataTable colSpan={10} />
            )}
          </TableBody>
        </Table>
        <Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={userAccountList.meta ? userAccountList.meta.total : 0}
            rowsPerPage={rowsPerPage}
            page={
              userAccountList.meta && userAccountList.meta.currentPage
                ? userAccountList.meta.currentPage - 1
                : 0
            }
            onChangePage={handleChangePage}
            ActionsComponent={Pagination}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </RootTable>
    </ProfileCard>
  );
};
