import { Action } from "redux";
import {
  LOAD_DASHBOARD_DATA,
  SET_DASHBOARD_DATA,
} from "../constants/dashboard.constants";
import { IObjectDashboard } from "../types/dashboard.type";

export interface ILoadDashboardDataAction extends Action<LOAD_DASHBOARD_DATA> {}

export const loadDashboardData = (): ILoadDashboardDataAction => ({
  type: LOAD_DASHBOARD_DATA,
});

export interface ISetDashboardDataAction extends Action<SET_DASHBOARD_DATA> {
  payload: IObjectDashboard;
}

export const setDashboardData = (
  dashboardData: IObjectDashboard
): ISetDashboardDataAction => ({
  type: SET_DASHBOARD_DATA,
  payload: dashboardData,
});
