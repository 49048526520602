import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Typography } from "@material-ui/core";
import classNames from "classnames";
import errorIcon from "../../assets/images/shape.svg";

const styles = makeStyles({
  root: {
    width: "100%",
  },
  inputNormal: {
    border: "1px solid #bbbdc0",
    height: 50,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: "normal",
    fontFamily: "Montserrat",
    "&:hover": {
      border: "1px solid #34CEA9",
    },
    "& .MuiInputAdornment-root": {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      backgroundColor: "#F9F9FB",
      minWidth: 100,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      margin: "0 auto",
      padding: "10px 0px",
      borderRight: "1px solid #D7DCE0",
      height: 50,
      fontWeight: 600,
      paddingLeft: 10,
    },
    "& .MuiInput-input": {
      backgroundColor: "#fff",
      height: 46,
      padding: "0px 10px",
      borderRadius: "0px 5px 5px 0px",
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
      color: "#050F47",
    },
  },
  inputError: {
    border: "1px solid #EB5757 !important",
  },
  errorGroup: {
    marginTop: 10,
    fontSize: 12,
    color: "#E3001E",
    fontFamily: "Montserrat",
  },
  errorIcon: {
    marginRight: 10,
    width: 15.11,
    height: 13.33,
  },
  inputDisabled: {
    backgroundColor: "#F9F9FB",
    "&:hover": {
      border: "1px solid #D7DCE0",
    },
    "& .MuiInput-input": {
      backgroundColor: "#F9F9FB !important",
    },
  },
  img: {
    paddingRight: 12,
    paddingLeft: 12,
    cursor: "pointer",
  },
  dvtInput: {
    fontWeight: 500,
    fontSize: 12,
    paddingRight: 10,
    fontFamily: "Montserrat",
    width: 40,
    textAlign: "right",
    color: "#bdbdbd",
  },
});

interface IProps {
  type: string;
  error?: boolean;
  msgError?: string;
  disabled?: boolean;
  value?: unknown;
  name?: string;
  dvt?: string;
  index?: number;
  handleChangeInput?: (
    key?: string | undefined,
    index?: number | undefined
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NexoInputConfig = (props: IProps) => {
  const classes = styles();

  const showIconError = () => {
    const { error } = props;
    if (error) {
      return (
        <img className={classes.errorIcon} src={errorIcon} alt="errorIcon" />
      );
    }
    return;
  };

  const getTypeForInput = (dvt: string | undefined) => {
    switch (dvt) {
      case "year":
        return <Typography className={classes.dvtInput}>%/year</Typography>;
      case "dvt":
        return (
          <Typography className={classes.dvtInput} style={{ width: 10 }}>
            %
          </Typography>
        );

      default:
        return null;
    }
  };

  const { type, error, msgError, disabled, value, dvt } = props;

  return (
    <div className={classes.root}>
      <Input
        className={
          error
            ? classNames(classes.inputError, classes.inputNormal)
            : disabled
            ? classNames(classes.inputDisabled, classes.inputNormal)
            : classes.inputNormal
        }
        value={value ? value : ""}
        disableUnderline={true}
        endAdornment={getTypeForInput(dvt)}
        inputProps={{
          min: 0,
          max: 100,
        }}
        type={type}
        error={error}
        disabled={disabled}
        onChange={
          props.handleChangeInput &&
          props.handleChangeInput(props.name, props?.index)
        }
      />
      {error ? (
        <Typography className={classes.errorGroup}>
          {showIconError()}
          {msgError}
        </Typography>
      ) : null}
    </div>
  );
};
