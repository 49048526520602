import { Action } from "redux";
import {
  SET_USER_APPROVE_LIST,
  lOAD_USER_APPROVE_LIST,
  VERIFICATION_ADVANCED_USER_APPROVE,
  VERIFICATION_REFUSE_USER_APPROVE,
} from "../constants/userApprove.constant";
import { IObjectListUser, ILoadUserListRequest } from "../types/user.type";

export interface ILoadUserApproveListAction
  extends Action<lOAD_USER_APPROVE_LIST> {
  payload: ILoadUserListRequest;
}

export const loadUserApproveList = (
  request: ILoadUserListRequest
): ILoadUserApproveListAction => ({
  type: lOAD_USER_APPROVE_LIST,
  payload: request,
});

export interface ISetUserApproveListAction
  extends Action<SET_USER_APPROVE_LIST> {
  payload: IObjectListUser;
}

export function setUserApproveListAction(
  userManagement: IObjectListUser
): ISetUserApproveListAction {
  return {
    type: SET_USER_APPROVE_LIST,
    payload: userManagement,
  };
}

export interface IVerificationAdvancedUserAction
  extends Action<VERIFICATION_ADVANCED_USER_APPROVE> {
  payload: string;
}

export const verificationAdvancedUser = (
  request: string
): IVerificationAdvancedUserAction => ({
  type: VERIFICATION_ADVANCED_USER_APPROVE,
  payload: request,
});

export interface IVerificationRefuseUserAction
  extends Action<VERIFICATION_REFUSE_USER_APPROVE> {
  payload: string;
}

export const verificationRefuseUser = (
  request: string
): IVerificationRefuseUserAction => ({
  type: VERIFICATION_REFUSE_USER_APPROVE,
  payload: request,
});
