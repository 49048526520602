import { ISelectCategory } from "../types/activityLogs.type";

export const LOAD_ACTIVITY_LOGS_LIST = "LOAD_ACTIVITY_LOGS_LIST";
export type LOAD_ACTIVITY_LOGS_LIST = typeof LOAD_ACTIVITY_LOGS_LIST;

export const SET_ACTIVITY_LOGS_LIST = "SET_ACTIVITY_LOGS_LIST";
export type SET_ACTIVITY_LOGS_LIST = typeof SET_ACTIVITY_LOGS_LIST;

export const DataCategoryActivityLog: ISelectCategory[] = [
  {
    title: "Authentication",
  },
  {
    title: "Verification",
  },
  {
    title: "Withdrawal",
  },
  {
    title: "Daily Interest",
  },
  {
    title: "User Profile",
  },
  {
    title: "Admin Profile",
  },
  {
    title: "System configuration",
  },
  {
    title: "Inbound Transaction",
  },
  {
    title: "Loan Risk",
  },
  {
    title: "Cold Wallet Transfer",
  },
];
