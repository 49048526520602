import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  createStore,
  combineReducers,
  applyMiddleware,
  DeepPartial,
  compose,
} from "redux";
import { Provider } from "react-redux";
import { createBrowserHistory, History } from "history";
import theme from "./styles/theme.styles";
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter,
} from "connected-react-router";
import { IStoreState } from "./types";
import { ThemeProvider } from "@material-ui/styles";
import { initialAxiosInterceptorAction } from "./actions/auth.action";
import * as reducers from "./reducers";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

interface IWindowReduxDevTool extends Window {
  __REDUX_DEVTOOLS_EXTENSION__(): DeepPartial<any>;
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

declare var window: IWindowReduxDevTool;

export const historyBrowser = createBrowserHistory();

// tslint:disable-next-line
const middlewares = require("./middlewares");
const middleware = routerMiddleware(historyBrowser);
const midws = Object.keys(middlewares).map((key) => middlewares[key]);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createReducers = (history: History<any>) =>
  combineReducers<IStoreState>({
    ...reducers,
    router: connectRouter(history),
  });

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["notification,userManagement"],
};

const persistedReducer = persistReducer(
  persistConfig,
  createReducers(historyBrowser)
);
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(middleware, ...midws))
);

const persistor = persistStore(store);

// const store = createStore(
//   createReducers(historyBrowser),
//   composeEnhancers(applyMiddleware(middleware, ...midws))
// );
store.dispatch(initialAxiosInterceptorAction());

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={historyBrowser}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
