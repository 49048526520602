import { Action } from "redux";
import {
  INIT_AXIOS_INTERCEPTOR,
  LOGIN,
  LOGOUT,
  SET_USER_PROFILE,
  LOAD_USER_PROFILE,
} from "../constants/auth.constant";
import { ILoginRequest } from "../types/auth.type";
import { IUserInfo } from "../types/user.type";

export interface IInitialAxiosInterceptorAction
  extends Action<INIT_AXIOS_INTERCEPTOR> {}
export const initialAxiosInterceptorAction = (): IInitialAxiosInterceptorAction => ({
  type: INIT_AXIOS_INTERCEPTOR,
});
export interface ILoginAction extends Action<LOGIN> {
  payload: ILoginRequest;
}

export const loginAction = (request: ILoginRequest): ILoginAction => ({
  type: LOGIN,
  payload: request,
});

export interface ILogoutAction extends Action<LOGOUT> {}

export const logoutAction = (): ILogoutAction => ({
  type: LOGOUT,
});

export interface ILoadUserProfileAction extends Action<LOAD_USER_PROFILE> {}

export const loadUserProfile = (): ILoadUserProfileAction => ({
  type: LOAD_USER_PROFILE,
});

export interface ISetUserProfileAction extends Action<SET_USER_PROFILE> {
  payload: IUserInfo;
}

export function setUserProfileAction(
  userManagement: IUserInfo
): ISetUserProfileAction {
  return {
    type: SET_USER_PROFILE,
    payload: userManagement,
  };
}
