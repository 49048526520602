export const lOAD_USER_APPROVE_LIST = "lOAD_USER_APPROVE_LIST";
export type lOAD_USER_APPROVE_LIST = typeof lOAD_USER_APPROVE_LIST;

export const SET_USER_APPROVE_LIST = "SET_USER_APPROVE_LIST";
export type SET_USER_APPROVE_LIST = typeof SET_USER_APPROVE_LIST;

export const VERIFICATION_ADVANCED_USER_APPROVE =
  "VERIFICATION_ADVANCED_USER_APPROVE";
export type VERIFICATION_ADVANCED_USER_APPROVE = typeof VERIFICATION_ADVANCED_USER_APPROVE;

export const VERIFICATION_REFUSE_USER_APPROVE =
  "VERIFICATION_REFUSE_USER_APPROVE";
export type VERIFICATION_REFUSE_USER_APPROVE = typeof VERIFICATION_REFUSE_USER_APPROVE;
