import React from "react";
import { Tooltip } from "@material-ui/core";

interface IProps {
  title: string;
  srcImage: string;
}

export const StatusTransaction = (props: IProps) => {
  const { title, srcImage } = props;
  return (
    <Tooltip title={title} aria-label="add">
      <img style={{ cursor: "pointer" }} src={srcImage} alt="" />
    </Tooltip>
  );
};
