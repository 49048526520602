import { ISetCountriesAction } from "../actions/country.action";
import ICountry from "../types/country";
import { SET_COUNTRIES_ACTION } from "../constants/country";

export const countries = (
  state: ICountry[] = [],
  action: ISetCountriesAction
): ICountry[] => {
  if (action.type === SET_COUNTRIES_ACTION) {
    return action.payload;
  }
  return state;
};
