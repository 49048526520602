import { IUserInfo } from "../types/user.type";
import { initialProfileUser } from "./user.reducer";
import { ISetUserProfileAction } from "../actions/auth.action";
import { SET_USER_PROFILE } from "../constants/auth.constant";

export const userProfile = (
  state: IUserInfo = initialProfileUser,
  action: ISetUserProfileAction
): IUserInfo => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return action.payload;

    default:
      return state;
  }
};
