import React from "react";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopy from "@material-ui/icons/FileCopy";

const styles = makeStyles({
  iconCopy: {
    "& .MuiSvgIcon-root": {
      width: 15,
      height: 15,
    },
  },
});

interface IProps {
  text: string;
  id: string;
  copyDepositAddress: (id: string) => void;
  activeIconCopy: string[];
}

export const CopyDepositAddress = (props: IProps) => {
  const { id, text, copyDepositAddress, activeIconCopy } = props;
  const classes = styles();

  return (
    <CopyToClipboard text={text} onCopy={copyDepositAddress}>
      <IconButton
        style={{
          borderRadius: 5,
          color:
            activeIconCopy[0] === id
              ? "rgba(0, 0, 0, 0.54)"
              : "rgba(0, 0, 0, 0.17)",
          padding: 5,
        }}
        className={classes.iconCopy}
      >
        <Tooltip title="Copy" aria-label="add">
          <FileCopy />
        </Tooltip>
      </IconButton>
    </CopyToClipboard>
  );
};
