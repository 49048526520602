import React from "react";
import Grid from "@material-ui/core/Grid";
import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NexoButton } from "../share/NexoButton";
import { ItemUser } from "../users/ItemUser";
import { useSelector, useDispatch } from "react-redux";
import { IStoreState } from "../../types";
import { selectCountry } from "../../helper";
import { EstimateWithdrawCard } from "../users/EstimateWithdrawCard";
import { IWithdrawIntemInfo } from "../users/Withdrawal";
import { IWithdrawRequest } from "../../types/withdraw.type";
import {
  approveWithdrawAction,
  deniedWithdrawAction,
} from "../../actions/withdraw.action";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "10px",
      width: "560px",
      maxWidth: 600,
      height: "auto",
    },
  },

  buttonVerify: {
    width: 150,
  },
  buttonCancel: {
    width: 150,
    marginRight: 7,
  },
}));

interface IProps {
  showDialog: boolean;
  handleClose: (showDialog: boolean) => void;
  withdrawIntemInfo: IWithdrawIntemInfo;
}

export const BankAccountUser = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countries = useSelector((state: IStoreState) => state.countries);
  const availableCredit: string = useSelector(
    (state: IStoreState) => state.wallets.availableCredit
  );

  const { showDialog, handleClose, withdrawIntemInfo } = props;

  const handleApprove = () => {
    const reuquest: IWithdrawRequest = {
      id: withdrawIntemInfo.id,
      idUser: withdrawIntemInfo.userId,
    };
    setTimeout(() => {
      dispatch(approveWithdrawAction(reuquest));
      handleClose(!showDialog);
    }, 500);
  };
  const handleAdminDeniedItem = () => {
    const reuquest: IWithdrawRequest = {
      id: withdrawIntemInfo.id,
      idUser: withdrawIntemInfo.userId,
    };
    setTimeout(() => {
      dispatch(deniedWithdrawAction(reuquest));
      handleClose(!showDialog);
    }, 500);
  };
  return (
    <Dialog open={showDialog} onClose={handleClose} className={classes.dialog}>
      <Grid
        container
        style={{
          background: "#F9FAFB",
          borderBottom: "1px solid #D7DCE0",
          height: 60,
        }}
        alignItems="center"
        justify="center"
      >
        <Typography variant="h4" style={{ fontSize: 20, fontWeight: 600 }}>
          Withdrawal Loan
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <Grid container style={{ marginBottom: 20 }}>
          <EstimateWithdrawCard
            availableCredit={Number(availableCredit)}
            valueUsd={withdrawIntemInfo.amount}
          />
        </Grid>

        {withdrawIntemInfo.symbol === "USD" ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={6} style={{ paddingRight: 20 }}>
                <ItemUser
                  title="Recipient name"
                  value={withdrawIntemInfo.userBankAccount?.name || ""}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 20 }}>
                <ItemUser
                  title="Transfer Type"
                  value={withdrawIntemInfo.userBankAccount?.transferType || ""}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <Grid item xs={6} style={{ paddingRight: 20 }}>
                <ItemUser
                  title="Recipient type"
                  value={withdrawIntemInfo.userBankAccount?.recipientType || ""}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 20 }}>
                <ItemUser
                  title="SWIFT code"
                  value={withdrawIntemInfo.userBankAccount?.swiftCode || ""}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <Grid item xs={6} style={{ paddingRight: 20 }}>
                <ItemUser
                  title="Account number"
                  value={withdrawIntemInfo.userBankAccount?.accountNumber || ""}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 20 }}>
                <ItemUser
                  title="Country"
                  value={selectCountry(
                    withdrawIntemInfo.userBankAccount?.countryId || "",
                    countries
                  )}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <Grid item xs={6} style={{ paddingRight: 20 }}>
                <ItemUser
                  title="City"
                  value={withdrawIntemInfo.userBankAccount?.city || ""}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 20 }}>
                <ItemUser
                  title="Postal code"
                  value={withdrawIntemInfo.userBankAccount?.postalCode || ""}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <ItemUser
            title="Recipient name"
            value={withdrawIntemInfo.recipientAddress || ""}
          />
        )}
      </Grid>

      {withdrawIntemInfo.adminApprovedItem === false &&
      withdrawIntemInfo.adminDeniedItem === false ? (
        <Grid container={true}>
          <Grid container justify="center" style={{ marginBottom: 30 }}>
            <Grid className={classes.buttonCancel}>
              <NexoButton
                title="Refuse"
                variant="outlined"
                color="primary"
                heightButton={40}
                handleRedirect={handleAdminDeniedItem}
              />
            </Grid>

            <Grid className={classes.buttonVerify}>
              <NexoButton
                handleRedirect={handleApprove}
                title="Approve"
                variant="contained"
                color="primary"
                heightButton={40}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Dialog>
  );
};
