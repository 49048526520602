export const SET_CURRENCY_LIST = "SET_CURRENCY_LIST";
export type SET_CURRENCY_LIST = typeof SET_CURRENCY_LIST;

export const LOAD_CURRENCY_LIST = "LOAD_CURRENCY_LIST";
export type LOAD_CURRENCY_LIST = typeof LOAD_CURRENCY_LIST;

export const CREATE_CURRENCY_ACTION = "CREATE_CURRENCY_ACTION";
export type CREATE_CURRENCY_ACTION = typeof CREATE_CURRENCY_ACTION;

export const DELETE_CURRENCY_ACTION = "DELETE_CURRENCY_ACTION";
export type DELETE_CURRENCY_ACTION = typeof DELETE_CURRENCY_ACTION;

export const UPDATE_CURRENCY_ACTION = "UPDATE_CURRENCY_ACTION";
export type UPDATE_CURRENCY_ACTION = typeof UPDATE_CURRENCY_ACTION;
