import { IStoreState } from "../types";
import {
  ILoadConfigListAction,
  ISetConfigListAction,
  setConfigList,
  IUpdateConfigAction,
  IAddConfigAction,
  IDeleteConfigAction,
} from "../actions";
import { Store } from "redux";
import { Dispatch } from "react";
import {
  LOAD_CONFIG_LIST,
  UPDATE_CONFIG,
  ADD_CONFIG,
  DELETE_CONFIG,
} from "../constants/config.constants";
import { CONFIG_URL } from "../helper/environment";
import { Method, AxiosError, AxiosResponse } from "axios";
import { callApi } from "../helper/callApi";
import { IObjectConfig } from "../types/config.type";
import { fireNotification } from "../actions/Notification.action";
import Variant from "../types/Variant.type";
import { PATH } from "../constants/PATH";

export const getConfigList = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadConfigListAction
    | ISetConfigListAction
    | IUpdateConfigAction
    | IAddConfigAction
    | IDeleteConfigAction
  >
) => async (
  action:
    | ILoadConfigListAction
    | IUpdateConfigAction
    | IAddConfigAction
    | IDeleteConfigAction
) => {
  let url = "";
  let method: Method = "get";
  let data = {};

  const loadDataConfigList = () => {
    url = CONFIG_URL;
    method = "get";
    const payload = { url, method };

    callApi(payload)
      .then((response: AxiosResponse<IObjectConfig>) => {
        if (response && response.data) {
          console.log(123);
          dispatch(setConfigList(response.data));
        }
      })
      .catch((errors: AxiosError) => {
        Promise.reject(errors);
        return errors;
      });
  };

  switch (action.type) {
    case LOAD_CONFIG_LIST:
      url = CONFIG_URL;
      method = "get";
      const payload = { url, method };

      await callApi(payload)
        .then((response: AxiosResponse<IObjectConfig>) => {
          if (response && response.data) {
            dispatch(setConfigList(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          Promise.reject(errors);
          return errors;
        });
      break;
    case UPDATE_CONFIG:
      url = `${CONFIG_URL}/${action.payload.id}`;
      method = "patch";
      data = {
        value: action.payload.value || undefined,
        type: action.payload.type || undefined,
      };

      await callApi({ url, method, data })
        .then((response: AxiosResponse<IObjectConfig>) => {
          store.dispatch(
            fireNotification({
              message: `Update ${action.payload.typeNoti} Successfully`,
              variant: Variant.SUCCESS,
              path: PATH.settings,
            })
          );
          loadDataConfigList();
          return response;
        })
        .catch((errors: AxiosError) => {
          store.dispatch(
            fireNotification({
              message:
                errors.response && errors.response.data.error.message
                  ? errors.response.data.error.message
                  : "error",
              variant: Variant.ERROR,
            })
          );
          Promise.reject(errors);
          loadDataConfigList();
        });
      break;
    case ADD_CONFIG:
      url = CONFIG_URL;
      method = "post";
      data = {
        name: action.payload.name || undefined,
        type: action.payload.type || undefined,
      };
      await callApi({ url, method, data })
        .then((response: AxiosResponse<IObjectConfig>) => {
          store.dispatch(
            fireNotification({
              message: `Add ${action.payload.typeNoti} Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          loadDataConfigList();
          return response;
        })
        .catch((errors: AxiosError) => {
          store.dispatch(
            fireNotification({
              message:
                errors.response && errors.response.data.error.message
                  ? errors.response.data.error.message
                  : "error",
              variant: Variant.ERROR,
            })
          );
          Promise.reject(errors);
        });
      break;
    case DELETE_CONFIG:
      url = `${CONFIG_URL}/${action.payload.id}`;
      method = "delete";

      await callApi({ url, method })
        .then((response: AxiosResponse<IObjectConfig>) => {
          store.dispatch(
            fireNotification({
              message: `Delete ${action.payload.typeNoti} Successfully`,
              variant: Variant.SUCCESS,
            })
          );
          loadDataConfigList();
          return response;
        })
        .catch((errors: AxiosError) => {
          store.dispatch(
            fireNotification({
              message:
                errors.response && errors.response.data.error.message
                  ? errors.response.data.error.message
                  : "error",
              variant: Variant.ERROR,
            })
          );
          Promise.reject(errors);
        });
      break;
  }
  return dispatch(action);
};
