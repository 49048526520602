import { IObjectTransactionList } from "../types/transaction.type";
import { ISetTransactionList } from "../actions/transaction.action";
import { SET_TRANSACTION_LIST } from "../constants/transaction.constant";

const initialTransaction: IObjectTransactionList = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};
export const TransactionList = (
  state: IObjectTransactionList = initialTransaction,
  action: ISetTransactionList
): IObjectTransactionList => {
  switch (action.type) {
    case SET_TRANSACTION_LIST:
      return action.payload;

    default:
      return state;
  }
};
