import { ISelectItemInfo } from "../types/dashboard.type";

export const LOAD_DASHBOARD_DATA = "LOAD_DASHBOARD_DATA";
export type LOAD_DASHBOARD_DATA = typeof LOAD_DASHBOARD_DATA;

export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export type SET_DASHBOARD_DATA = typeof SET_DASHBOARD_DATA;

export const DataType: ISelectItemInfo[] = [
  {
    id: "0",
    title: "Deposit",
  },
  {
    id: "1",
    title: "Repayment",
  },
];

export const itemsDeposit = [
  { name: "BTC Deposit", background: "#8067dc" },
  { name: "ETH Deposit", background: "#c767dc" },
  { name: "XRP Deposit", background: "#67b7dc" },
];

export const itemsRepayment = [
  { name: "BTC Repayment", background: "#8067dc" },
  { name: "ETH Repayment", background: "#c767dc" },
  { name: "XRP Repayment", background: "#67b7dc" },
  { name: "USDT Repayment", background: "#6794dc" },
];

export const MONTH_NAME = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "OCtober",
  "November",
  "December",
];

export const MONTH_KEY = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
