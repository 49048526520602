import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import axios from "axios";
import classNames from "classnames";
import errorIcon from "../../assets/images/shape.svg";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
      width: "100%",
    },
    content: {
      border: "1px solid #D7DCE0",
      height: 40,
      borderRadius: 5,
      fontSize: 14,
      fontWeight: "normal",
      fontFamily: "Montserrat",
      "&:hover": {
        border: "1px solid #34CEA9",
      },
      display: "flex",
      flexDirection: "row",
    },
    leftPart: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F9F9FB",
      minWidth: 100,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderRight: "1px solid #D7DCE0",
      height: 40,
      fontWeight: 600,
    },
    rightPart: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F9F9FB",
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      height: 40,
      fontWeight: 600,
      "& #phone-form-control": {
        borderStyle: "none",
        height: 40,
        width: "100%",
      },
      "& #flag-dropdown": {
        borderStyle: "none",
        backgroundColor: "#fff",
      },
      "& .react-tel-input .form-control": {
        width: "100%",
        height: 40,
        border: "transparent",
      },
      "& .react-tel-input .flag-dropdown": {
        backgroundColor: "transparent",
        border: "transparent",
      },
    },
    contentError: {
      border: "1px solid #EB5757 !important",
    },
    errorGroup: {
      marginTop: 10,
      fontSize: 12,
      color: "#E3001E",
      fontFamily: "Montserrat",
    },
    errorIcon: {
      marginRight: 10,
      width: 15.11,
      height: 13.33,
    },
    img: {
      paddingRight: 12,
      paddingLeft: 12,
      cursor: "pointer",
    },
  });

interface IProps extends WithStyles<typeof styles> {
  title: string;
  value: string;
  error?: boolean;
  msgError?: string;
  handleChangeInputVerifyPhone: (phone: string) => void;
}

interface IState {
  phone: string;
  country: string;
}

class NexoPhoneInputComponent extends React.Component<IProps, IState> {
  public state: IState = {
    phone: "",
    country: "us",
  };
  // tslint:disable-next-line
  // UNSAFE_componentWillMount() {
  //   this.getGeoIP();
  // }

  protected setCountryCodeForUser = (response: any) => {
    // this.setState({
    //   country: response.data ? response.data.country_code.toLowerCase() : 'us'
    // });
  };

  protected getGeoIP = () => {
    axios
      .get("https://geoip-db.com/json/geoip.php?json=?")
      .then((response) => this.setCountryCodeForUser(response))
      .catch((error) => console.log(error));
  };

  public showIconError = () => {
    const { error } = this.props;
    if (error) {
      return (
        <img
          className={this.props.classes.errorIcon}
          src={errorIcon}
          alt="errorIcon"
        />
      );
    }
    return;
  };

  public render() {
    const { classes, title, error, msgError, value } = this.props;
    const { country } = this.state;

    return (
      <div className={classes.root}>
        <div
          className={
            error
              ? classNames(classes.content, classes.contentError)
              : classes.content
          }
        >
          <div className={classes.leftPart}>
            <Typography variant="h4">{title}</Typography>
          </div>

          <div className={classes.rightPart}>
            <PhoneInput
              country={country}
              value={value}
              onChange={this.props.handleChangeInputVerifyPhone}
            />
          </div>
        </div>
        <Typography className={classes.errorGroup}>
          {this.showIconError()}
          {msgError}
        </Typography>
      </div>
    );
  }
}

export const NexoPhoneInput = withStyles(styles)(NexoPhoneInputComponent);
