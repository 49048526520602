import { IObjectConfig } from "../types/config.type";
import { ISetConfigListAction } from "../actions";
import { SET_CONFIG_LIST } from "../constants/config.constants";

const initialConfig: IObjectConfig = {
  data: [
    {
      id: "",
      name: "",
      type: "",
      value: "",
    },
  ],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};

export const configList = (
  state: IObjectConfig = initialConfig,
  action: ISetConfigListAction
) => {
  switch (action.type) {
    case SET_CONFIG_LIST:
      return action.payload;

    default:
      return state;
  }
};
