import { IObjectListUser } from "../types/user.type";
import { ISetUserApproveListAction } from "../actions/userApprove.action";
import { SET_USER_APPROVE_LIST } from "../constants/userApprove.constant";

const emptyListUsers: IObjectListUser = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};

export const UserApproveList = (
  state: IObjectListUser = emptyListUsers,
  action: ISetUserApproveListAction
): IObjectListUser => {
  switch (action.type) {
    case SET_USER_APPROVE_LIST:
      return action.payload;
    default:
      return state;
  }
};
