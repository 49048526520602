import React, { useState, useEffect } from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import iconLogo from "../../assets/images/iconLogo.svg";
import iconUser from "../../assets/images/iconUser.svg";
import iconUserAccount from "../../assets/images/iconUserAccount.svg";
import iconTransaction from "../../assets/images/iconTransaction.svg";
import iconSetting from "../../assets/images/iconSetting.svg";
import iconCurrency from "../../assets/images/iconCurrency.svg";
import iconWithdrawAsset from "../../assets/images/iconWithdrawAsset.svg";
import iconActivity from "../../assets/images/iconActivity.svg";

import iconUserAction from "../../assets/images/iconUserAction.svg";
import iconTransactionAction from "../../assets/images/iconTransactionAction.svg";
import iconWithdrawAssetAction from "../../assets/images/iconWithdrawAssetAction.svg";
import iconUserAccountAction from "../../assets/images/iconUserAccountAction.svg";
import iconCurrencyAction from "../../assets/images/iconCurrencyAction.svg";
import iconSettingAction from "../../assets/images/iconSettingAction.svg";
import iconActivityAction from "../../assets/images/iconActivityAction.svg";
import iconDashboard from "../../assets/images/iconDashboard.svg";
import iconDashboardAction from "../../assets/images/iconDashboardAction.svg";

import { PATH } from "../../constants/PATH";
import classNames from "classnames";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { loadCountriesAction } from "../../actions/country.action";
import Scrollbars from "react-custom-scrollbars";
import { loadUserProfile } from "../../actions/auth.action";
const styles = makeStyles({
  logo: {
    marginTop: 45,
    marginBottom: 45,
  },
  itemMenu: {
    height: 50,
    cursor: "pointer",
    paddingLeft: 35,
    margin: "10px 0px",

    "&:hover": {
      background: "#9fa2b42e",
      "&:after": {
        content: "''",
        left: 0,
        backgroundColor: "#FFFFFF",
        position: "absolute",
        width: 3,
        height: 50,
      },
    },
  },
  itemMenuAction: {
    height: 50,
    margin: "10px 0px",
    cursor: "pointer",
    background: "#9fa2b42e",
    paddingLeft: 35,
    "&:after": {
      content: "''",
      left: 0,
      backgroundColor: "#FFFFFF",
      position: "absolute",
      width: 3,
      height: 50,
    },
  },
  colorItemMenu: {
    color: "#A4A6B3",

    "&:hover": {
      color: "#FFFFFF",
    },
  },
  colorItemMenuAction: {
    color: "#FFFFFF",
  },
});

const listMenu = [
  {
    id: 0,
    text: "Dashboard",
    link: PATH.dashboards,
    icon: iconDashboard,
    iconAction: iconDashboardAction,
    symbol: "dashboard",
  },
  {
    id: 1,
    text: "Users",
    link: PATH.users,
    icon: iconUser,
    iconAction: iconUserAction,
    symbol: "user",
  },
  {
    id: 2,
    text: "Accounts",
    link: PATH.userAccount,
    icon: iconUserAccount,
    iconAction: iconUserAccountAction,
    symbol: "account",
  },
  {
    id: 3,
    text: "Transactions",
    link: PATH.transactions,
    icon: iconTransaction,
    iconAction: iconTransactionAction,
    symbol: "transaction",
  },
  {
    id: 4,
    text: "Withdrawals",
    link: PATH.withdraws,
    icon: iconWithdrawAsset,
    iconAction: iconWithdrawAssetAction,
    symbol: "withdraws",
  },
  {
    id: 5,
    text: "Currency",
    link: PATH.currencies,
    icon: iconCurrency,
    iconAction: iconCurrencyAction,
    symbol: "currency",
  },
  {
    id: 6,
    text: "Activity Log",
    link: PATH.activityLogs,
    icon: iconActivity,
    iconAction: iconActivityAction,
    symbol: "activityLog",
  },
  // {
  //   id: 7,
  //   text: "Setting",
  //   link: PATH.settings,
  //   icon: iconSetting,
  //   iconAction: iconSettingAction,
  //   symbol: "setting",
  // },
];

export const MenuLeft = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const history = useHistory();
  const classes = styles();
  const [mouseOver, setMouseOver] = useState<any>({
    user: false,
    transaction: false,
    withdraws: false,
    currency: false,
    account: false,
    logout: false,
    activityLog: false,
    setting: false,
    dashboard: false,
  });
  const handleOnMouseOver = (key: string) => () => {
    selectIcon(mouseOver, key);
  };

  const selectIcon = function (mouseOver: any, key: string) {
    Object.keys(mouseOver).forEach(function (el) {
      let data = {
        user: false,
        transaction: false,
        withdraws: false,
        currency: false,
        account: false,
        logout: false,
        setting: false,
        activityLog: false,
        dashboard: false,
      };
      if (el === key) {
        setMouseOver({
          ...data,
          [key]: true,
        });
      }
    });
  };
  useEffect(() => {
    dispatch(loadCountriesAction());
  }, [dispatch]);
  useEffect(() => {
    if (token) {
      dispatch(loadUserProfile());
    }
  }, [dispatch, token]);

  const handleOnMouseLeave = (key: string) => () => {
    setMouseOver({
      ...mouseOver,
      [key]: false,
    });
  };
  const redirectTo = (path: string) => (event: any) => {
    if (path === PATH.users || path === PATH.withdraws) {
      history.push({
        pathname: path,
        state: 0,
      });
    } else {
      history.push(path);
    }
  };
  return (
    <Grid data-aos="fade-right" data-aos-offset="300" data-aos-duration="300">
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.logo}
      >
        <img src={iconLogo} alt="logo" />
      </Grid>

      <Grid>
        <Scrollbars
          style={{
            overflow: "hidden",
            width: "100%",
            height: "calc(100vh - 180px)",
          }}
        >
          <Grid>
            {listMenu.map((el) => (
              <Grid
                key={el.id}
                container
                onMouseEnter={handleOnMouseOver(el.symbol)}
                onMouseLeave={handleOnMouseLeave(el.symbol)}
                alignItems="center"
                className={classNames(
                  el.link === history.location.pathname
                    ? classes.itemMenuAction
                    : classes.itemMenu
                )}
                onClick={redirectTo(el.link)}
              >
                <img
                  src={
                    mouseOver[el.symbol] ||
                    el.link === history.location.pathname
                      ? el.iconAction
                      : el.icon
                  }
                  alt="icon"
                  width={25}
                  height={25}
                  style={{ marginRight: 20 }}
                />
                <Typography
                  variant="h4"
                  className={
                    mouseOver[el.symbol] ||
                    el.link === history.location.pathname
                      ? classes.colorItemMenuAction
                      : classes.colorItemMenu
                  }
                >
                  {el.text}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid
            style={{
              borderBottom: "1px solid rgba(223, 224, 235, 0.15)",
              marginTop: 20,
              marginBottom: 20,
            }}
          ></Grid>
          <Grid
            container
            onMouseEnter={handleOnMouseOver("setting")}
            onMouseLeave={handleOnMouseLeave("setting")}
            alignItems="center"
            className={classNames(
              PATH.settings === history.location.pathname
                ? classes.itemMenuAction
                : classes.itemMenu
            )}
            onClick={redirectTo(PATH.settings)}
          >
            <img
              src={
                mouseOver["setting"] ||
                PATH.settings === history.location.pathname
                  ? iconSettingAction
                  : iconSetting
              }
              alt="icon"
              width={25}
              height={25}
              style={{ marginRight: 20 }}
            />
            <Typography
              variant="h4"
              className={
                mouseOver["setting"] ||
                PATH.settings === history.location.pathname
                  ? classes.colorItemMenuAction
                  : classes.colorItemMenu
              }
            >
              Settings
            </Typography>
          </Grid>
        </Scrollbars>
      </Grid>
    </Grid>
  );
};
