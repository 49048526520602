import React, { useState, useEffect } from "react";
import {
  formatDateTime,
  formatMonthDateYear,
  formatNumberToFixed,
  formatAddress,
} from "../helper";
import {
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { NexoAutocomplete } from "../components/share/NexoAutocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DataAllTypes, DataAllAsset } from "../constants/transaction.constant";
import { ILoadTransactionListRequest } from "../types/meta.type";
import { useDispatch, useSelector } from "react-redux";
import { loadTransactionListAction } from "../actions/transaction.action";
import { ISelectInfo } from "../types/transaction.type";
import { IStoreState } from "../types";
import Pagination from "../components/share/Pagination";
import iconRejected from "../assets/images/refuse.svg";
import iconSuccesful from "../assets/images/approve.svg";
import iconProcessing from "../assets/images/pending.svg";
import { ProfileCard } from "../components/ProfileCard";
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from "../constants/transaction.constant";
import { CardNoDataTable } from "../components/share/CardNoDataTable";
import { OrderByTableHeader } from "../components/share/OrderByTableHeader";
import { CopyDepositAddress } from "../components/share/CopyDepositAddress";
import { StatusTransaction } from "../components/share/StatusTransaction";

const styles = makeStyles({
  rootKeyboardDate: {
    marginTop: 0,
    marginBottom: 0,
    width: 180,
    height: 40,
    "& .MuiInputBase-input": {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "12px",
      color: "#A5B2C0",
    },
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "12px",
      color: "#050F47",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #34CEA9",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CEA9",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  autocomplete: {
    height: 40,
    marginLeft: 20,
    width: 180,
  },
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  rootTable: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DFE0EB",
    boxSizing: "border-box",
    borderRadius: 8,
  },

  textAmountIn: {
    color: "#45BB83",
  },
  textAmountOut: {
    color: "#C61615",
  },
  paddingTableHead: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const Transactions = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dateTo, setDateTo] = useState<any>(null);
  const [meta, setMeta] = useState<ILoadTransactionListRequest>({
    limit: 10,
    offset: 0,
    asset: "ALL",
    type: "ALL",
    order: {},
  });
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["Transactions"];
  const [activeIconCopy, setActiveIconCopy] = useState<string[]>([]);

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };
  const transactionList = useSelector(
    (state: IStoreState) => state.TransactionList
  );

  const handleChangePage = (event: any, page: number) => {
    setMeta({
      ...meta,
      limit: rowsPerPage,
      offset: page * transactionList.meta.limit,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMeta({
      ...meta,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    });
  };

  const handleChangeDate = (type: string) => (date: Date | null) => {
    const newDate = formatMonthDateYear(date);
    if (date) {
      if (!isNaN(date.getTime())) {
        if (type === "from") {
          setDateFrom(newDate);
        } else {
          setDateTo(newDate);
        }
        setMeta({
          ...meta,
          offset: 0,
          [type]: newDate,
        });
      }
    }

    if (date === null) {
      if (type === "from") {
        setDateFrom(null);
      } else {
        setDateTo(null);
      }

      setMeta({
        ...meta,
        offset: 0,
        [type]: undefined,
      });
    }
  };

  const handleChangeType = (type: string) => (
    event: any,
    value: ISelectInfo | null
  ) => {
    setMeta({
      ...meta,
      offset: 0,
      [type]: value ? value.symbol : "ALL",
    });
  };

  useEffect(() => {
    dispatch(loadTransactionListAction(meta));
  }, [meta, dispatch]);

  const renderColorAmountTransaction = (
    type: string,
    amount: string,
    symbol: string
  ) => {
    let colorAmount: boolean = false;
    let textAmount: string = "-";
    if (
      type === TRANSACTION_TYPE.DEPOSIT ||
      type === TRANSACTION_TYPE.REPAYMENT ||
      type === TRANSACTION_TYPE.SAVING ||
      type === TRANSACTION_TYPE.SAVING_INTEREST
    ) {
      colorAmount = true;
      textAmount = "+";
    } else {
      colorAmount = false;
      textAmount = "-";
    }
    return (
      <span
        className={
          colorAmount === true ? classes.textAmountIn : classes.textAmountOut
        }
      >
        {`${textAmount} ${formatNumberToFixed(
          amount,
          symbol === "USD" || symbol === "USDT" ? 4 : 8
        )} ${symbol}`}
      </span>
    );
  };
  // const renderIconTransactionTypeClosed = (closed: boolean, type: string) => {
  //   if (
  //     closed === true &&
  //     (type === TRANSACTION_TYPE.SAVING ||
  //       type === TRANSACTION_TYPE.SAVING_INTEREST)
  //   ) {
  //     return (
  //       <Typography
  //         variant="h5"
  //         style={{
  //           background: "#EB5757",
  //           borderRadius: 4,
  //           padding: "1px 2px",
  //           color: "#FFFFFF",
  //           fontSize: 10,
  //           marginLeft: 10,
  //         }}
  //       >
  //         Closed
  //       </Typography>
  //     );
  //   }
  // };
  const handleChangeArrow = (
    typeTable: string,
    orderBy: string | undefined
  ) => {
    let newOrder = meta.order;
    if (newOrder) {
      newOrder[typeTable] = orderBy;
    }
    setMeta({
      ...meta,
      order: newOrder,
    });
  };

  const copyDepositAddress = (id: string) => () => {
    let checkActiveCopy = [];
    checkActiveCopy.push(id);
    setActiveIconCopy(checkActiveCopy);
  };

  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <Grid className={classes.rootTable}>
        <Grid
          container
          alignItems="center"
          style={{
            marginTop: 30,
            marginBottom: 10,
            display: "grid",
            gridTemplateColumns: "250px calc(100% - 250px)",
          }}
        >
          <Typography variant="h3" style={{ fontSize: 20, marginLeft: 16 }}>
            All Transactions
          </Typography>
          <Grid
            container
            justify="flex-end"
            style={{ height: 40, paddingRight: 16 }}
          >
            <Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  className={classes.rootKeyboardDate}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  autoOk
                  label="Start"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="MM-DD-YYYY"
                  value={dateFrom}
                  inputVariant="outlined"
                  onChange={handleChangeDate("from")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  className={classes.rootKeyboardDate}
                  style={{ marginLeft: 20 }}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  autoOk
                  label="End"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="MM-DD-YYYY"
                  value={dateTo}
                  onChange={handleChangeDate("to")}
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid className={classes.autocomplete}>
              <NexoAutocomplete
                content=""
                placeholderInput="All Types"
                dataSelect={DataAllTypes}
                onChangeType={handleChangeType("type")}
              />
            </Grid>
            <Grid className={classes.autocomplete}>
              <NexoAutocomplete
                content=""
                placeholderInput="All Asset"
                dataSelect={DataAllAsset}
                onChangeType={handleChangeType("asset")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Table>
          <TableHead>
            <TableRow style={{ width: "100%" }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: "1%",
                }}
              >
                {"#"}
              </TableCell>
              <TableCell
                style={{
                  width: "14%",
                }}
                className={classes.tableHead}
              >
                Name
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Receiver"
                  type="to"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
              <TableCell
                style={{
                  width: "20%",
                }}
                className={classes.tableHead}
              >
                Type
              </TableCell>
              <TableCell
                style={{
                  width: "20%",
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Amount"
                  type="value"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>

              <TableCell
                align="center"
                style={{
                  width: "10%",
                }}
                className={classes.tableHead}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  width: "20%",
                  paddingRight: 16,
                }}
                className={classes.paddingTableHead}
              >
                <OrderByTableHeader
                  title="Date/time"
                  type="createdAt"
                  justifyTableHeader="flex-end"
                  handleChangeArrow={handleChangeArrow}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionList.data.length ? (
              transactionList.data.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableCell}>
                      <TableCell className={classes.tableBody}>
                        {index +
                          transactionList.meta.limit *
                            (transactionList.meta.currentPage - 1) +
                          1}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.user.firstName ? el.user.firstName + " " : ""}
                        {el.user.lastName ? el.user.lastName : ""}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {el.to ? formatAddress(el.to) : "-"}
                        {el.to ? (
                          <CopyDepositAddress
                            id={el.id}
                            text={el.to}
                            copyDepositAddress={copyDepositAddress(el.id)}
                            activeIconCopy={activeIconCopy}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        <Grid container alignItems="center">
                          {el.transactionType}
                          {/* {renderIconTransactionTypeClosed(
                            el.closed,
                            el.transactionType
                          )} */}
                        </Grid>
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        {renderColorAmountTransaction(
                          el.transactionType,
                          el.value,
                          el.currency.symbol
                        )}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        className={classes.tableBody}
                      >
                        {el.status === TRANSACTION_STATUS.successful ? (
                          <StatusTransaction
                            title="Apporved"
                            srcImage={iconSuccesful}
                          />
                        ) : null}
                        {el.status === TRANSACTION_STATUS.rejected ? (
                          <StatusTransaction
                            title="Reject"
                            srcImage={iconRejected}
                          />
                        ) : null}
                        {el.status === TRANSACTION_STATUS.processing ? (
                          <StatusTransaction
                            title="Pending"
                            srcImage={iconProcessing}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        style={{ paddingRight: 16 }}
                        className={classes.tableBody}
                        align="right"
                      >
                        {el.createdAt ? formatDateTime(el.createdAt) : ""}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <CardNoDataTable colSpan={9} />
            )}
          </TableBody>
        </Table>

        <Grid style={{ width: "100%" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={transactionList.meta.total ? transactionList.meta.total : 0}
            rowsPerPage={rowsPerPage}
            page={
              transactionList.meta.currentPage
                ? transactionList.meta.currentPage - 1
                : 0
            }
            onChangePage={handleChangePage}
            ActionsComponent={Pagination}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </ProfileCard>
  );
};
