import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  updateConfigAction,
  addConfigAction,
  deleteConfigAction,
} from "../../actions";
import { NexoInputConfig } from "../share/NexoInputConfig";
import { NexoButton } from "../share/NexoButton";
import { IConfig } from "../../types/config.type";
import { DataDefault, CONFIG_TYPE } from "../../constants/config.constants";
import IconAdd from "../../assets/images/add.svg";
import { ConfirmDialog } from "../dialog/Confirm.dialog";

const styles = makeStyles({
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#050F47",
  },
  card: {
    background: "#FFFFFF",
    border: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderRadius: 5,
  },
  item: {
    background: " #F9FAFB",
    borderBottom: "1px solid #D7DCE0",
    boxSizing: "border-box",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: 60,
    paddingLeft: 16,
    paddingRight: 16,
  },
});

interface IProps {
  dataPaymentType: IConfig[];
  dataDisable: boolean[];
}

export const PaymentTypeConfig = (props: IProps) => {
  const { dataPaymentType, dataDisable } = props;
  const dispatch = useDispatch();
  const classes = styles();
  const [disableAddPaymentType, setDisableAddPaymentType] = useState<Boolean>(
    false
  );
  const [paymentTypeAdd, setPaymentTypeAdd] = useState<IConfig>(DataDefault);
  const [paymentTypeId, setPaymentTypeId] = useState("");
  const [paymentType, setPaymentType] = useState<IConfig[]>([]);
  const [, setRender] = useState(0);

  const [disableButton, setDisableButton] = useState<boolean[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [indexItem, setIndexItem] = useState(-1);

  const checkDisable = (index: number, value: string) => {
    const newDisableButton = disableButton;
    if (value === "") {
      newDisableButton[index] = true;
    } else {
      newDisableButton[index] = false;
    }
  };

  const handleSetDisableAddTransferType = (type: string) => () => {
    setDisableAddPaymentType(true);
  };

  const handlleDeleteConfig = () => {
    if (paymentTypeId !== "") {
      dispatch(
        deleteConfigAction({ id: paymentTypeId, typeNoti: "Payment Type" })
      );
      setOpenConfirmDialog(!openConfirmDialog);
    }
  };

  const handleOpenConfirmDialog = (id: string) => () => {
    setPaymentTypeId(id);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const forceUpdate = () => {
    setRender((render) => ++render); // update the state to force render
  };

  const handleChangeInput = (
    type: string | undefined,
    index: number | undefined
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (type) {
      case CONFIG_TYPE.PAYMENT_TYPE:
        if (index !== undefined) {
          setIndexItem(index);
          checkDisable(index, event.target.value);
          let newPaymentType = paymentType;
          newPaymentType[index].type = event.target.value;

          setPaymentType(newPaymentType);
          forceUpdate();
        }
        break;
      case CONFIG_TYPE.PAYMENT_TYPE_ADD:
        setIndexItem(-1);
        setPaymentTypeAdd({
          ...paymentTypeAdd,
          type: event.target.value,
        });
        break;
    }
  };

  const handeSave = (index?: number) => () => {
    let dataUpdate = {
      id: "",
      value: 0,
      type: "",
      typeNoti: "",
    };

    if (index !== undefined) {
      dataUpdate.id = paymentType[index].id;
      dataUpdate.type = paymentType[index].type;
      dataUpdate.typeNoti = "Payment Type";
    }

    if (dataUpdate.id !== "") {
      dispatch(updateConfigAction(dataUpdate));
      if (index !== undefined) {
        setDisableButton({ ...disableButton, [index]: true });
      }
      forceUpdate();
    }
  };

  const handleAddConfig = (type: string) => () => {
    let dataAdd = { type: "", name: "", typeNoti: "Payment Type" };
    dataAdd.type = paymentTypeAdd.type;
    dataAdd.name = "RECIPIENT_TYPE";
    setDisableAddPaymentType(false);
    setPaymentTypeAdd(DataDefault);
    dispatch(addConfigAction(dataAdd));
  };

  const handleWhenClickDelete = () => {
    setDisableAddPaymentType(false);
  };

  useEffect(() => {
    setPaymentType(dataPaymentType);
    setDisableButton(dataDisable);
  }, [dataPaymentType, dataDisable]);

  return (
    <Grid container className={classes.card}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.item}
      >
        <Typography style={{ fontSize: 16 }} className={classes.title}>
          Payment type
        </Typography>
        <img
          src={IconAdd}
          alt="Add"
          onClick={handleSetDisableAddTransferType("Payment Type")}
        />
      </Grid>
      {disableAddPaymentType ? (
        <Grid container style={{ padding: 16 }}>
          <Grid item xs={7}>
            <NexoInputConfig
              type="text"
              value={paymentTypeAdd.type}
              name="Payment Type Add"
              handleChangeInput={handleChangeInput}
              error={paymentTypeAdd.type === "" ? true : false}
              msgError="Please enter payment type!"
            />
          </Grid>
          <Grid container item xs={5} style={{ paddingLeft: 50 }}>
            <Grid
              style={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "49% 49%",
                columnGap: "2%",
              }}
            >
              <NexoButton
                heightButton={40}
                title="Save"
                variant="contained"
                color="primary"
                handleRedirect={handleAddConfig(CONFIG_TYPE.PAYMENT_TYPE)}
                disabled={paymentTypeAdd.type === "" ? true : false}
              />
              <NexoButton
                heightButton={40}
                title="Delete"
                variant="contained"
                color="inherit"
                handleRedirect={handleWhenClickDelete}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {paymentType.length > 0
        ? paymentType.map((el, index) => {
            return (
              <Grid container style={{ padding: 16 }} key={index}>
                <Grid item xs={7}>
                  <NexoInputConfig
                    type="text"
                    value={paymentType[index].type}
                    name="Payment Type"
                    index={index}
                    handleChangeInput={handleChangeInput}
                    error={paymentType[index].type === "" ? true : false}
                    msgError="Please enter type!"
                    disabled={
                      el.type !== "Person"
                        ? false
                        : el.type === "Person" && indexItem === index
                        ? false
                        : true
                    }
                  />
                </Grid>
                <Grid container item xs={5} style={{ paddingLeft: 50 }}>
                  {el.type !== "Person" ? (
                    <Grid
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "49% 49%",
                        columnGap: "2%",
                      }}
                    >
                      <NexoButton
                        heightButton={40}
                        title="Save"
                        variant="contained"
                        color="primary"
                        handleRedirect={handeSave(index)}
                        disabled={disableButton[index]}
                      />
                      <NexoButton
                        heightButton={40}
                        title="delete"
                        variant="contained"
                        color="inherit"
                        handleRedirect={handleOpenConfirmDialog(el.id)}
                      />
                    </Grid>
                  ) : el.type === "Person" &&
                    indexItem === index &&
                    disableButton[index] === false ? (
                    <Grid
                      style={{
                        display: "grid",
                        width: "100%",
                        gridTemplateColumns: "49% 49%",
                        columnGap: "2%",
                      }}
                    >
                      <NexoButton
                        heightButton={40}
                        title="Save"
                        variant="contained"
                        color="primary"
                        handleRedirect={handeSave(index)}
                        disabled={disableButton[index]}
                      />
                      <NexoButton
                        heightButton={40}
                        title="delete"
                        variant="contained"
                        color="inherit"
                        handleRedirect={handleOpenConfirmDialog(el.id)}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            );
          })
        : null}
      <ConfirmDialog
        showDialog={openConfirmDialog}
        handleClose={setOpenConfirmDialog}
        message="Please confirm you want to delete?"
        type="PAYMENT TYPE"
        handleSubmit={handlleDeleteConfig}
      />
    </Grid>
  );
};
