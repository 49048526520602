import { Action } from "redux";
import {
  LOAD_USER_ACCOUNT_LIST,
  SET_USER_ACCOUNT_LIST,
} from "../constants/usersAccount.constant";
import {
  ILoadUserAccountListRequest,
  IObjectUserAccountList,
} from "../types/userAccount.type";

export interface ILoadUserAccountListAction
  extends Action<LOAD_USER_ACCOUNT_LIST> {
  payload: ILoadUserAccountListRequest;
}

export const loadUserAccountList = (
  request: ILoadUserAccountListRequest
): ILoadUserAccountListAction => ({
  type: LOAD_USER_ACCOUNT_LIST,
  payload: request,
});

export interface ISetUserAccountListAction
  extends Action<SET_USER_ACCOUNT_LIST> {
  payload: IObjectUserAccountList;
}

export const setUserAccountListAction = (
  userAccountList: IObjectUserAccountList
): ISetUserAccountListAction => ({
  type: SET_USER_ACCOUNT_LIST,
  payload: userAccountList,
});
