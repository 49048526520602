import ICountry from "../types/country";
import { Action } from "redux";
import {
  LOAD_COUNTRIES_ACTION,
  SET_COUNTRIES_ACTION,
} from "../constants/country";

export interface ILoadCountriesAction extends Action<LOAD_COUNTRIES_ACTION> {}

export const loadCountriesAction = (): ILoadCountriesAction => ({
  type: LOAD_COUNTRIES_ACTION,
});

export interface ISetCountriesAction extends Action<SET_COUNTRIES_ACTION> {
  payload: ICountry[];
}

export const setCountriesAction = (
  request: ICountry[]
): ISetCountriesAction => ({
  type: SET_COUNTRIES_ACTION,
  payload: request,
});
