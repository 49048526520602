import { ISelectInfo } from "../types/transaction.type";

export const SET_TRANSACTION_LIST = "SET_TRANSACTION_LIST";
export type SET_TRANSACTION_LIST = typeof SET_TRANSACTION_LIST;

export const LOAD_TRANSACTION_LIST = "LOAD_TRANSACTION_LIST";
export type LOAD_TRANSACTION_LIST = typeof LOAD_TRANSACTION_LIST;

export const DVT_USD = " $";

export enum TRANSACTION_STATUS {
  processing = "0",
  successful = "1",
  rejected = "2",
}

export enum TRANSACTION_TYPE {
  ALL = "ALL",
  DEPOSIT = "Deposit",
  REPAYMENT = "Repayment",
  ASSET_WITHDRAWAL = "Asset Withdrawal",
  LOAN_WITHDRAWAL = "Loan Withdrawal",
  EARNED_INTEREST = "Earned Interest",
  LOAN_INTEREST = "Loan Interest",
  AUTO_LOAN_REPAYMENT = "Partial Automatic Loan Repayment",
  ACCOUNTING = "Accounting",
  DIVIDEND = "Dividend",
  GATHERING_ASSET = "Gathering Asset",
  SAVING = "Saving",
  SAVING_INTEREST = "Saving Interest",
  SAVING_WITHDRAWAL = "Saving Withdrawal",
  SAVING_INTEREST_WITHDRAWAL = "Saving Interest Withdrawal",
}
export enum SYMBOL_DECIMAL {
  USD = 2,
  USDT = 2,
  XRP = 6,
}

export const DataAllTypes: ISelectInfo[] = [
  {
    id: "0",
    title: "All Type ",
    symbol: "ALL",
  },
  {
    id: "1",
    title: "Deposit",
    symbol: "Deposit",
  },
  {
    id: "2",
    title: "Asset",
    symbol: "Asset",
  },
  {
    id: "3",
    title: "Loan",
    symbol: "Loan",
  },
  {
    id: "4",
    title: "Repayment",
    symbol: "Repayment",
  },
  {
    id: "5",
    title: "Saving",
    symbol: "Saving",
  },
];
export const DataAllTypesUserWithdrawal: ISelectInfo[] = [
  {
    id: "0",
    title: "All Type ",
    symbol: "ALL",
  },
  {
    id: "1",
    title: "Loan Withdrawal",
    symbol: "Loan Withdrawal",
  },
  {
    id: "2",
    title: "Asset Withdrawal",
    symbol: "Asset Withdrawal",
  },
  {
    id: "3",
    title: "Saving Withdrawal",
    symbol: "Saving Withdrawal",
  },
];

export const DataAllAsset: ISelectInfo[] = [
  {
    id: "0",
    title: "All Asset",
    symbol: "ALL",
  },
  {
    id: "1",
    title: "(BTC) Bitcoin",
    symbol: "BTC",
  },
  {
    id: "2",
    title: "(ETH) Ether",
    symbol: "ETH",
  },
  {
    id: "5",
    title: "(USD) Dolar",
    symbol: "USD",
  },
  {
    id: "6",
    title: "(USDT) Tether",
    symbol: "USDT",
  },
  {
    id: "8",
    title: "(XRP) XRP",
    symbol: "XRP",
  },
];
