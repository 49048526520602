import React, { useState, useEffect } from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";
import { IUserInfo } from "../types/user.type";
import { History } from "history";
import { useDispatch, useSelector } from "react-redux";

import { ProfileCard } from "../components/ProfileCard";
import "react-image-lightbox/style.css";
import { renderContentVerification, formatNumberToFixed } from "../helper";
import { MenuUser } from "../components/share/MenuUser";
import { loadWalletUserAction, loadUserDetailAction } from "../actions";
import { ProfileUser } from "../components/users/ProfileUser";
import { Wallet } from "../components/users/Wallet";
import { IStoreState } from "../types";
import { Withdrawal } from "../components/users/Withdrawal";
import { PAGE_USER_DETAIL } from "../constants/users.constant";
import { TransactionUser } from "../components/users/TransactionUser";
import { RouteChildrenProps, useHistory } from "react-router";
import { ActivityLogsUser } from "../components/users/ActivityLogUser";

export interface IParams {
  detailUser: IUserInfo;
  numberPage: number;
  idUser: string;
}

interface IProps {
  history: History<IParams>;
}
export const UserDetail = (props: RouteChildrenProps<{ id: string }>) => {
  const dispatch = useDispatch();
  const history = useHistory<{
    type: number;
    idWithdraw: string;
  }>();
  const id: string | undefined = props.match?.params.id;
  const wallets = useSelector((state: IStoreState) => state.wallets);
  const profileUser = useSelector((state: IStoreState) => state.profileUser);

  useEffect(() => {
    if (id) {
      dispatch(loadWalletUserAction(id));
      dispatch(loadUserDetailAction(id));
    }
  }, [dispatch, id]);

  //Create State
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["User Detail"];
  const [valueMenu, setValueMenu] = useState<number>(0);
  const menuheader = [
    "Profile",
    "Wallet",
    "Withdrawals",
    "Transaction",
    "Activity Log",
  ];

  useEffect(() => {
    if (history.location.state.type) {
      setValueMenu(history.location.state.type);
    }
  }, [history.location.state]);

  const handleChangeMenuHeader = (event: React.ChangeEvent<{}>, value: any) => {
    setValueMenu(value);
  };
  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };

  const renderPage = () => {
    switch (valueMenu) {
      case PAGE_USER_DETAIL.WALLET:
        return <Wallet wallets={wallets} />;
      case PAGE_USER_DETAIL.WITHDRAW:
        return (
          <Withdrawal
            idWithdraw={history.location.state?.idWithdraw || ""}
            id={id ? id : ""}
          />
        );
      case PAGE_USER_DETAIL.TRANSACTION:
        return <TransactionUser idUser={id ? id : ""} />;
      case PAGE_USER_DETAIL.ACTIVITY_LOG:
        return <ActivityLogsUser idUser={id ? id : ""} />;
      default:
        return <ProfileUser profileUser={profileUser} />;
    }
  };

  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <Grid
        style={{
          background: "#FFFFFF",
          border: "1px solid #D7DCE0",
          boxSizing: "border-box",
          borderRadius: 5,
        }}
      >
        <Grid
          container
          alignItems="center"
          style={{
            height: 100,
            display: "grid",
            paddingLeft: 16,
            gridTemplateColumns: "350px calc(100% - 350px)",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              display: "grid",
              gridTemplateColumns: "50px calc(100% - 50px)",
              borderRight: "1px dashed #B2B6BA",
            }}
          >
            <Avatar />
            <Grid container direction="column">
              <Grid container alignItems="center">
                <Typography variant="h4" style={{ fontSize: 20 }}>
                  {`${profileUser.firstName ? profileUser.firstName : ""} ${
                    profileUser.lastName ? profileUser.lastName : ""
                  }`}{" "}
                </Typography>
                {profileUser.verification1 !== undefined &&
                profileUser.verification2 !== undefined ? (
                  <span
                    style={{
                      backgroundColor: "#49d786",
                      padding: "5px 10px",
                      borderRadius: 10,
                      color: "#FFFFFF",
                      fontSize: 12,
                      fontFamily: "Montserrat",
                      textTransform: "capitalize",
                      fontWeight: 500,
                      marginLeft: 10,
                    }}
                  >
                    {renderContentVerification(
                      profileUser.verification1,
                      profileUser.verification2
                    )}
                  </span>
                ) : null}
              </Grid>

              <Typography
                variant="h4"
                style={{ color: "rgba(5, 15, 71, 0.31)", fontSize: 12 }}
              >
                Email: {profileUser.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "grid",
              paddingLeft: 16,
              paddingRight: 16,
              gridTemplateColumns: "33% 33% 32%",
              columnGap: "1%",
              height: "100%",
            }}
            alignItems="center"
          >
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              style={{
                borderRadius: 5,
                height: 56,
                background:
                  "linear-gradient(136.04deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(127.73deg, #67ACF1 -10.95%, #156EE4 104.81%)",
              }}
            >
              <Typography
                variant="h4"
                style={{ fontSize: 22, color: "#FFFFFF" }}
              >
                {`${formatNumberToFixed(
                  wallets.availableCredit ? wallets.availableCredit : 0,
                  2
                )} $`}
              </Typography>
              <Typography
                variant="h4"
                style={{
                  fontSize: 10,
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                Available Credit
              </Typography>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              style={{
                borderRadius: 5,
                height: 56,
                background:
                  "linear-gradient(136.04deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(127.73deg, #67ACF1 -10.95%, #156EE4 104.81%)",
              }}
            >
              <Typography
                variant="h4"
                style={{ fontSize: 22, color: "#FFFFFF" }}
              >
                {`${formatNumberToFixed(
                  wallets.creditLine ? wallets.creditLine : 0,
                  2
                )} $`}
              </Typography>
              <Typography
                variant="h4"
                style={{
                  fontSize: 10,
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                Credit Line
              </Typography>
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              style={{
                borderRadius: 5,
                height: 56,
                background: "#F2994A",
              }}
            >
              <Typography
                variant="h4"
                style={{ fontSize: 22, color: "#FFFFFF" }}
              >
                {`${formatNumberToFixed(
                  wallets.saving ? wallets.saving : 0,
                  2
                )} $`}
              </Typography>
              <Typography
                variant="h4"
                style={{
                  fontSize: 10,
                  lineHeight: "20px",
                  color: "#FFFFFF",
                }}
              >
                Saving Balance
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            backgroundColor: "#f5f5f5",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <MenuUser
            dataNavigation={menuheader}
            value={valueMenu}
            handleChange={handleChangeMenuHeader}
          />
        </Grid>
      </Grid>
      {renderPage()}
    </ProfileCard>
  );
};
