import React, { useState, useEffect } from "react";
import iconRedirect from "../assets/images/iconRedirect.svg";
import { ILoadWithdrawRequest } from "../types/withdraw.type";
import { useDispatch, useSelector } from "react-redux";
import { loadWithdrawLoanAction } from "../actions/withdraw.action";
import { IStoreState } from "../types";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  TableRow,
  makeStyles,
  IconButton,
  Grid,
  Tooltip,
} from "@material-ui/core";
import Pagination from "./share/Pagination";
import { formatNumberToFixed } from "../helper";
import classNames from "classnames";
import iconRefuse from "../assets/images/refuse.svg";
import iconApprove from "../assets/images/approve.svg";
import iconPending from "../assets/images/pending.svg";
import { RootTable } from "./RootTable";
import { CardNoDataTable } from "./share/CardNoDataTable";
import { OrderByTableHeader } from "./share/OrderByTableHeader";
import { useHistory } from "react-router";
import { PATH } from "../constants/PATH";
import { PAGE_USER_DETAIL } from "../constants/users.constant";
import { DVT_USD } from "../constants/transaction.constant";
import { StatusTransaction } from "./share/StatusTransaction";

const styles = makeStyles({
  tableHead: {
    fontSize: 12,
    color: "#9FA2B4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
  },
  tableBody: {
    fontSize: 12,
    color: "#050F47 ",
    fontWeight: 500,
    fontFamily: "Montserrat",
    background: "#FFFFFF",
    height: 27,
  },
  tableCell: {
    transform: "scale(1)",
    "&:hover": {
      "&:after": {
        content: "''",
        backgroundColor: "#3CA5F2",
        position: "absolute",
        width: 3,
        height: 30,
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  paddingTableHead: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const WithdrawsLoans = () => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const classes = styles();

  const [meta, setMeta] = useState<ILoadWithdrawRequest>({
    limit: 10,
    offset: 0,
    type: "Loan Withdrawal",
    order: {},
  });

  const listWithdrawLoans = useSelector(
    (state: IStoreState) => state.listWithdrawLoans
  );
  listWithdrawLoans.data.map((el) => {
    return (el.status = false);
  });

  useEffect(() => {
    dispatch(loadWithdrawLoanAction(meta));
  }, [dispatch, meta]);

  const handleChangePage = (event: any, page: number) => {
    setMeta({
      ...meta,
      limit: listWithdrawLoans.meta.limit,
      offset: page * listWithdrawLoans.meta.limit,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMeta({
      ...meta,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    });
  };

  const handleChildWithdrawLoanInfo = (id: string, userId: string) => () => {
    history.push(PATH.user.replace(":id", userId), {
      idWithdraw: id,
      type: PAGE_USER_DETAIL.WITHDRAW,
    });
  };

  const handleChangeArrow = (
    typeTable: string,
    orderBy: string | undefined
  ) => {
    let newOrder = meta.order;
    if (newOrder) {
      newOrder[typeTable] = orderBy;
    }
    setMeta({
      ...meta,
      order: newOrder,
    });
  };
  return (
    <RootTable nameTable="Withdraw All Loans">
      <Table>
        <TableHead>
          <TableRow style={{ width: "100%" }}>
            <TableCell
              className={classes.tableHead}
              style={{
                width: "1%",
              }}
            >
              {"#"}
            </TableCell>
            <TableCell
              style={{
                width: "14%",
              }}
              className={classes.tableHead}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              style={{
                width: "15%",
              }}
              className={classes.paddingTableHead}
            >
              <OrderByTableHeader
                title="Currency"
                justifyTableHeader="center"
                type="currencyId"
                handleChangeArrow={handleChangeArrow}
              />
            </TableCell>
            <TableCell
              style={{
                width: "20%",
              }}
              className={classes.paddingTableHead}
            >
              <OrderByTableHeader
                title="Amount"
                type="amount"
                handleChangeArrow={handleChangeArrow}
              />
            </TableCell>
            <TableCell
              style={{
                width: "15%",
              }}
              className={classes.tableHead}
            >
              Receiver
            </TableCell>
            <TableCell
              style={{
                width: "15%",
              }}
              className={classes.tableHead}
            >
              Account Number
            </TableCell>
            <TableCell
              style={{
                width: "10%",
              }}
              align="center"
              className={classes.tableHead}
            >
              Status
            </TableCell>
            <TableCell
              style={{
                width: "10%",
                paddingRight: 16,
              }}
              align="right"
              className={classes.tableHead}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listWithdrawLoans.data.length ? (
            listWithdrawLoans.data.map((el, index) => {
              return (
                <TableRow key={index} className={classes.tableCell}>
                  <TableCell className={classNames(classes.tableBody)}>
                    {index +
                      1 +
                      listWithdrawLoans.meta.limit *
                        (listWithdrawLoans.meta.currentPage - 1)}
                  </TableCell>
                  <TableCell className={classes.tableBody}>
                    {el.user.firstName ? el.user.firstName + " " : ""}
                    {el.user.lastName ? el.user.lastName : ""}
                  </TableCell>
                  <TableCell
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    align="center"
                    className={classes.tableBody}
                  >
                    <img
                      src={
                        (`${process.env.REACT_APP_API_URL}static/images/currencies/` ||
                          "") + el.currency.image
                      }
                      width={25}
                      height={25}
                      alt="icon currency"
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    style={{ color: "red" }}
                  >
                    {"- " + formatNumberToFixed(el.amount, 2) + DVT_USD}
                  </TableCell>
                  <TableCell className={classes.tableBody}>
                    {el.userBankAccount
                      ? el.userBankAccount.name
                      : el.recipientAddress}
                  </TableCell>
                  <TableCell className={classes.tableBody}>
                    {el.userBankAccount?.accountNumber || el.recipientAddress}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="center">
                    {el.adminApproved === true ? (
                      <StatusTransaction
                        title="Apporved"
                        srcImage={iconApprove}
                      />
                    ) : null}
                    {el.adminDenied === true ? (
                      <StatusTransaction title="Reject" srcImage={iconRefuse} />
                    ) : null}
                    {!el.adminApproved && !el.adminDenied ? (
                      <StatusTransaction
                        title="Pending"
                        srcImage={iconPending}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingRight: 16,
                    }}
                    className={classNames(classes.tableBody)}
                  >
                    <IconButton
                      onClick={handleChildWithdrawLoanInfo(el.id, el.userId)}
                      style={{
                        width: 30,
                        height: 30,
                        padding: 0,
                      }}
                    >
                      <Tooltip title="Redirect" aria-label="redirect">
                        <img
                          src={iconRedirect}
                          alt="icon"
                          width={15}
                          height={15}
                        />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <CardNoDataTable colSpan={8} />
          )}
        </TableBody>
      </Table>
      <Grid>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={
            listWithdrawLoans.meta.total ? listWithdrawLoans.meta.total : 0
          }
          rowsPerPage={rowsPerPage}
          page={
            listWithdrawLoans.meta.currentPage
              ? listWithdrawLoans.meta.currentPage - 1
              : 0
          }
          onChangePage={handleChangePage}
          ActionsComponent={Pagination}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </RootTable>
  );
};
