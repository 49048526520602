import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { PATH } from "./constants/PATH";
import { Login } from "./pages/Login";
import _ from "lodash";
import { Template } from "./components/Template";
import { requestTokenAction } from "./helper/requestToken";
import { logoutAction } from "./actions/auth.action";
import { store } from ".";

export const Routes = () => {
  const history = useHistory();

  const getAccessToken = () => {
    const time = 1000 * 60 * 30;
    const accessExpire = localStorage.getItem("exp");
    const refreshToken = localStorage.getItem("refresh-token");

    if (
      accessExpire !== null &&
      parseInt(accessExpire, 0) * 1000 - new Date().getTime() < 1000 * 60 * 60
    ) {
      requestTokenAction(refreshToken ? refreshToken : "");
    }

    setInterval(() => {
      if (
        accessExpire !== null &&
        parseInt(accessExpire, 0) * 1000 - new Date().getTime() < 1000 * 60 * 60
      ) {
        requestTokenAction(refreshToken ? refreshToken : "");
      }
    }, time);
  };

  const logoutWhenRefreshExpire = () => {
    const hour = 1000 * 60 * 60;
    const refreshExpire = localStorage.getItem("refreshExp");

    if (
      refreshExpire &&
      parseInt(refreshExpire, 0) * 1000 - new Date().getTime() < hour * 2
    ) {
      store.dispatch(logoutAction());
    } else {
      getAccessToken();
    }

    setInterval(() => {
      if (
        refreshExpire &&
        parseInt(refreshExpire, 0) * 1000 - new Date().getTime() < hour * 2
      ) {
        store.dispatch(logoutAction());
      }
    }, hour);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const path = history.location.pathname;
    if (token) {
      if (_.includes([PATH.login, PATH.home], path)) {
        return history.push(PATH.users);
      }
    } else {
      return history.push(PATH.login);
    }
  }, [history]);

  useEffect(() => {
    logoutWhenRefreshExpire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={PATH.login} exact={true} component={Login} />
      <Route path={PATH.home} component={Template} />
    </Switch>
  );
};
