import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: "100%",
      },
    },
    MuiTypography: {
      subtitle1: {
        fontSize: 20,
        fontFamily: "Montserrat",
        fontWeight: 600,
        lineHeight: "20px",
        color: "#3E399F",
      },
      h2: {
        fontSize: 32,
        fontFamily: "Montserrat",
        fontWeight: 600,
        color: "#050F47",
        lineHeight: "37px",
      },
      h3: {
        fontSize: 16,
        fontFamily: "Montserrat",
        fontWeight: 600,
        color: "#050F47",
        lineHeight: "18px",
      },
      h4: {
        fontSize: 14,
        fontFamily: "Montserrat",
        fontWeight: 500,
        color: "#050F47",
        lineHeight: "28px",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiDialog: {
      paper: {
        margin: 0,
        background: " #FFFFFF",
        border: "1px solid #E4EAEE",
        boxSizing: "border-box",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.1)",
        borderRadius: 10,
      },
      paperWidthXs: {
        width: 520,
        maxWidth: "520px !important",
        height: "auto",
      },
      paperWidthSm: {
        maxWidth: 520,
        height: 345,
        width: 520,
      },
      paperWidthMd: {
        width: 520,
        maxWidth: 520,
        height: 500,
      },
      paperWidthLg: {
        width: 600,
        maxWidth: 600,
        height: "auto",
      },
      paperWidthXl: {
        width: 520,
        maxWidth: 520,
        height: 650,
      },
    },
    MuiSwitch: {
      root: {
        width: 50,
        height: 26,
        padding: 0,
        borderRadius: 20,
      },
      switchBase: {
        top: "-6px",
        left: "-4px",
        color: "#FFFFFF !important",
        "&$track": {
          opacity: 1,
          background:
            "linear-gradient(118.38deg, #9BA0A6 9.63%, #C4C4C4 85.81%) !important",
        },
        "&$checked": {
          color: "red",
          "& + $track": {
            opacity: 1,
            background:
              "linear-gradient(118.38deg, #47CAB2 9.63%, #36BCA4 85.81%) !important",
          },
        },
      },
    },
    MuiMenu: {
      list: {
        color: "#A5B2C0",
        fontFamily: "Montserrat",
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "separate",
      },
    },
    MuiTablePagination: {
      toolbar: {
        padding: 0,
      },
      caption: {
        color: "#A5B2C0",
        paddingLeft: 16,
        fontFamily: "Montserrat",
        fontSize: 14,
        fontWeigth: 500,
      },
      spacer: {
        flex: "inherit",
      },
      input: {
        width: 60,
      },
    },
    MuiTableCell: {
      root: {
        paddingRight: 0,
      },
    },
    MuiSelect: {
      select: {
        fontFamily: "Montserrat",
        fontSize: 14,
        fontWeigth: 500,
        color: "#A5B2C0",
      },
    },
  },
});

export default theme;
