import { IStoreState } from "../types";
import { Dispatch } from "react";
import { Store } from "redux";
import {
  ISetActivityLogsListAction,
  ILoadActivityLogsListAction,
  setActivityLogsListAction,
} from "../actions/activityLogs.action";
import { LOAD_ACTIVITY_LOGS_LIST } from "../constants/activityLogs.constants";
import { Method, AxiosResponse, AxiosError } from "axios";
import { ACTIVITY_LOGS_URL } from "../helper/environment";
import { callApi } from "../helper/callApi";
import { IObjectActivityLogsList } from "../types/activityLogs.type";

export const getActivityLogsList = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ILoadActivityLogsListAction | ISetActivityLogsListAction>
) => async (action: ILoadActivityLogsListAction) => {
  switch (action.type) {
    case LOAD_ACTIVITY_LOGS_LIST:
      const url = ACTIVITY_LOGS_URL;
      const method: Method = "get";
      const params = {
        limit: action.payload.limit,
        offset: action.payload.offset,
        search: action.payload.search ? action.payload.search : undefined,
        category: action.payload.category ? action.payload.category : undefined,
      };

      const payload = { method, url, params };
      callApi(payload)
        .then((response: AxiosResponse<IObjectActivityLogsList>) => {
          if (response && response.data) {
            dispatch(setActivityLogsListAction(response.data));
          }
        })
        .catch((errors: AxiosError) => {
          return Promise.reject(errors);
        });
      break;
  }
  return dispatch(action);
};
