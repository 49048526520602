import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { loadConfigList } from "../actions";
import { IStoreState } from "../types";
import { LoanInterestConfig } from "../components/config/loanInterest.config";
import { SavingWithdrawRateConfig } from "../components/config/savingWithdrawRate.config";
import { SavingInterestConfig } from "../components/config/savingInterest.config";
import { LtvLevelConfig } from "../components/config/ltvLevel.config";
import { PaymentTypeConfig } from "../components/config/paymentType.config";
import { TransferTypeConfig } from "../components/config/transferType.config";
import { IConfig } from "../types/config.type";
import { ProfileCard } from "../components/ProfileCard";

export const Settings = () => {
  const dispatch = useDispatch();
  const dataDefault = {
    id: "",
    name: "",
    type: "",
    rate: "",
    value: "",
    createdAt: "",
    updatedAt: "",
  };

  const [disablePaymentType, setDisablePaymentType] = useState<boolean[]>([]);
  const [disableTransferType, setDisableTransferType] = useState<boolean[]>([]);
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["Setting"];

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };
  const configList = useSelector((state: IStoreState) => state.configList);
  const loanInteret: IConfig[] = configList.data.filter(
    (value) => value.name === "INTEREST_RATE" && value.type === "YEARLY"
  );
  const savingWithdrawRate: IConfig[] = configList.data.filter(
    (value) => value.name === "SAVING_WITHDRAW_RATE"
  );
  const savingInterest: IConfig[] = configList.data.filter(
    (value) => value.name === "SAVING_INTEREST_RATE" && value.type === "YEARLY"
  );
  const dataLtvLevel: IConfig[] = configList.data.filter(
    (value) => value.name === "LTV_WARNING_LEVEL"
  );
  const transferType: IConfig[] = configList.data.filter(
    (value) => value.name === "TRANSFER_TYPE"
  );

  const paymentType: IConfig[] = configList.data.filter(
    (value) => value.name === "RECIPIENT_TYPE"
  );
  useEffect(() => {
    dispatch(loadConfigList());
  }, [dispatch]);

  useEffect(() => {
    if (configList && configList.data) {
      let newDisablePaymentType: boolean[] = [];
      let newDisableTranferType: boolean[] = [];

      configList.data.map((value) => {
        if (value.name === "TRANSFER_TYPE") {
          newDisableTranferType.push(true);
          setDisableTransferType(newDisableTranferType);
        }
        if (value.name === "RECIPIENT_TYPE") {
          newDisablePaymentType.push(true);

          setDisablePaymentType(newDisablePaymentType);
        }
        return value;
      });
    }
  }, [configList]);

  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <Grid container style={{ marginTop: 30, width: "98%" }}>
        <Grid
          container
          spacing={3}
          style={{
            paddingBottom: 30,
          }}
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            item
            xs={6}
          >
            <LoanInterestConfig
              dataLoanInterest={
                loanInteret.length > 0 ? loanInteret[0] : dataDefault
              }
            />
            <SavingWithdrawRateConfig
              dataSavingWithdrawRate={
                savingWithdrawRate.length > 0
                  ? savingWithdrawRate[0]
                  : dataDefault
              }
            />
            <SavingInterestConfig
              dataSavingInterest={
                savingInterest.length > 0 ? savingInterest[0] : dataDefault
              }
            />
          </Grid>

          <Grid item xs={6}>
            <LtvLevelConfig dataLtvLevel={dataLtvLevel} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PaymentTypeConfig
              dataPaymentType={paymentType}
              dataDisable={disablePaymentType}
            />
          </Grid>
          <Grid item xs={6}>
            <TransferTypeConfig
              dataTransferType={transferType}
              dataDisable={disableTransferType}
            />
          </Grid>
        </Grid>
      </Grid>
    </ProfileCard>
  );
};
