import { Action } from "redux";
import {
  DELETE_USER_ADMIN_ACTION,
  SET_LIST_USER_ADMIN_ACTION,
  EDIT_USER_ADMIN,
} from "../constants/usersAdmin.constant";
import {
  IDeleteUserAdminRequest,
  IUserAdminInfoRequest,
} from "../types/userAdmin.type";
import { IObjectListUser } from "../types/user.type";
import { ADD_USER_ADMIN } from "../constants/users.constant";

export interface IDeleteUserAdminAction
  extends Action<DELETE_USER_ADMIN_ACTION> {
  payload: IDeleteUserAdminRequest;
}

export const deleteUserAdminAction = (
  request: IDeleteUserAdminRequest
): IDeleteUserAdminAction => ({
  type: DELETE_USER_ADMIN_ACTION,
  payload: request,
});

export interface ISetUserAdminListAction
  extends Action<SET_LIST_USER_ADMIN_ACTION> {
  payload: IObjectListUser;
}

export const setListUserAdminAction = (
  userlist: IObjectListUser
): ISetUserAdminListAction => {
  return {
    type: SET_LIST_USER_ADMIN_ACTION,
    payload: userlist,
  };
};

export interface IAddUserAdminAction extends Action<ADD_USER_ADMIN> {
  payload: IUserAdminInfoRequest;
}

export const addUserAdminAction = (
  request: IUserAdminInfoRequest
): IAddUserAdminAction => ({
  type: ADD_USER_ADMIN,
  payload: request,
});

export interface IEditUserAdminAction extends Action<EDIT_USER_ADMIN> {
  payload: IUserAdminInfoRequest;
}

export const editUserAdminAction = (
  request: IUserAdminInfoRequest
): IEditUserAdminAction => ({
  type: EDIT_USER_ADMIN,
  payload: request,
});
