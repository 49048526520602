export const DELETE_USER_ADMIN_ACTION = "DELETE_USER_ADMIN_ACTION";
export type DELETE_USER_ADMIN_ACTION = typeof DELETE_USER_ADMIN_ACTION;

export const SET_LIST_USER_ADMIN_ACTION = "SET_LIST_USER_ADMIN_ACTION";
export type SET_LIST_USER_ADMIN_ACTION = typeof SET_LIST_USER_ADMIN_ACTION;

export const EDIT_USER_ADMIN = "EDIT_USER_ADMIN";
export type EDIT_USER_ADMIN = typeof EDIT_USER_ADMIN;

export enum INFO_ADMIN {
  EMAIL = "admin@gmail.com",
}
