import React from "react";
import Grid from "@material-ui/core/Grid";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconClose from "../../assets/images/close.svg";
import { Typo } from "../share/Typo";
import { NexoButton } from "../share/NexoButton";

const useStyles = makeStyles((theme) => ({
  button: {
    boder: "1px solid red",
    borderRadius: "18px",
    boxSizing: "border-box",
    width: "150px",
    height: "50px",
  },
  paper: {
    background: "#FFFFFF",
    borderRadius: "20px",
    padding: "15px 20px 20px 20px",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "10px",
      width: "590px",
      height: "213px",
      maxWidth: 600,
    },
  },

  labelCreateScript: {
    fontSize: 20,
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#050F47",
    lineHeight: "18px",
  },
  content: {
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#050F47",
    lineHeight: "18px",
  },
  buttonVerify: {
    width: 150,
  },
  buttonCancel: {
    width: 150,
    marginRight: 7,
  },
}));

interface IProps {
  showDialog: boolean;
  handleClose: (showDialog: boolean) => void;
  type?: string;
  message?: string;
  handleSubmit?: () => void;
}

export const ConfirmDialog = (props: IProps) => {
  const classes = useStyles();
  const { showDialog, handleClose, type, message, handleSubmit } = props;

  const handleCloseDidalog = () => {
    handleClose(!showDialog);
  };

  return (
    <Dialog open={showDialog} onClose={handleClose} className={classes.dialog}>
      <Grid container={true} className={classes.paper}>
        <Grid container={true} justify="flex-end">
          <img
            style={{ cursor: "pointer" }}
            src={IconClose}
            alt=""
            onClick={handleCloseDidalog}
          />
        </Grid>
        <Grid container={true}>
          <Grid container={true} style={{ height: 50 }} justify="center">
            <Typo className={classes.labelCreateScript}>{type}</Typo>
          </Grid>

          <Grid container={true} style={{ height: 50 }} justify="center">
            <Typo className={classes.content}>{message}</Typo>
          </Grid>

          <Grid container justify="center" style={{ marginTop: 15 }}>
            <Grid className={classes.buttonCancel}>
              <NexoButton
                title="Cancel"
                variant="outlined"
                color="primary"
                heightButton={40}
                handleRedirect={handleCloseDidalog}
              />
            </Grid>

            <Grid className={classes.buttonVerify}>
              <NexoButton
                title="Ok"
                variant="contained"
                color="primary"
                heightButton={40}
                handleRedirect={handleSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
