import { IObjectActivityLogsList } from "../types/activityLogs.type";
import { ISetActivityLogsListAction } from "../actions/activityLogs.action";
import { SET_ACTIVITY_LOGS_LIST } from "../constants/activityLogs.constants";

const initialActivityLog: IObjectActivityLogsList = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0,
  },
};

export const activityLogs = (
  state: IObjectActivityLogsList = initialActivityLog,
  action: ISetActivityLogsListAction
): IObjectActivityLogsList => {
  switch (action.type) {
    case SET_ACTIVITY_LOGS_LIST:
      return action.payload;

    default:
      return state;
  }
};
