import axios, { AxiosRequestConfig } from "axios";
import { Dispatch, Store } from "redux";
import { IStoreState } from "../types";
import { INIT_AXIOS_INTERCEPTOR } from "../constants/auth.constant";
import { IInitialAxiosInterceptorAction } from "../actions/auth.action";

export const initAxiosInterceptor = (store: Store<IStoreState>) => (
  dispatch: Dispatch<IInitialAxiosInterceptorAction>
) => (action: IInitialAxiosInterceptorAction) => {
  if (action.type === INIT_AXIOS_INTERCEPTOR) {
    axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const token = localStorage.getItem("token");
        if (
          token &&
          config.url !== "https://geoip-db.com/json/geoip.php?json=?"
        ) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        config.baseURL = process.env.REACT_APP_API_URL;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }
  return dispatch(action);
};
