import React from "react";
import Grid from "@material-ui/core/Grid";
import { Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NexoButton } from "../share/NexoButton";
import { ItemUser } from "../users/ItemUser";
import { IWithdrawIntemInfo } from "../users/Withdrawal";
import { formatNumberToFixed } from "../../helper";
import { IWithdrawRequest } from "../../types/withdraw.type";
import { useDispatch } from "react-redux";
import {
  approveWithdrawAction,
  deniedWithdrawAction,
} from "../../actions/withdraw.action";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "10px",
      width: "560px",
      maxWidth: 600,
      height: "auto",
    },
  },

  buttonVerify: {
    width: 150,
  },
  buttonCancel: {
    width: 150,
    marginRight: 7,
  },
}));

interface IProps {
  showDialog: boolean;
  handleClose: (showDialog: boolean) => void;
  withdrawIntemInfo: IWithdrawIntemInfo;
}

export const WithdrawAssetUser = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showDialog, handleClose, withdrawIntemInfo } = props;

  const handleApprove = () => {
    const reuquest: IWithdrawRequest = {
      id: withdrawIntemInfo.id,
      idUser: withdrawIntemInfo.userId,
    };
    setTimeout(() => {
      dispatch(approveWithdrawAction(reuquest));
      handleClose(!showDialog);
    }, 500);
  };
  const handleAdminDeniedItem = () => {
    const reuquest: IWithdrawRequest = {
      id: withdrawIntemInfo.id,
      idUser: withdrawIntemInfo.userId,
    };
    setTimeout(() => {
      dispatch(deniedWithdrawAction(reuquest));
      handleClose(!showDialog);
    }, 500);
  };

  return (
    <Dialog open={showDialog} onClose={handleClose} className={classes.dialog}>
      <Grid
        container
        style={{
          background: "#F9FAFB",
          borderBottom: "1px solid #D7DCE0",
          height: 60,
        }}
        alignItems="center"
        justify="center"
      >
        <Typography variant="h4" style={{ fontSize: 20, fontWeight: 600 }}>
          Withdrawal Asset
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <Grid container style={{ marginTop: 10 }}>
          <ItemUser
            title="Amount"
            value={`${formatNumberToFixed(withdrawIntemInfo.amount, 8)} ${
              withdrawIntemInfo.symbol
            }`}
          />
        </Grid>
        <Grid container style={{ marginTop: 10 }}>
          <ItemUser
            title="Recipient Address"
            value={withdrawIntemInfo.recipientAddress}
          />
        </Grid>
      </Grid>
      {withdrawIntemInfo.adminApprovedItem === false &&
      withdrawIntemInfo.adminDeniedItem === false ? (
        <Grid container={true}>
          <Grid container justify="center" style={{ marginBottom: 30 }}>
            <Grid className={classes.buttonCancel}>
              <NexoButton
                title="Refuse"
                variant="outlined"
                color="primary"
                handleRedirect={handleAdminDeniedItem}
                heightButton={40}
              />
            </Grid>

            <Grid className={classes.buttonVerify}>
              <NexoButton
                handleRedirect={handleApprove}
                title="Approve"
                variant="contained"
                color="primary"
                heightButton={40}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Dialog>
  );
};
