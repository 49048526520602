import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { UserTable } from "../components/users/UserTable";
import { ProfileCard } from "../components/ProfileCard";
import { AdminTable } from "./AdminTable";

export const User = (props: any) => {
  const [value, setValue] = useState<number>(0);
  const dataNavigation = ["Member", "Admin"];

  const handleChangeBottomNavigation = (
    event: React.ChangeEvent<{}>,
    value: any
  ) => {
    setValue(value);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setValue(props.history.location.state);
    }
  }, [props.history.location.state]);
  return (
    <ProfileCard
      dataNavigation={dataNavigation}
      value={value}
      handleChangeBottomNavigation={handleChangeBottomNavigation}
    >
      <Grid>{value === 0 ? <UserTable /> : <AdminTable />}</Grid>
    </ProfileCard>
  );
};
